<template>
  <v-container v-if="tags">
    <h1>Catégories</h1>
    <v-btn @click="createTag({ name: 'Nouveau', label: 'Nouveau' })"
      >Créer une catégorie</v-btn
    >
    <table class="fo-table">
      <tr v-for="tag in tags" :key="`tag-${tag.id}`">
        <td>
          <v-text-field
            v-model="tag.name"
            hide-details
            @change="patchTag(tag, 'name', tag.name)"
            label="Clé"
          ></v-text-field>
        </td>
        <td>
          <v-text-field
            v-model="tag.color"
            hide-details
            @change="patchTag(tag, 'color', tag.color)"
            label="Couleur"
          ></v-text-field>
        </td>
        <td>
          <v-btn @click="deleteTag(tag.id)" icon small>
            <v-icon small>delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
    <v-alert v-if="tags.length == 0"> Aucun tag pour le moment </v-alert>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    tagNatures: [
      {
        value: "article",
        text: "Article",
      },
      {
        value: "organization",
        text: "Organisation",
      },
    ],
  }),
  methods: {
    ...mapActions({
      createTag: "tags/create",
      deleteTag: "tags/delete",
    }),
    patchTag(tag, field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/tag_articles/${tag.id}.json`, payload)
        .catch(() => {
          alert("Problème");
        });
    },
  },
  computed: {
    ...mapGetters({
      tags: "tags/tags",
    }),
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
</style>
