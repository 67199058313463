<template>
  <v-container>
    <v-card v-if="organization">
      <v-toolbar flat class="toolbar">
        <v-btn icon :to="{ name: 'organizations' }" class="mr-3">
          <v-icon>navigate_before</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small @click="deleteOrganization()"
          >Supprimer l'organisation</v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-text-field
          name="Nom de l'organisation"
          label="Nom de l'organisation"
          v-model="organization.name"
          @change="patchOrganization('name', organization.name)"
        ></v-text-field>

        <v-btn-toggle
          name="Position de l'organisation"
          label="Position de l'organisation"
          v-model="organization.is_third_party"
          rounded
          @change="
            patchOrganization(
              'is_third_party',
              organization.is_third_party ? true : false
            )
          "
        >
          <v-btn> Interne </v-btn>
          <v-btn> Tier </v-btn>
        </v-btn-toggle>
        <!-- <div>
          <TagsPicker
            v-model="organization.tags"
            multiple
            @input="patchOrganization('tags', organization.tags)"
          ></TagsPicker>
        </div> -->
        <div>Adresse de l'organisation</div>
        <VueEditor
          v-model="organization.address"
          @input="patchOrganization('address', organization.address)"
        >
        </VueEditor>
        <div>Informations de contact</div>
        <VueEditor
          v-model="organization.contact_infos"
          @input="
            patchOrganization('contact_infos', organization.contact_infos)
          "
        >
        </VueEditor>
        <div>Informations de paiement</div>
        <VueEditor
          v-model="organization.payment_infos"
          @input="
            patchOrganization('payment_infos', organization.payment_infos)
          "
        >
        </VueEditor>
        <div>Informations bas de facture</div>
        <VueEditor
          v-model="organization.invoice_bottom"
          @input="
            patchOrganization('invoice_bottom', organization.invoice_bottom)
          "
        >
        </VueEditor>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */

import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";
// import TagsPicker from "../tags/TagsPicker.vue";
export default {
  components: {
    VueEditor,
    // TagsPicker,
  },
  data: () => ({
    organization: null,
  }),
  computed: {
    ...mapGetters({
      organizations: "organizations/organizations",
    }),
  },
  methods: {
    ...mapActions({
      storeDelete: "organizations/delete",
      storePatch: "organizations/patch",
    }),
    patchOrganization(field, value) {
      let payload = {};
      payload[field] = value;
      this.storePatch({ id: this.organization.id, payload: payload }).catch(
        () => {
          window.alert("Changement non enregistré");
        }
      );
    },
    deleteOrganization() {
      this.storeDelete(this.organization.id)
        .then(() => {
          this.$router.push({ name: "organizations" });
        })
        .catch(() => {
          window.alert("Echec de la suppresion");
        });
    },
    loadOrganization() {
      this.organization = this.organizations.find(
        (e) => e.id == this.$route.params.organization_id
      );
      this.organization.is_third_party = this.organization.is_third_party
        ? 1
        : 0;
    },
  },
  mounted() {
    this.loadOrganization(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadOrganization(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
