<template>
  <div class="page-view" v-if="pages">
    <div class="page left">
      <!-- <v-toolbar flat dense class="toolbar" color="transparent">
        <v-checkbox
          v-if="
            user.direction_access &&
            edition &&
            (tableView || filterStr.length > 0)
          "
          v-model="toggleAllValue"
          dense
          small
          :value="toggleAllValue"
          :indeterminate="toggleAllIndeterminate"
          @change="toggleAll"
          hide-details
        ></v-checkbox>

        <v-menu
          offset-y
          v-if="
            user.direction_access &&
            edition &&
            (tableView || filterStr.length > 0)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              :color="rowsSelectedIds.length > 0 ? 'primary' : ''"
              v-bind="attrs"
              v-on="on"
              class="mr-3"
            >
              {{ rowsSelectedIds.length }} éléments
            </v-btn>
          </template>
          <v-list>
            <v-list-item link dense @click="dialogAddMember = true">
              Ajouter des membres
            </v-list-item>
            <v-list-item link dense @click="dialogRemoveMember = true">
              Supprimer des membres
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link dense @click="removeSelectedPages()">
              Supprimer
            </v-list-item>
          </v-list>
        </v-menu>
        <v-text-field
          v-model="filterStr"
          label="Filtrer"
          solo
          hide-details
          dense
        ></v-text-field>
        <v-btn small icon class="mx-2" @click="tableView = !tableView">
          <v-icon small v-if="tableView">account_tree</v-icon>
          <v-icon small v-else>table_rows</v-icon>
        </v-btn>
      </v-toolbar> -->

      <!-- <span v-if="user.direction_access">
        <v-dialog v-model="dialogAddMember" width="300">
          <v-card class="pa-3">
            <h3>Ajouter</h3>
            <StakeholderPicker
              v-model="newMembers"
              :canCreate="true"
              multiple
              label="Sélectionnez des membres"
            ></StakeholderPicker>
            <v-btn @click="addMembers">Ajouter</v-btn>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogRemoveMember" width="300">
          <v-card class="pa-3">
            <h3>Supprimer</h3>
            <StakeholderPicker
              v-model="membersToRemove"
              :canCreate="true"
              multiple
              label="Sélectionnez des membres"
            ></StakeholderPicker>
            <v-btn @click="removeMembers">Ajouter</v-btn>
          </v-card>
        </v-dialog>
      </span> -->
      <div class="">
        <!-- <div v-if="filterStr.length > 0" class="filter-results">
          <router-link
            v-for="page in pagesFiltered"
            :key="`bf-${page.item.id}`"
            class="page-link"
            :to="{ name: 'page', params: { page_id: page.item.id } }"
          >
            <div>
              <v-checkbox
                v-if="user.direction_access && edition"
                v-model="rowsSelectedIds"
                dense
                x-small
                class="ma-0 pa-0"
                hide-details
                :value="page.item.id"
              ></v-checkbox
              >{{ page.item.parentsName }}
            </div>
          </router-link>
        </div> -->
        <!-- <div v-else> -->
        <div v-if="!tableView">
          <PageItem
            :pages="dataPages"
            :parentPage="null"
            :edition="edition"
          ></PageItem>
        </div>
        <div v-else class="filter-results">
          <router-link
            v-for="page in flattenPages"
            :key="`bf-${page.id}`"
            class="page-link"
            :to="{ name: 'page', params: { page_id: page.id } }"
          >
            <div>
              <v-checkbox
                v-if="user.direction_access && edition"
                v-model="rowsSelectedIds"
                dense
                x-small
                class="ma-0 pa-0"
                hide-details
                :value="page.id"
              ></v-checkbox>
              {{ page.parentsName }}
            </div>
          </router-link>
        </div>
        <!-- </div> -->
      </div>
      <v-alert v-if="dataPages.length == 0">
        Aucun bloc pour le moment
      </v-alert>
    </div>
    <v-col class="page page-page">
      <router-view></router-view>
    </v-col>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PageItem from "./PageItem.vue";
import Fuse from "fuse.js";
// import StakeholderPicker from "../stakeholders/StakeholderPicker.vue";

/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {
    PageItem,
    // StakeholderPicker
  },
  data: () => ({
    rowsSelected: {
      ids: [],
    },
    dataPages: [],
    dialogImport: false,
    dataImport: "",
    delimiter: ";",
    pageParentColumn: "",
    pageType: "Zone",
    edition: true,
    filterStr: "",
    pagesFiltered: [],
    fuse: null,
    tableView: false,
    rowsSelectedIds: [],
    dialogAddMember: false,
    newMembers: [],
    membersToRemove: [],
    dialogRemoveMember: false,
  }),
  methods: {
    ...mapActions({
      load: "pages/load",
      delete: "pages/delete",
    }),
    addMembers() {
      this.newMembers.forEach((stakeholder) => {
        this.rowsSelectedIds.forEach((pageId) => {
          let payload = {
            stakeholder: stakeholder,
            page: pageId,
          };
          this.axios.post(`/api/members.json`, payload).then(() => {
            // this.page.members.push(result.data);
            // this.page.members_count++;
          });
        });
      });
    },
    removeMembers() {
      this.membersToRemove.forEach((stakeholder) => {
        this.rowsSelectedIds.forEach((pageId) => {
          let page = this.flattenPages.find((b) => b.id == pageId);
          let member = page.members.find(
            (m) => m.stakeholder_id == stakeholder
          );
          this.axios.delete(`/api/members/${member.id}.json`).then(() => {
            // this.page.members.push(result.data);
            // this.page.members_count++;
          });
        });
      });
    },
    toggleAll() {
      let data = this.flattenPages;
      if (this.pagesFiltered.length > 0) {
        data = this.pagesFiltered.map((p) => p.item);
      }
      if (this.rowsSelectedIds.length == 0) {
        this.rowsSelectedIds = data.map((e) => e.id);
      } else if (this.rowsSelectedIds.length == data.length) {
        this.rowsSelectedIds = [];
      } else {
        this.rowsSelectedIds = data.map((e) => e.id);
      }
    },
    removeSelectedPages() {
      var that = this;
      function deleteOneByOne(i) {
        if (that.rowsSelectedIds.length > i) {
          that.delete(that.rowsSelectedIds[i]).finally(() => {
            deleteOneByOne(++i);
          });
        }
      }
      deleteOneByOne(0);
    },
  },
  watch: {
    pages() {
      this.dataPages = [...this.pages];
    },
    flattenPages() {
      let pages = [...this.flattenPages];
      this.fuse = new Fuse(pages, {
        keys: ["parentsName"],
      });
    },
    filterStr(value) {
      this.pagesFiltered = this.fuse.search(value);
    },
  },
  computed: {
    ...mapGetters({
      pages: "pages/pages",
      flattenPages: "pages/flattenPages",
      user: "user/user",
    }),
    toggleAllValue() {
      return (
        this.rowsSelectedIds.length == this.flattenPages.length &&
        this.rowsSelectedIds.length > 0
      );
    },
    toggleAllIndeterminate() {
      return (
        this.rowsSelectedIds.length != this.flattenPages.length &&
        this.rowsSelectedIds.length > 0
      );
    },
  },
  mounted() {
    this.load("&parent_isnull=True");
  },
};
</script>
<style lang="scss" scoped>
// .filter-results {
//   padding-left: 20px;
//   padding-top: 20px;
//   a {
//     .top {
//       font-size: 0.7em;
//       color: #666;
//       padding: 2px;
//     }
//     display: block;
//     border: 1px solid #f6f6f6;
//     margin: 2px;
//     padding: 2px 20px;

//     &.page-link {
//       div {
//         display: flex;
//       }
//     }
//   }
// }
.page-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: #f8f8f8;
  .page {
    height: 100%;
    width: 50%;
    overflow-y: scroll;

    padding-bottom: 100px;
    &.left {
      width: 20%;
    }
    &.page-page {
      color: rgb(26, 26, 26);
      padding: 40px;
      background-color: #fff;
    }
  }
}
</style>
