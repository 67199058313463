import axios from "axios";
import _ from "lodash";
import Fuse from "fuse.js";
/* eslint-disable no-debugger */

const state = {
  organizations: [],
  isLoaded: false
};
const getters = {
  organizations(state) {
    return _.sortBy(state.organizations, ['name']);
  },
  searchOrganizations(state) {
    return new Fuse(state.organizations, {
      keys: ["name"],
    });
  }
};
const actions = {
  load({ state }) {
    if (!state.isLoaded) {
      state.isLoaded = true;
      state.organizations = [];
      let url = `/api/organizations.json?`;
      return axios.get(url).then((result) => {
        state.organizations = result.data;
      });
    }
  },
  create({ state }, payload) {
    state.isLoaded = true;
    let url = `/api/organizations.json`;
    axios.post(url, payload).then((result) => {
      state.organizations.push(result.data);
    });
  },
  patch({ state }, { id, payload }) {
    state.isLoaded = true;
    return axios.patch(`/api/organizations/${id}.json`, payload).then((result) => {
      let i = state.organizations.indexOf(state.organizations.find(e => e.id == id))
      state.organizations[i] = Object.assign(state.organizations[i], result.data);
    });
  },
  delete({ state }, id) {
    state.isLoaded = true;
    let url = `/api/organizations/${id}.json`;
    return axios.delete(url).then(() => {
      let localObj = state.organizations.find(e => e.id == id);
      state.organizations.splice(state.organizations.indexOf(localObj), 1);
    });
  },
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
