

<template>
  <tr class="organization-row">
    <td class="nature pa-1">
      <v-chip
        :color="organization.is_third_party ? 'purple' : 'pink'"
        x-small
        dark
      >
        {{ organization.is_third_party ? "Tier" : "Interne" }}
      </v-chip>
    </td>
    <td class="pa-1">
      <router-link
        class="link"
        :to="{
          name: 'organization',
          params: { organization_id: organization.id },
        }"
      >
        {{ organization.name }}
      </router-link>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
// import _ from "lodash";
export default {
  components: {},
  props: {
    organization: {},
  },
  data: () => ({}),
  computed: {
    ...mapGetters({}),
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.nature {
  width: 1%;
}
</style>

