<template>
  <div>
    <div class="text-right">
      <v-btn
        v-if="user.direction_access && edition && !parentPage"
        @click="create(null, pages, true)"
        small
        class="ma-3"
        icon
        ><v-icon small>add</v-icon></v-btn
      >
    </div>
    <div :class="{ children: true, first: !parentPage }">
      <div
        :class="{ 'no-content': true, toolbar: '' }"
        v-if="
          parentPage &&
          parentPage.showChildren &&
          parentPage.children.length == 0
        "
      >
        Aucune page à l'intérieur
      </div>
      <Draggable
        sortable="true"
        :group="{ name: 'g1' }"
        :list="pages"
        handle=".handle"
        class="list"
        @change="onEnd(parentPage, ...arguments)"
        tag="ul"
      >
        <li v-for="page in pages" :key="`b-${page.id}`">
          <div
            :class="`header ${page.editing ? 'hover' : ''}`"
            @mousemove="page.editing = true"
            @mouseout="page.editing = false"
          >
            <router-link
              class="page-link"
              :to="{ name: 'page', params: { page_id: page.id } }"
            >
              <v-btn small icon @click="page.showChildren = !page.showChildren">
                <v-icon small>
                  {{
                    page.showChildren
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_right"
                  }}
                </v-icon>
              </v-btn>
              {{ page.icon }}
              {{ page.title }}
            </router-link>
            <div :class="{ toolbar: true, hover: page.editing }" v-if="edition">
              <v-btn small icon class="handle">
                <v-icon small>drag_indicator</v-icon></v-btn
              >
              <v-btn @click="create(page, page.children, false)" icon small
                ><v-icon small>add</v-icon></v-btn
              >
              <v-btn small icon @click="removePage(pages, page)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </div>
          </div>
          <div v-if="page.showChildren">
            <PageItem
              :pages="page.children"
              :rowsSelected="rowsSelected"
              :parentPage="page"
              :edition="edition"
            ></PageItem>
          </div>
        </li>
      </Draggable>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Draggable from "vuedraggable";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
// import _ from "lodash";
export default {
  name: "PageItem",
  components: { Draggable },
  props: {
    edition: {
      type: Boolean,
      required: true,
    },
    parentPage: {},
    pages: {
      required: true,
      type: Array,
    },
    rowsSelected: {},
  },
  data: () => ({
    showChildren: false,
  }),
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  methods: {
    create(parentPage, array) {
      let payload = {
        title: "Nouvelle page",
        parent: parentPage.id,
        position: array.length,
      };
      this.axios.post(`/api/pages.json`, payload).then((result) => {
        let page = result.data;
        page.editing = false;
        array.push(page);
        parentPage.descendant_count++;
      });
    },
    removePage(pages, page) {
      if (window.confirm("Etes-vous sûr ?")) {
        this.axios
          .delete(`/api/pages/${page.id}.json`)
          .then(() => {
            pages.splice(pages.indexOf(page), 1);
            page.descendant_count--;
          })
          .catch(() => {
            window.alert("Changement non enregistré");
          });
      }
    },
    patchPage(page, field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/pages/${page.id}.json`, payload)
        .then((result) => {
          page[field] = result.data[field];
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
      // .finally(() => {
      //   this.updateAfterChange();
      // });
    },
    onEnd(parentPage, { added, moved }) {
      if (added || moved) {
        this.pages.forEach((b, index) => {
          let payload = {};
          payload["position"] = index;
          payload["parent"] = parentPage ? parentPage.id : null;
          this.axios
            .patch(`/api/pages/${b.id}.json`, payload)
            .then(() => {
              // this.loadDocument(this.$route.params.document_id);
            })
            .catch(() => {
              window.alert("Changement non enregistré");
            });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  list-style-type: none;
  padding-left: 0;
}
li {
  padding-left: 24px;
}
.header {
  position: relative;
  // background-color: white;
  border-bottom: 0;
  &:hover {
    background-color: #f9f9f9;
  }
}

.list {
  min-height: 30px;
  padding-bottom: 6px;
  padding-right: 6px;
}

.children {
  // background-color: white;
  // border: 1px solid #eee;
  border-top: 0;
  position: relative;
  &.first {
    border: 0;
  }
  .no-content {
    font-size: 0.7em;
    color: #666;
    position: absolute;
    padding: 8px;
    padding-left: 40px;
  }
}
.toolbar {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  &.left {
    left: 0;
    width: 30px;
  }
  &.hover {
    display: inline-block;
  }
}
.page-link {
  background: #f6f6f6;
  display: block;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 2px;
  padding-left: 4px;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: bold;
  color: #666;
  &:hover {
    outline: 0;
    background: white;
    border: 1px solid rgb(180, 213, 255);
  }
}
</style>
