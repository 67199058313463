<template>
  <v-autocomplete
    v-bind="$attrs"
    v-bind:value="value"
    v-on="inputListeners"
    :items="tags"
    item-text="name"
    item-value="id"
    autocomplete="off"
    @input="selectItems"
    small-chips
    :search-input.sync="searchInput"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Commencez à entrer le nom d'un
          <strong>tag</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:append-item>
      <div class="pa-2" v-if="canCreate">
        <v-btn
          small
          color="primary"
          @click="
            createTag({
              name: searchInput,
            })
          "
          >Créer le tag</v-btn
        >
      </div>
    </template>
    <template v-slot:selection="{ item }">
      <v-chip :color="item.color">{{ item.name }}</v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-chip :color="item.color">{{ item.name }}</v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  inheritAttrs: true,
  props: {
    value: {},
    nature: { required: true },
    canCreate: {},
  },
  data: () => ({
    searchTime: null,
    isLoading: false,
    items: [],
    selectedItems: [],
    searchInput: "",
    idsSelected: [],
    isThirdParty: true,
  }),
  computed: {
    ...mapGetters({
      tags: "tags/tags",
    }),
    inputListeners: function () {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        input: function (event) {
          vm.$emit("input", event);
        },
      });
    },
  },
  methods: {
    ...mapActions({
      createTag: "tags/create",
    }),
    selectItems(value) {
      this.idsSelected = value;
      if (!Array.isArray(this.idsSelected)) {
        this.idsSelected = [value];
      }
      this.selectedItems = _.concat(
        [],
        this.items.filter((e) => this.idsSelected.includes(e.id))
      );
    },
  },
};
</script>
