<template>
  <v-container v-if="exps">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Exports</v-card-title>
          <v-list subheader>
            <v-list-item v-for="exp in exps" :key="`exp-${exp.id}`" link>
              <span>{{ exp.title }}</span>
              <v-chip
                v-for="file in exp.attachments"
                :key="`exp-${exp.id}-${file.uuid}`"
                :href="file.url"
              >
                {{ file.file_name }}
              </v-chip>
            </v-list-item>
          </v-list>
          <v-alert v-if="exps.length == 0">
            Aucun exports pour le moment
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    exps: null,
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    load() {
      this.axios.get(`/api/exports.json`).then((result) => {
        this.exps = result.data;
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style lang="scss" scoped>
</style>
