/* eslint-disable no-debugger */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import user from "./components/authentication/user.js";
import organizations from "./components/organizations/organizations.js";
import articles from "./components/articles/articles.js";
import tags from "./components/tags/tags.js";
import stocks from "./components/stocks/stocks.js";
import groups from "./components/groups/groups.js";
import messages from "./components/messages/messages.js";
import pages from "./components/pages/pages.js";

export default new Vuex.Store({
  modules: {
    user: user,
    organizations: organizations,
    articles: articles,
    tags: tags,
    stocks: stocks,
    groups: groups,
    messages: messages,
    pages: pages,
  },
  state: {
    pageLoading: false,
  },
  actions: {
    setPageLoading({ state }, value) {
      state.pageLoading = value;
    }
  },
  getters: {
    pageLoading(state) {
      return state.pageLoading;
    }
  },
});
