import axios from "axios";
import _ from "lodash";
/* eslint-disable no-debugger */

const state = {
  group_natures: [],
  isLoaded: false
};
const getters = {
  natures(state) {
    return _.sortBy(state.group_natures, ['name']);
  },
  mapNatures(state) {
    let mapNatures = {};
    state.group_natures.forEach(t => { mapNatures[t.id] = { name: t.name } });
    return mapNatures;
  },
};
const actions = {
  load({ state }) {
    if (!state.isLoaded) {
      state.isLoaded = true;
      state.group_natures = [];
      let url = `/api/group_natures.json?`;
      return axios.get(url).then((result) => {
        state.group_natures = result.data;
      });
    }
  },
  create({ state }, payload) {
    state.isLoaded = true;
    let url = `/api/group_natures.json`;
    axios.post(url, payload).then((result) => {
      state.group_natures.push(result.data);
    });
  },
  delete({ state }, id) {
    state.isLoaded = true;
    let url = `/api/group_natures/${id}.json`;
    return axios.delete(url).then(() => {
      let localObj = state.group_natures.find(e => e.id == id);
      state.group_natures.splice(state.group_natures.indexOf(localObj), 1);
    });
  },

};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
