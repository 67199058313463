
<template>
  <tr
    v-if="exchange"
    class="exchange"
    :class="{ exchange: true, orphan: isOrphan }"
  >
    <td class="" v-if="rowsSelected">
      <v-checkbox
        v-model="rowsSelected.ids"
        dense
        x-small
        class="ma-0 pa-0"
        hide-details
        :value="exchange.id"
      ></v-checkbox>
    </td>
    <td class="organization" v-if="emitterObj.is_third_party">
      {{ emitter }}
    </td>
    <td :class="`states ${colorNature}`">
      <v-chip
        x-small
        v-if="exchange.invoice"
        class="exchange-stage"
        :color="`${exchange.invoice_acted_date ? 'green' : 'green lighten-3'}`"
        :to="{
          name: 'document',
          params: { document_id: exchange.invoice },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">receipt</v-icon>
        <span v-if="exchange.invoice_acted_date" class="text--green">
          Facturé {{ exchange.invoice_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Facturation</div>
      </v-chip>
      <v-chip
        v-if="exchange.delivery"
        x-small
        class="exchange-stage"
        :color="`${
          exchange.delivery_acted_date ? 'yellow' : 'yellow lighten-3'
        }`"
        :to="{
          name: 'document',
          params: { document_id: exchange.delivery },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">local_shipping</v-icon>
        <span v-if="exchange.delivery_acted_date" class="text--green">
          Livré {{ exchange.delivery_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Livraison</div>
      </v-chip>
      <v-chip
        v-if="exchange.order"
        x-small
        class="exchange-stage"
        :color="`${exchange.order_acted_date ? 'red' : 'red lighten-3'}`"
        :to="{
          name: 'document',
          params: { document_id: exchange.order },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">settings_suggest</v-icon>
        <span v-if="exchange.order_acted_date" class="text--green">
          Commandé {{ exchange.order_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Commande</div>
      </v-chip>
      <v-chip
        v-if="exchange.proposal"
        x-small
        class="exchange-stage"
        :color="`${exchange.proposal_acted_date ? 'blue' : 'blue lighten-3'}`"
        :to="{
          name: 'document',
          params: { document_id: exchange.proposal },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">request_quote</v-icon>
        <span v-if="exchange.proposal_acted_date" class="text--green">
          Proposé {{ exchange.proposal_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Proposition</div>
      </v-chip>
    </td>
    <td class="organization" v-if="!emitterObj.is_third_party">
      {{ recipient }}
    </td>
    <td class="article" v-if="!hideDescription">
      <div>{{ article }}</div>
      <div class="description" v-html="exchange.description"></div>
    </td>
    <!-- <td class="date">
      <span v-if="exchange.delivery_supposed_date">
        {{ exchange.delivery_supposed_date | moment("DD/MM/YYYY") }}
      </span>
    </td> -->
    <td class="numbers">
      {{ exchange.source_q ? exchange.source_q + "/" : "" }}
      {{ exchange.quantity ? exchange.quantity : "-" }}
    </td>
    <td class="numbers">
      {{ exchange.gross_amount ? exchange.gross_amount : "-" }}
    </td>
  </tr>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import _ from "lodash";
export default {
  components: {},
  props: {
    exchange: {},
    rowsSelected: {},
    hideDescription: {
      default: false,
    },
    // exchangesSelected: {},
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      organizations: "organizations/organizations",
    }),
    colorNature() {
      if (this.exchange.invoice) {
        return !this.exchange["invoice_acted°date"]
          ? "green lighten-4"
          : "green";
      } else if (this.exchange.delivery) {
        return !this.exchange["delivery_acted_date"]
          ? "yellow lighten-4"
          : "yellow";
      } else if (this.exchange.order) {
        return !this.exchange["order_acted_date"] ? "red lighten-4" : "red";
      } else if (this.exchange.proposal) {
        return !this.exchange["proposal_acted_date"]
          ? "blue lighten-4"
          : "blue";
      }
      return "Inconnu";
    },
    isOrphan() {
      return (
        !this.exchange.proposal &&
        !this.exchange.order &&
        !this.exchange.delivery &&
        !this.exchange.invoice
      );
    },
    article() {
      if (Number.isInteger(this.exchange.article)) {
        return this.exchange.article
          ? this.articles.find((a) => a.id == this.exchange.article).name
          : "";
      } else {
        return this.exchange.article?.name;
      }
    },
    emitterObj() {
      if (Number.isInteger(this.exchange.emitter)) {
        return this.exchange.emitter
          ? this.organizations.find((a) => a.id == this.exchange.emitter)
          : null;
      } else {
        return this.exchange.emitter;
      }
    },
    emitter() {
      if (Number.isInteger(this.exchange.emitter)) {
        return this.exchange.emitter
          ? this.organizations.find((a) => a.id == this.exchange.emitter).name
          : "";
      } else {
        return this.exchange.emitter?.name;
      }
    },
    recipient() {
      if (Number.isInteger(this.exchange.recipient)) {
        return this.exchange.recipient
          ? this.organizations.find((a) => a.id == this.exchange.recipient).name
          : "";
      } else {
        return this.exchange.recipient?.name;
      }
    },
  },
  methods: {
    selectArticle(exchange) {
      let payload = {
        article: exchange.article,
      };
      if (exchange.article) {
        let article = this.articles.find((a) => a.id == exchange.article);
        if (!exchange.description) {
          exchange.description = payload["description"] = article.description;
        }
        if (!exchange.unit_gross_amount) {
          let prices = _.sortBy(article.prices, ["quantity_min"]);
          prices.forEach((price) => {
            if (exchange.quantity >= price.quantity_min) {
              exchange.unit_gross_amount = payload["unit_gross_amount"] =
                price.unit_gross_amount;
              return;
            }
          });
        }
      }
      // this.patchPayloadExchange(payload);
    },
    patchExchange(exchange, field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/exchanges/${exchange.id}.json`, payload)
        .then((result) => {
          exchange[field] = result.data[field];
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.exchange {
  font-size: 0.9em;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  min-height: 30px;
  .states {
    min-width: 100px;
    .exchange-stage {
      display: block;
      font-size: 0.6em;
      &.router-link-active {
        font-weight: bold;
      }
    }
  }
  &.orphan {
    background: rgb(255, 187, 187);
  }
  &.no_rows {
    background: rgb(255, 169, 56);
  }
  .organization {
    font-size: 0.9em;
    width: 100px;
    min-width: 100px;
    border-right: 1px solid #eee;
    padding: 4px;
  }
  .article {
    font-size: 0.9em;
    border-right: 1px solid #eee;
    padding: 4px;
    .description {
      font-size: 0.8em;
      max-height: 14px;
      overflow: hidden;
    }
  }
  .date {
    font-size: 0.9em;
    width: 100px;
    min-width: 100px;
    text-align: right;
    border-right: 1px solid #eee;
    padding: 4px;
  }
  .numbers {
    font-size: 0.9em;
    width: 70px;
    min-width: 70px;
    text-align: right;
    border-right: 1px solid #eee;
    padding: 4px;
  }
}
</style>

