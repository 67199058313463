<template>
  <div>
    <v-row
      align="center"
      v-for="(custom_row_content, custom_row_key) in custom_data_list"
      :key="`cd-${custom_row_key}`"
      class="pa-0 ma-1"
    >
      <v-col class="text-right caption pa-0 ma-0 mr-1">
        {{ custom_row_content["label"] }}
      </v-col>
      <v-col class="pa-0 ma-0">
        <div v-if="custom_row_content['nature'] == 'Char'">
          <v-text-field
            hide-details
            solo
            dense
            v-model="custom_row_content['value']"
            @input="saveValue(custom_row_content)"
          ></v-text-field>
        </div>
        <div v-else-if="custom_row_content['nature'] == 'Integer'">
          <v-text-field
            hide-details
            solo
            dense
            type="number"
            v-model="custom_row_content['value']"
            @input="saveValue(custom_row_content)"
          ></v-text-field>
        </div>
        <div v-else-if="custom_row_content['nature'] == 'Date'">
          <FoDatePicker
            hide-details
            solo
            dense
            clearable
            v-model="custom_row_content['value']"
            @input="saveValue(custom_row_content)"
          ></FoDatePicker>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FoDatePicker from "../FoDatePicker.vue";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {
    FoDatePicker,
  },
  props: {
    nature: {},
    entity: {},
    custom_data: {},
  },
  data: () => ({}),
  computed: {
    ...mapGetters({}),
    custom_data_list() {
      return Object.keys(this.custom_data).map((key) =>
        Object.assign({ key: key }, this.custom_data[key])
      );
    },
  },
  methods: {
    ...mapActions({}),
    saveValue(custom_row_content) {
      clearTimeout(this.timeOutSave);
      this.timeOutSave = setTimeout(() => {
        this.custom_data[custom_row_content["key"]] = custom_row_content;
        this.axios
          .patch(`/api/${this.nature}/${this.entity.id}.json`, {
            custom_data: this.custom_data,
          })
          .catch(() => {
            window.alert("Changement non enregistré");
          });
      }, 300);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
</style>
