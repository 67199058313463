<template>
  <div>
    <!-- <div class="menu" v-if="editor">
      <button
        @click="setLink"
        :class="{ 'is-active': editor.isActive('link') }"
      >
        <v-icon small>insert_link</v-icon>
      </button>
      <button
        @click="editor.chain().focus().unsetLink().run()"
        :disabled="!editor.isActive('link')"
      >
        <v-icon small>link_off</v-icon>
      </button> -->
    <!-- 
      <v-dialog v-model="dialogImage" width="1000">
        <template v-slot:activator="{ on, attrs }">
          <button v-bind="attrs" v-on="on">
            <v-icon small>image</v-icon>
          </button>
        </template>

        <v-card class="pa-3">
          <ImageUploader @file-uploaded="addImageFromUpload"></ImageUploader>
          <hr />
          <v-text-field label="URL"></v-text-field>
          <v-btn @click="addImage">Ajouter</v-btn>
        </v-card>
      </v-dialog>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        <v-icon small>title</v-icon>1
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        <v-icon small>title</v-icon>2
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        <v-icon small>title</v-icon>3
      </button>
      <button
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        P
      </button> -->
    <!-- <button
      @click="editor.chain().focus().toggleBold().run()"
      :class="{ 'is-active': editor.isActive('bold') }"
    >
      <v-icon small>format_bold</v-icon>
    </button>
    <button
      @click="editor.chain().focus().toggleItalic().run()"
      :class="{ 'is-active': editor.isActive('italic') }"
    >
      <v-icon small>format_italic</v-icon>
    </button>
    <button
      @click="editor.chain().focus().toggleStrike().run()"
      :class="{ 'is-active': editor.isActive('strike') }"
    >
      <v-icon small>format_strikethrough</v-icon>
    </button> -->
    <!-- <button
        @click="editor.chain().focus().setTextAlign('left').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
      >
        <v-icon small>format_align_left</v-icon>
      </button>
      <button
        @click="editor.chain().focus().setTextAlign('center').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
      >
        <v-icon small>format_align_left</v-icon>
      </button>
      <button
        @click="editor.chain().focus().setTextAlign('right').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
      >
        <v-icon small>format_align_right</v-icon>
      </button>
      <button
        @click="editor.chain().focus().setTextAlign('justify').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
      >
        <v-icon small>format_align_justify</v-icon>
      </button>
      <button
        @click="
          editor.chain().focus().toggleHighlight({ color: '#ffc078' }).run()
        "
        :class="{
          'is-active': editor.isActive('highlight', { color: '#ffc078' }),
        }"
      >
        <v-icon small style="color: #ffc078">format_color_fill</v-icon>
      </button>
      <button
        @click="
          editor.chain().focus().toggleHighlight({ color: '#8ce99a' }).run()
        "
        :class="{
          'is-active': editor.isActive('highlight', { color: '#8ce99a' }),
        }"
      >
        <v-icon small style="color: #8ce99a">format_color_fill</v-icon>
      </button>
      <button
        @click="
          editor.chain().focus().toggleHighlight({ color: '#74c0fc' }).run()
        "
        :class="{
          'is-active': editor.isActive('highlight', { color: '#74c0fc' }),
        }"
      >
        <v-icon small style="color: #74c0fc">format_color_fill</v-icon>
      </button>
      <button
        @click="
          editor.chain().focus().toggleHighlight({ color: '#b197fc' }).run()
        "
        :class="{
          'is-active': editor.isActive('highlight', { color: '#b197fc' }),
        }"
      >
        <v-icon small style="color: #b197fc">format_color_fill</v-icon>
      </button>
      <button
        @click="
          editor.chain().focus().toggleHighlight({ color: '#ffa8a8' }).run()
        "
        :class="{
          'is-active': editor.isActive('highlight', { color: '#ffa8a8' }),
        }"
      >
        <v-icon small style="color: #ffa8a8">format_color_fill</v-icon>
      </button>
      <button
        @click="editor.chain().focus().unsetHighlight().run()"
        :disabled="!editor.isActive('highlight')"
      >
        <v-icon small style="color: #333">format_color_fill</v-icon>
      </button>

      <button
        @click="editor.chain().focus().setImage({ size: 'small' }).run()"
        :class="{
          'is-active': editor.isActive('custom-image', {
            size: 'small',
          }),
        }"
      >
        Small
      </button>
      <button
        @click="editor.chain().focus().setImage({ size: 'medium' }).run()"
        :class="{
          'is-active': editor.isActive('custom-image', {
            size: 'medium',
          }),
        }"
      >
        Medium
      </button>
      <button
        @click="editor.chain().focus().setImage({ size: 'large' }).run()"
        :class="{
          'is-active': editor.isActive('custom-image', {
            size: 'large',
          }),
        }"
      >
        Large
      </button> -->
    <!-- </div> -->
    <BubbleMenu
      :editor="editor"
      :tippy-options="{ duration: 100 }"
      v-if="editor"
      class="bubble-menu"
    >
      <button
        @click="setLink"
        :class="{ 'is-active': editor.isActive('link') }"
      >
        <v-icon small>insert_link</v-icon>
      </button>
      <button
        @click="editor.chain().focus().unsetLink().run()"
        :disabled="!editor.isActive('link')"
      >
        <v-icon small>link_off</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <v-icon small>format_bold</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <v-icon small>format_italic</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        <v-icon small>format_strikethrough</v-icon>
      </button>
    </BubbleMenu>
    <EditorContent :editor="editor" />
  </div>
</template>
<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
// import BubbleMenu from "@tiptap/extension-bubble-menu";
import Placeholder from "@tiptap/extension-placeholder";

// import History from "@tiptap/extension-history";
// import Document from "@tiptap/extension-document";
// import Paragraph from "@tiptap/extension-paragraph";
// import Text from "@tiptap/extension-text";
import Typography from "@tiptap/extension-typography";
// import Image from "@tiptap/extension-image";
// import Dropcursor from "@tiptap/extension-dropcursor";
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
// import ImageUploader from "./ImageUploader.vue";
import CustomImage from "./CustomImage.js";

export default {
  components: {
    // ImageUploader,
    EditorContent,
    BubbleMenu,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      default: "",
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    addImageFromUpload(url) {
      this.editor.chain().focus().setImage({ src: url, size: "large" }).run();
    },
    addImage() {
      const url = window.prompt("URL");
      if (url) {
        this.editor.chain().focus().setImage({ src: url, size: "large" }).run();
      }
    },
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          image: false,
          draggable: true,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        // History,
        Typography,
        // Image,
        Highlight.configure({ multicolor: true }),
        Text,
        Link.configure({
          openOnClick: false,
        }),
        CustomImage.configure({
          HTMLAttributes: {},
        }),
      ],
      // autofocus: true,
      editable: true,
      injectCSS: false,
      content: this.value,
      onUpdate: () => {
        // HTML
        // this.$emit("update:value", this.editor.getHTML());
        this.$emit("input", this.editor.getHTML());
        // JSON
        // this.$emit('update:value', this.editor.getJSON())
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss">
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
}
.ProseMirror {
  outline: 0;
  color: #333;
  min-height: 20px;
  margin: 4px;
  white-space: pre-wrap;
  // font-family: OpenSans;

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0.8em;
  }
  a {
    color: rgba(74, 89, 104, 0.9) !important;
    text-decoration: none;
    border-bottom: 1px solid rgba(55, 53, 47, 0.16);
  }
  p {
    margin-bottom: 0.2em;
  }
  hr {
    margin: 0 8px;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }
  img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }
  .custom-image {
    &.small {
      max-width: 200px;
    }
    &.medium {
      max-width: 500px;
    }
    &.large {
      max-width: 100%;
    }
  }
}
.menu {
  background-color: white;
  // border-radius: 12px;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  position: sticky;
  top: 0;
  z-index: 1;

  button {
    border: 1px solid #f6f6f6;
    padding: 4px 8px;
    font-size: 0.6em;

    &.is-active {
      background-color: #ccc;
      color: white;
    }
  }
}
.bubble-menu {
  background-color: white;
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #ccc;

  button {
    border: 1px solid #f6f6f6;
    padding: 4px 8px;
    font-size: 0.6em;

    &.is-active {
      background-color: #ccc;
      color: white;
    }
  }
}
</style>