<template>
  <div>
    <div v-if="message.body">
      <component
        :is="{ template: `<div>${message.body}</div>`, functionnal: true }"
      ></component>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({}),
  props: {
    message: {},
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
  },
};
</script>
<style lang="scss" scoped>
</style>
