import axios from "axios";
import moment from "moment";
// import _ from "lodash";
/* eslint-disable no-debugger */

const state = {
  messages: [],
  unreadCount: null,
  isLoaded: false
};
const getters = {
  unreadCount(state) {
    return state.unreadCount;
  },
};
const actions = {
  loadCount({ state }) {
    return axios.get(`/api/messages/unread_count.json`).then((result) => {
      state.unreadCount = result.data;
    })
  },
  markAsRead(undefined, message) {
    axios.patch(`/api/messages/${message.id}.json`, {
      read_at: moment().toDate(),
    }).then((result) => {
      message.read_at = result.data.read_at;
      state.unreadCount--;
    });
  },
  markAsUnread(undefined, message) {
    axios.patch(`/api/messages/${message.id}.json`, {
      read_at: null,
    }).then((result) => {
      message.read_at = result.data.read_at;
      state.unreadCount++;
    });
  },
  deleteMessage(undefined, { messages, message }) {
    axios.delete(`/api/messages/${message.id}.json`).then(() => {
      messages.splice(messages.indexOf(message), 1);
      if (!message.unread_at) {
        state.unreadCount--;
      }
    });
  },
  load({ state }) {
    if (!state.isLoaded) {
      state.isLoaded = true;
      state.messages = [];
      let url = `/api/messages.json?`;
      return axios.get(url).then((result) => {
        state.messages = result.data;
      });
    }
  },
  create({ state }, payload) {
    state.isLoaded = true;
    let url = `/api/messages.json`;
    axios.post(url, payload).then((result) => {
      state.messages.push(result.data);
    });
  },
  delete({ state }, id) {
    state.isLoaded = true;
    let url = `/api/messages/${id}.json`;
    return axios.delete(url).then(() => {
      let localObj = state.messages.find(e => e.id == id);
      state.messages.splice(state.messages.indexOf(localObj), 1);
    });
  },

};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
