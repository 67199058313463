<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <DocumentItem
          v-for="official_document in official_documents"
          :key="`official_document-${official_document.id}`"
          :document="official_document"
          @click.native="openDoc(official_document)"
          @query="addToQuery"
        >
        </DocumentItem>
        <div v-if="official_documents.length == 0">
          Pas de facture en retard
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import DocumentItem from "./DocumentItem.vue";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    DocumentItem,
  },
  data: () => ({
    official_documents: [],
  }),
  watch: {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    openDoc(document) {
      this.$router.push({
        name: "document",
        params: { document_id: document.id },
      });
      // this.documentsSelected.push(document.id);
    },
    loadDocuments() {
      let url = `/api/official_documents.json?official_nature=invoice&direction=${
        this.$route.params.direction
      }&is_paid=False&deadline_date__lt=${moment().format("YYYY-MM-DD")}`;
      this.axios.get(url).then((result) => {
        this.official_documents = result.data;
      });
    },
  },
  mounted() {
    this.loadDocuments(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadDocuments(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
