<template>
  <div v-if="page" class="page-container">
    <div class="toolbar">
      <v-toolbar dense flat v-if="user.direction_access">
        <v-btn
          @click="edition = !edition"
          :color="edition ? 'primary' : ''"
          small
          class="mr-1"
        >
          <span v-if="edition">Affichage standard</span>
          <span v-else>Edition</span>
        </v-btn>
        <v-btn
          @click="
            page.is_system = !page.is_system;
            patchPage('is_system', page.is_system);
          "
          :color="page.is_system ? 'primary' : ''"
          small
          class="mr-3"
        >
          <span v-if="page.is_system">Page système</span>
          <span v-else>Page normale</span>
        </v-btn>

        <v-btn
          v-if="edition"
          @click="patchPageScript()"
          small
          :color="oldData != page.script + page.body ? 'primary' : ''"
          >Sauvegarder</v-btn
        >
      </v-toolbar>
    </div>
    <div v-if="edition" class="wrapper">
      <EmojiPicker @emoji="insert" :search="search">
        <div
          slot="emoji-invoker"
          slot-scope="{ events: { click: clickEvent } }"
          @click.stop="clickEvent"
        >
          <div class="emoji-invoker ma-1">
            {{ page.icon ? page.icon : "-" }}
          </div>
        </div>
        <div class="emoji-picker" slot="emoji-picker" slot-scope="{ emojis }">
          <div>
            <div>
              <input type="text" v-model="search" />
            </div>
            <div>
              <div v-for="(emojiGroup, category) in emojis" :key="category">
                <h5>{{ category }}</h5>
                <div>
                  <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    class="emoji-btn pa-1"
                    @click="
                      page.icon = emoji;
                      patchPage('icon', emoji);
                    "
                    :title="emojiName"
                    >{{ emoji }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </EmojiPicker>
    </div>
    <div>
      <div v-if="page.is_system">
        <div v-if="edition">
          <v-row>
            <v-col>
              <CodeEditor
                v-model="page.body"
                :languages="[['html', 'HTML']]"
                font_size="13px"
                width="100%"
              ></CodeEditor>
              <CodeEditor
                v-model="page.script"
                :languages="[['js', 'JS']]"
                font_size="13px"
                width="100%"
              ></CodeEditor>
            </v-col>
            <v-col>
              <v-text-field
                v-model="page.title"
                @click="patchPage('title', page.title)"
              ></v-text-field>
              <component :is="`MyExternalComponent${page.id}`"></component>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <div class="document">
            <v-card class="pa-4 elevation-4">
              <h1>{{ page.icon }} {{ page.title }}</h1>
              <component :is="`MyExternalComponent${page.id}`"></component>
            </v-card>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!edition">
          <div class="document">
            <v-card class="pa-4 elevation-4">
              <h1>{{ page.title }}</h1>
              <div class="ProseMirror" v-html="page.body"></div>
            </v-card>
          </div>
        </div>
        <div v-else>
          <v-text-field
            v-model="page.title"
            @click="patchPage('title', page.title)"
          ></v-text-field>
          <TipTap
            v-model="page.body"
            @input="patchPage('body', ...arguments)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import { mapGetters } from "vuex";
import TipTap from "../tiptap/TipTap.vue";
import Vue from "vue";
import CodeEditor from "simple-code-editor";
// import vuetify from "../../plugins/vuetify.js";
import { VBtn, VTextField } from "vuetify/lib";

// import ExchangesList from "../exchanges/ExchangesList.vue";
import OrganizationsList from "../organizations/OrganizationsList.vue";
import VueExcelEditor from "vue-excel-editor";
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
import { EmojiPicker } from "vue-emoji-picker";

export default {
  components: {
    TipTap,
    // StakeholderPicker,
    // RessourcePicker,
    CodeEditor,
    EmojiPicker,
  },
  data: () => ({
    oldData: null,
    page: null,
    edition: false,
    newMember: null,
    search: "",
  }),
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  watch: {
    edition() {
      this.loadPage(this.$route);
      this.$forceUpdate();
    },
  },
  methods: {
    loadPage(route) {
      var vm = this;
      this.axios
        .get(`/api/pages/${route.params.page_id}.json`)
        .then((result) => {
          vm.page = result.data;
          vm.oldData = vm.page.script + vm.page.body;
          setTimeout(() => {
            if (vm.page.is_system) {
              let objScript = eval("(" + vm.page.script + ")");
              objScript.template = vm.page.body;
              objScript.components = {
                VBtn,
                VTextField,
                OrganizationsList,
                VueExcelEditor,
                OrganizationPicker,
              };

              Vue.component(`MyExternalComponent${vm.page.id}`, objScript);
              // let objScript = eval("(" + vm.page.script + ")");
              // objScript.template = vm.page.body;
              // objScript.components = {
              //   ExchangesList,
              // };
              // objScript.computed = {
              //   ...mapGetters({
              //     user: "user/user",
              //     articles: "articles/articles",
              //     organizations: "organizations/organizations",
              //   }),
              // };
              // var ComponentClass = Vue.extend(objScript);
              // var vuetify = new Vuetify({
              //   icons: {
              //     iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
              //   },
              // });
              // var instance = new ComponentClass({
              //   router: router,
              //   vuetify,
              // });
              // // instance.components = vm.components;
              // instance.$mount("#container"); // pass nothing
              // // vm.$refs.container = instance.$el;
              vm.$forceUpdate();
            }
          });
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    patchPageScript() {
      this.axios
        .patch(`/api/pages/${this.page.id}.json`, {
          script: this.page.script,
          body: this.page.body,
        })
        .then(() => {
          this.loadPage(this.$route);
          this.$forceUpdate();
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    patchPage(field, value) {
      let payload = {};
      payload[field] = value;
      this.axios.patch(`/api/pages/${this.page.id}.json`, payload).catch(() => {
        window.alert("Changement non enregistré");
      });
      // .finally(() => {
      //   this.updateAfterChange();
      // });
    },
  },
  mounted() {
    this.page = null;
    this.loadPage(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.page = null;
    this.loadPage(to);
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  .toolbar {
    margin-bottom: 20px;
  }
  .document {
    width: 70%;
    padding: 0 40px 40px 0;
    background-color: white;
  }
}

/* Tailwind CSS-styled demo is available here: https://codepen.io/DCzajkowski/pen/Brxvzj */

.wrapper {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  background: #f6f6f6;
  font-size: 3.5em;
  text-align: center;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 4px #ccc;
  z-index: 10;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-btn {
  font-size: 1.3em;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #ececec;
    cursor: pointer;
  }
}
</style>

