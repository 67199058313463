<template>
  <tr>
    <!-- <td class="actions">
      <slot></slot>
    </td> -->
    <td class="pa-1">
      <a class="block-link" @click="$emit('input', article)">
        <div class="name">{{ article.name }}</div>
        <div>
          <v-chip
            x-small
            v-for="tag in article.tags"
            :key="`ta-${tag}`"
            :color="mapTags[tag].color"
          >
            {{ mapTags[tag].name }}
          </v-chip>
          <span class="no-tag" v-if="article.tags.length == 0"
            >Aucune catégorie</span
          >
        </div>
        <div>
          <v-chip
            v-for="price in prices"
            x-small
            :key="`art-${article.id}price-${price.id}`"
          >
            {{ price.unit_gross_amount }} €
          </v-chip>
        </div>
      </a>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
// import _ from "lodash";
export default {
  components: {},
  props: {
    article: {},
    recipientId: null,
    emitterId: null,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      mapTags: "tags/mapTags",
    }),
    prices() {
      let prices = this.article.prices;
      if (this.emitterId) {
        prices = prices.filter(
          (p) => p.emitter == this.emitterId || p.emitter == null
        );
      }
      if (this.recipientId) {
        prices = prices.filter(
          (p) => p.recipient == this.recipientId || p.recipient == null
        );
      }
      return prices;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.block-link {
  display: block;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
  font-size: 0.9em;
  border-bottom: 1px solid #f6f6f6;
  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // max-width: 500px;
  }
  .no-tag {
    color: #aaa;
    font-size: 0.7em;
  }
  &:hover {
    background: #f6f6f6;
  }
}
</style>

