<template>
  <v-container>
    <v-card v-if="element">
      <v-toolbar flat class="toolbar">
        <v-btn icon :to="{ name: 'stocks' }" class="mr-3">
          <v-icon>navigate_before</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small @click="deleteElement()">Supprimer l'élément</v-btn>
      </v-toolbar>
      <table class="fo-table">
        <tr>
          <td :class="`states ${sourceColorNature}`">
            <span v-if="element.source">
              <router-link
                v-if="element.source.invoice"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.source.invoice },
                  query: { exchange_id: element.source.id },
                }"
              >
                <span
                  v-if="element.source.invoice_acted_date"
                  class="text--green"
                >
                  Facturé
                  {{ element.source.invoice_acted_date | moment("DD/MM/YYYY") }}
                </span>
                <span v-else> Facturation </span>
              </router-link>
              <router-link
                v-if="element.source.delivery"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.source.delivery },
                  query: { exchange_id: element.source.id },
                }"
              >
                <span
                  v-if="element.source.delivery_acted_date"
                  class="text--green"
                >
                  Livré
                  {{
                    element.source.delivery_acted_date | moment("DD/MM/YYYY")
                  }}
                </span>
                <span v-else> Livraison </span>
              </router-link>
              <router-link
                v-if="element.source.order"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.source.order },
                  query: { exchange_id: element.source.id },
                }"
              >
                <span
                  v-if="element.source.order_acted_date"
                  class="text--green"
                >
                  Commandé
                  {{ element.source.order_acted_date | moment("DD/MM/YYYY") }}
                </span>
                <span v-else> Commande </span>
              </router-link>
              <router-link
                v-if="element.source.proposal"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.source.proposal },
                  query: { exchange_id: element.source.id },
                }"
              >
                <span
                  v-if="element.source.proposal_acted_date"
                  class="text--green"
                >
                  Proposé
                  {{
                    element.source.proposal_acted_date | moment("DD/MM/YYYY")
                  }}
                </span>
                <span v-else> Proposition </span>
              </router-link>
            </span>
          </td>
          <td style="width: 100%"></td>
          <td :class="`states ${destinationColorNature}`">
            <span v-if="element.destination">
              <router-link
                v-if="element.destination.invoice"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.destination.invoice },
                  query: { exchange_id: element.destination.id },
                }"
              >
                <span
                  v-if="element.destination.invoice_acted_date"
                  class="text--green"
                >
                  Facturé
                  {{
                    element.destination.invoice_acted_date
                      | moment("DD/MM/YYYY")
                  }}
                </span>
                <span v-else> Facturation </span>
              </router-link>
              <router-link
                v-if="element.destination.delivery"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.destination.delivery },
                  query: { exchange_id: element.destination.id },
                }"
              >
                <span
                  v-if="element.destination.delivery_acted_date"
                  class="text--green"
                >
                  Livré
                  {{
                    element.destination.delivery_acted_date
                      | moment("DD/MM/YYYY")
                  }}
                </span>
                <span v-else> Livraison </span>
              </router-link>
              <router-link
                v-if="element.destination.order"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.destination.order },
                  query: { exchange_id: element.destination.id },
                }"
              >
                <span
                  v-if="element.destination.order_acted_date"
                  class="text--green"
                >
                  Commandé
                  {{
                    element.destination.order_acted_date | moment("DD/MM/YYYY")
                  }}
                </span>
                <span v-else> Commande </span>
              </router-link>
              <router-link
                v-if="element.destination.proposal"
                class="exchange-stage"
                :to="{
                  name: 'document',
                  params: { document_id: element.destination.proposal },
                  query: { exchange_id: element.destination.id },
                }"
              >
                <span
                  v-if="element.destination.proposal_acted_date"
                  class="text--green"
                >
                  Proposé
                  {{
                    element.destination.proposal_acted_date
                      | moment("DD/MM/YYYY")
                  }}
                </span>
                <span v-else> Proposition </span>
              </router-link>
            </span>
          </td>
        </tr>
      </table>
      <v-card-text>
        <OrganizationPicker
          v-model="element.orga_dest_id"
          label="Revendu à"
          clearable
        ></OrganizationPicker>
        <v-text-field
          name="Référence"
          label="Référence"
          v-model="element.unique_reference"
          @change="patchElement('unique_reference', element.unique_reference)"
        ></v-text-field>
        <v-text-field
          name="Quantité"
          label="Quantité"
          type="number"
          v-model="element.quantity"
          @change="patchElement('quantity', element.quantity)"
        ></v-text-field>
        <v-text-field
          name="Prix d'achat"
          label="Prix d'achat"
          type="number"
          v-model="element.buying_amount"
          @change="patchElement('buying_amount', element.buying_amount)"
        ></v-text-field>
        <v-text-field
          name="Prix de vente"
          label="Prix de vente"
          type="number"
          v-model="element.selling_amount"
          @change="patchElement('selling_amount', element.selling_amount)"
        ></v-text-field>
        <div>
          <TagsPicker
            v-model="element.tags"
            multiple
            @input="patchElement('tags', element.tags)"
          ></TagsPicker>
        </div>
        <div>Description</div>
        <VueEditor
          v-model="element.description"
          @input="patchElement('description', element.description)"
        >
        </VueEditor>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */

import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";
import TagsPicker from "../tags/TagsPicker.vue";
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
export default {
  components: {
    VueEditor,
    TagsPicker,
    OrganizationPicker,
  },
  data: () => ({
    element: null,
  }),
  computed: {
    ...mapGetters({
      elements: "stocks/elements",
    }),
    sourceColorNature() {
      if (this.element.source?.invoice) {
        return !this.element.source["invoice_acted°date"]
          ? "green lighten-4"
          : "green";
      } else if (this.element.source?.delivery) {
        return !this.element.source["delivery_acted_date"]
          ? "yellow lighten-4"
          : "yellow";
      } else if (this.element.source?.order) {
        return !this.element.source["order_acted_date"]
          ? "red lighten-4"
          : "red";
      } else if (this.element.source?.proposal) {
        return !this.element.source["proposal_acted_date"]
          ? "blue lighten-4"
          : "blue";
      }
      return "";
    },
    destinationColorNature() {
      if (this.element.destination?.invoice) {
        return !this.element.destination["invoice_acted°date"]
          ? "green lighten-4"
          : "green";
      } else if (this.element.destination?.delivery) {
        return !this.element.destination["delivery_acted_date"]
          ? "yellow lighten-4"
          : "yellow";
      } else if (this.element.destination?.order) {
        return !this.element.destination["order_acted_date"]
          ? "red lighten-4"
          : "red";
      } else if (this.element.destination?.proposal) {
        return !this.element.destination["proposal_acted_date"]
          ? "blue lighten-4"
          : "blue";
      }
      return "";
    },
  },
  methods: {
    ...mapActions({
      storeDelete: "stocks/delete",
      storePatch: "stocks/patch",
    }),
    patchElement(field, value) {
      let payload = {};
      payload[field] = value;
      this.storePatch({ id: this.element.id, payload: payload }).catch(() => {
        window.alert("Changement non enregistré");
      });
    },
    deleteElement() {
      this.storeDelete(this.element.id)
        .then(() => {
          this.$router.push({ name: "stocks" });
        })
        .catch(() => {
          window.alert("Echec de la suppresion");
        });
    },
    loadElement() {
      this.element = this.elements.find(
        (e) => e.id == this.$route.params.element_id
      );
    },
  },
  mounted() {
    this.loadElement(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadElement(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
