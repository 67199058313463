<template>
  <v-main>
    <v-container class="fill-height">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12 pa-3">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Sysbase Connexion</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Adresse email"
                  name="username"
                  prepend-icon="person"
                  type="email"
                  v-model="username"
                  @keyup.enter="submit()"
                />
                <v-text-field
                  id="password"
                  label="Mot de passe"
                  name="password"
                  prepend-icon="lock"
                  type="password"
                  v-model="password"
                  @keyup.enter="submit()"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click.native="submit()"
                >Se connecter</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar
        centered
        color="red"
        v-model="loginFailedSnack"
        :timeout="6000"
      >
        Le compte n'existe pas ou l'adresse email et le mot de passe ne sont pas
        valides.
        <v-btn icon @click="loginFailedSnack = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    username: null,
    password: null,
    errors: null,
    loginFailedSnack: false,
  }),
  mounted() {},
  methods: {
    submit() {
      this.$store
        .dispatch("user/login", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$store.dispatch("user/gotoPrevious");
        })
        .catch((result) => {
          this.loginFailedSnack = true;
          let data = result.response.data;
          if (data && typeof data === "object") {
            this.errors = [];
            for (let propt in data) {
              this.errors.push(`${propt}: ${data[propt]}`);
            }
          } else {
            this.errors = ["Ne peut pas se logguer"];
          }
        });
    },
  },
};
</script>
