import axios from "axios";
import _ from "lodash";
import Vue from "vue";
/* eslint-disable no-debugger */

const state = {
  allPages: [],
  pages: [],
  flattenPages: [],
  isLoaded: false
};
const getters = {
  // pages(state, getters, rootState) {
  pages(state) {
    // return state.pages;
    // return _.sortBy(state.pages, ['position']);

    // I can't fix the ordering on server side for leafs
    function orderByPosition(pages) {
      pages.forEach((b) => {
        Vue.set(b, "editing", false);
        Vue.set(b, "showChildren", false);
        // if (!rootState.user.user.direction_access) {
        //   b.children = b.children.filter(c => c.members.filter(m => m.stakeholder_id == rootState.user.user.stakeholder.id).length > 0);
        // }
        b.children = orderByPosition(b.children);
      });
      return _.sortBy(pages, ["position"]);
    }
    return orderByPosition(state.pages)
  },
  flattenPages(state) {
    // return state.pages;
    // return _.sortBy(state.pages, ['position']);
    var pagesFlatten = []
    // I can't fix the ordering on server side for leafs
    function orderByPosition(pages) {
      pages.forEach((b) => {
        pagesFlatten.push(b);
        orderByPosition(b.children);
      });
    }
    orderByPosition(state.pages);

    function searchParent(txt, b) {
      let parent = pagesFlatten.find((fb) => fb.id == b.parent);
      let r = "";
      if (parent) {
        r = searchParent(txt, parent) + " > ";
      }
      txt = `${r}${b.title}`;
      return txt;
    }
    pagesFlatten.map((blo) => {
      blo.parentsName = searchParent("", blo);
    });
    return _.sortBy(pagesFlatten, ["parentsName"]);

    // return _.sortBy(pagesFlatten, ['title']);
  },
};
const actions = {
  load({ state }, filter) {
    state.isLoaded = true;
    state.pages = [];
    state.allPages = [];
    let url = `/api/pages.json?ordering=position${filter}`;
    return axios.get(url).then((result) => {
      state.allPages = result.data;
      state.pages = state.allPages.filter(b => !b.parent)
    });
  },
  delete({ state }, id) {
    state.isLoaded = true;
    let url = `/api/pages/${id}.json`;
    return axios.delete(url).then(() => {
      let localObj = state.pages.find(e => e.id == id);
      state.pages.splice(state.pages.indexOf(localObj), 1);
    });
  },
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
