var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right"},[(_vm.user.direction_access && _vm.edition && !_vm.parentPage)?_c('v-btn',{staticClass:"ma-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.create(null, _vm.pages, true)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")])],1):_vm._e()],1),_c('div',{class:{ children: true, first: !_vm.parentPage }},[(
        _vm.parentPage &&
        _vm.parentPage.showChildren &&
        _vm.parentPage.children.length == 0
      )?_c('div',{class:{ 'no-content': true, toolbar: '' }},[_vm._v(" Aucune page à l'intérieur ")]):_vm._e(),_c('Draggable',{staticClass:"list",attrs:{"sortable":"true","group":{ name: 'g1' },"list":_vm.pages,"handle":".handle","tag":"ul"},on:{"change":function($event){
      var i = arguments.length, argsArray = Array(i);
      while ( i-- ) argsArray[i] = arguments[i];
return _vm.onEnd.apply(void 0, [ _vm.parentPage ].concat( argsArray ))}}},_vm._l((_vm.pages),function(page){return _c('li',{key:("b-" + (page.id))},[_c('div',{class:("header " + (page.editing ? 'hover' : '')),on:{"mousemove":function($event){page.editing = true},"mouseout":function($event){page.editing = false}}},[_c('router-link',{staticClass:"page-link",attrs:{"to":{ name: 'page', params: { page_id: page.id } }}},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){page.showChildren = !page.showChildren}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(page.showChildren ? "keyboard_arrow_down" : "keyboard_arrow_right")+" ")])],1),_vm._v(" "+_vm._s(page.icon)+" "+_vm._s(page.title)+" ")],1),(_vm.edition)?_c('div',{class:{ toolbar: true, hover: page.editing }},[_c('v-btn',{staticClass:"handle",attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("drag_indicator")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.create(page, page.children, false)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")])],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.removePage(_vm.pages, page)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)],1):_vm._e()],1),(page.showChildren)?_c('div',[_c('PageItem',{attrs:{"pages":page.children,"rowsSelected":_vm.rowsSelected,"parentPage":page,"edition":_vm.edition}})],1):_vm._e()])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }