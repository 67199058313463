<template>
  <v-dialog v-model="selectDialog" width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-icon :color="color">search</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-3">
      <v-card-title>Prix</v-card-title>
      <v-chip
        v-for="price in prices"
        :key="`ppa-${articleId}-p-${price.id}`"
        @click="selectPrice(price.unit_gross_amount)"
      >
        {{ price.unit_gross_amount }} €
      </v-chip>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  inheritAttrs: true,
  components: {},
  props: {
    value: {},
    canCreate: {},
    recipientId: null,
    emitterId: null,
    articleId: null,
  },
  data: () => ({
    selectDialog: false,
  }),
  watch: {},
  computed: {
    ...mapGetters({
      articles: "articles/articles",
    }),
    prices() {
      let prices = this.articles.find((a) => a.id == this.articleId).prices;
      if (this.emitterId) {
        prices = prices.filter(
          (p) => p.emitter == this.emitterId || p.emitter == null
        );
      }
      if (this.recipientId) {
        prices = prices.filter(
          (p) => p.recipient == this.recipientId || p.recipient == null
        );
      }
      return prices;
    },
    color() {
      if (!this.value || this.prices.length == 0) {
        return;
      } else if (this.value != this.prices[0].unit_gross_amount) {
        return "orange";
      } else {
        return "green";
      }
    },
  },
  methods: {
    ...mapActions({}),
    openDialog() {
      if (this.canEdit) {
        this.selectDialog = true;
      }
    },
    removePrice() {
      this.selectPrice(null);
    },
    selectPrice(priceAmount) {
      Vue.set(this, "value", priceAmount);
      this.$nextTick(() => {
        this.$emit("input", priceAmount);
        this.$emit("change", priceAmount);
        this.selectDialog = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
