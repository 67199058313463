<template>
  <v-container fluid v-if="elements">
    <h1>Parc</h1>
    <v-row>
      <v-col class="left">
        <v-btn @click="createElement()">Créer</v-btn>
        <ArticlePicker
          v-model="articleId"
          hide-details
          dense
          clearable
          :canCreate="true"
        />
        <v-text-field label="Rechercher" v-model="filterStr"></v-text-field>
        <table class="fo-table">
          <thead>
            <tr>
              <!-- <th>Etat</th> -->
              <th>Source</th>
              <th>Quantité</th>
              <th>Article</th>
              <th>Référence</th>
              <th>Destination</th>
            </tr>
          </thead>
          <tbody>
            <ElementItem
              v-for="element in elementsToShow"
              :key="`element-${element.id}`"
              :element="element"
            >
            </ElementItem>
          </tbody>
        </table>
        <v-alert v-if="elementsToShow.length == 0">
          Aucun élément pour le moment
        </v-alert>
      </v-col>
      <v-col :cols="8"><router-view></router-view></v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ElementItem from "./ElementItem.vue";
import ArticlePicker from "../articles/ArticlePicker.vue";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {
    ElementItem,
    ArticlePicker,
  },
  data: () => ({
    elementsToShow: [],
    filterStr: "",
    articleId: null,
  }),
  methods: {
    ...mapActions({
      createElementStore: "stocks/create",
    }),
    createElement() {
      let payload = {
        article_id: this.articleId,
        is_available: false,
        quantity: 1,
      };
      this.createElementStore(payload).then((result) => {
        this.$router.push({
          name: "element",
          params: { element_id: result.data.id },
        });
      });
    },
    updateElementsList() {
      if (this.filterStr) {
        this.elementsToShow = this.searchElements
          .search(this.filterStr)
          .map((i) => i.item);
      } else {
        this.elementsToShow = this.elements;
      }
      this.elementsToShow = this.elementsToShow.filter(
        (e) => e.article_id == this.articleId
      );
    },
  },
  watch: {
    filterStr() {
      this.updateElementsList();
    },
    articleId() {
      this.updateElementsList();
    },
    elements() {
      this.updateElementsList();
    },
  },
  computed: {
    ...mapGetters({
      elements: "stocks/elements",
      searchElements: "stocks/searchElements",
    }),
  },
  mounted() {
    this.elementsToShow = this.elements;
  },
};
</script>
<style lang="scss" scoped>
.left {
  background: #f6f6f6;
  .element-row {
    padding: 4px;
    &:hover {
      background: #fff;
    }
    .link {
      display: block;
    }
    .states {
      padding: 4px;
      .exchange-stage {
        display: block;
        font-size: 0.6em;
        &.router-link-active {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
