<template>
  <v-container v-if="groups && mapNatures">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title>Natures de groupe</v-card-title>
          <v-select
            :items="natures"
            v-model="nature_id__in"
            hide-details
            item-text="name"
            item-value="id"
            label="Nature"
            clearable
            @input="filter"
          ></v-select>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-3">
          <v-card-title>Groupes</v-card-title>

          <v-dialog v-model="dialogOpsGroup" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on"> Nouveau groupe </v-btn>
            </template>
            <v-card class="pa-3">
              <v-card-title>Nouveau groupe</v-card-title>
              <v-select
                :items="natures"
                v-model="groupNature"
                hide-details
                item-text="name"
                item-value="id"
                label="Nature"
                clearable
              ></v-select>
              <v-text-field label="Nom" v-model="groupName"></v-text-field>
              <v-btn @click="addOpsGroup()"> Ajouter </v-btn>
            </v-card>
          </v-dialog>

          <v-list subheader>
            <v-list-item
              v-for="group in groups"
              :key="`group-${group.id}`"
              link
              :to="{
                name: 'group',
                params: { group_id: group.id },
              }"
            >
              <span class="grey--text" v-if="mapNatures[group.nature_id]"
                >{{ mapNatures[group.nature_id].name }}&nbsp;</span
              >
              <span>{{ group.name }}</span>
            </v-list-item>
          </v-list>
          <v-alert v-if="groups.length == 0">
            Aucun groupe pour le moment
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    groups: null,
    nature_id__in: null,
    // creation
    dialogOpsGroup: false,
    groupNature: null,
    groupName: "Groupe",
  }),
  computed: {
    ...mapGetters({
      natures: "groups/natures",
      mapNatures: "groups/mapNatures",
    }),
  },
  methods: {
    filter() {
      this.axios
        .get(
          `/api/ops_groups.json?offset=0&limit=20${
            this.nature_id__in ? `&nature_id__in=${this.nature_id__in}` : ""
          }`
        )
        .then((result) => {
          this.groups = result.data.results;
        });
    },
    addOpsGroup() {
      this.axios.post(`/api/ops_groups.json`, {
        nature: this.groupNature,
        name: this.groupName,
      });
    },
  },
  mounted() {
    this.filter();
  },
};
</script>
<style lang="scss" scoped>
</style>
