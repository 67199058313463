<template>
  <v-autocomplete
    v-bind="$attrs"
    v-bind:value="value"
    v-on="inputListeners"
    :items="items"
    :loading="isLoading"
    item-text="name"
    item-value="id"
    clearable
    autocomplete="off"
    cache-items
    :search-input.sync="searchInput"
  >
    <!-- :append-icon="value ? 'open_in_new' : null"
    @click:append="
      $router.push({ name: 'group', params: { group_id: value } })
    " -->
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Commencez à entrer le nom d'un
          <strong>groupe</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:append-item>
      <div class="pa-2" v-if="canCreate">
        <v-btn
          small
          color="primary"
          @click="
            createGroup({
              name: searchInput,
              tags: [],
            })
          "
          >Créer le groupe</v-btn
        >
      </div>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <span class="grey--text" v-if="mapNatures[item.id]"
            >{{ mapNatures[item.id].name }}&nbsp;</span
          >
          {{ item.name }}</v-list-item-title
        >
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import { mapGetters, mapActions } from "vuex";

export default {
  inheritAttrs: true,
  props: {
    value: {},
    canCreate: {},
  },
  data: () => ({
    searchTime: null,
    isLoading: false,
    items: [],
    selectedItems: [],
    search: "",
    canCreate: false,
    idsSelected: [],
    searchInput: "",
  }),
  watch: {
    searchInput() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.axios
          .get(
            `/api/ops_groups.json?offset=0&limit=2&${
              this.searchInput ? `name__icontains=${this.searchInput}` : ""
            }`
          )
          .then((result) => {
            this.items = result.data.results;
          });
      }, 250);
    },
  },
  computed: {
    ...mapGetters({
      mapNatures: "groups/mapNatures",
    }),

    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
  methods: {
    ...mapActions({
      createGroup: "groups/create",
    }),
  },
};
</script>
