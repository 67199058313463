<template>
  <v-container fluid v-if="documents">
    <h1>Indicateurs</h1>
    <v-row>
      <v-col>
        <v-card class="pa-5">
          <v-card-title>Ventes</v-card-title>
          <v-row>
            <v-col>
              <div class="text-h2">{{ turnover }}</div>
              <hr />
              <div class="text-overline">En retard</div>
              <div
                class="text-h3"
                :class="lateTurnover == 0 ? 'green--text' : 'orange--text'"
              >
                {{ lateTurnover }}
              </div>
            </v-col>
            <v-col>
              <div v-for="month in turnoverByMonth" :key="`tobm-${month.key}`">
                <div class="caption">
                  {{ month.key }}
                </div>
                <div class="text-h4">
                  {{ month.amount_invoice }}
                </div>
                <div class="text-h5">
                  {{ month.amount_delivery }}
                </div>
                <div class="text-h6">
                  {{ month.amount_order }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-5">
          <v-card-title>Achats</v-card-title>
          <v-row>
            <v-col>
              <div class="text-h2">{{ buyings }}</div>
              <hr />
              <!-- <div class="text-overline">En retard</div>
              <div
                class="text-h3"
                :class="lateTurnover == 0 ? 'green--text' : 'orange--text'"
              >
                {{ lateTurnover }}
              </div> -->
            </v-col>
            <v-col>
              <div v-for="month in buyingsByMonth" :key="`tobm-${month.key}`">
                <div class="caption">
                  {{ month.key }}
                </div>
                <div class="text-h4">
                  {{ month.amount_invoice }}
                </div>
                <div class="text-h5">
                  {{ month.amount_delivery }}
                </div>
                <div class="text-h6">
                  {{ month.amount_order }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-5">
          <v-card-title>Marge</v-card-title>
          <v-row>
            <v-col>
              <div class="text-h2">{{ margin }}</div>
              <hr />
              <!-- <div class="text-overline">En retard</div>
              <div
                class="text-h3"
                :class="lateTurnover == 0 ? 'green--text' : 'orange--text'"
              >
                {{ lateTurnover }}
              </div> -->
            </v-col>
            <v-col>
              <div v-for="month in marginsByMonth" :key="`tobm-${month.key}`">
                <div class="caption">
                  {{ month.key }}
                </div>
                <div
                  class="text-h4"
                  :class="{
                    'red--text': month.amount < 0,
                    'green--text': month.amount >= 0,
                  }"
                >
                  {{ month.amount }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import _ from "lodash";
import moment from "moment";
export default {
  components: {},
  data: () => ({
    documents: null,
  }),
  computed: {
    ...mapGetters({
      organizations: "organizations/organizations",
      articles: "articles/articles",
    }),
    allDocsPrepared() {
      let exs = this.documents.map((e) => {
        e.emitter = this.organizations.find((o) => o.id == e.emitter_id);
        e.recipient = this.organizations.find((o) => o.id == e.recipient_id);
        let date = moment(e.acted_date);
        e.year = date.format("YYYY");
        e.month = date.format("MM/YYYY");
        return e;
      });
      return exs;
    },
    ordersPrepared() {
      let exs = this.documents
        .filter((i) => i.official_nature == "order")
        .map((e) => {
          e.emitter = this.organizations.find((o) => o.id == e.emitter_id);
          e.recipient = this.organizations.find((o) => o.id == e.recipient_id);
          let date = moment(e.acted_date);
          e.year = date.format("YYYY");
          e.month = date.format("MM/YYYY");
          return e;
        });
      return exs;
    },
    deliveriesPrepared() {
      let exs = this.documents
        .filter((i) => i.official_nature == "delivery")
        .map((e) => {
          e.emitter = this.organizations.find((o) => o.id == e.emitter_id);
          e.recipient = this.organizations.find((o) => o.id == e.recipient_id);
          let date = moment(e.acted_date);
          e.year = date.format("YYYY");
          e.month = date.format("MM/YYYY");
          return e;
        });
      return exs;
    },
    invoicesPrepared() {
      let exs = this.documents
        .filter((i) => i.official_nature == "invoice")
        .map((e) => {
          e.emitter = this.organizations.find((o) => o.id == e.emitter_id);
          e.recipient = this.organizations.find((o) => o.id == e.recipient_id);
          let date = moment(e.acted_date);
          e.year = date.format("YYYY");
          e.month = date.format("MM/YYYY");
          return e;
        });
      return exs;
    },
    turnover() {
      return Math.round(
        this.invoicesPrepared
          .filter(
            (e) =>
              e.recipient &&
              e.emitter &&
              !e.emitter.is_third_party &&
              !(!e.emitter.is_third_party && !e.recipient.is_third_party) &&
              !(e.emitter.is_third_party && e.recipient.is_third_party)
          )
          .reduce((p, c) => p + c.gross_amount, 0)
      );
    },
    lateTurnover() {
      return Math.round(
        this.invoicesPrepared
          .filter(
            (e) =>
              e.recipient &&
              e.emitter &&
              !e.emitter.is_third_party &&
              moment(e.deadline_date).isBefore(moment()) &&
              !e.is_paid &&
              !(!e.emitter.is_third_party && !e.recipient.is_third_party) &&
              !(e.emitter.is_third_party && e.recipient.is_third_party)
          )
          .reduce((p, c) => p + c.gross_amount, 0)
      );
    },
    turnoverByMonth() {
      let monthsMap = _.groupBy(
        this.allDocsPrepared.filter(
          (e) => e.recipient && e.emitter && e.direction == "customer"
        ),
        (e) => e.month
      );
      let months = [];
      _.forOwn(monthsMap, (v, k) => {
        months.push({
          key: k,
          documents: v,
          amount_order: 0,
          amount_delivery: 0,
          amount_invoice: 0,
        });
      });
      months.forEach((month) => {
        month.amount_order = Math.round(
          month.documents
            .filter((d) => d.official_nature == "order")
            .reduce((p, order) => p + order.gross_amount, 0)
        );
        month.amount_delivery = Math.round(
          month.documents
            .filter((d) => d.official_nature == "delivery")
            .reduce((p, delivery) => p + delivery.gross_amount, 0)
        );
        month.amount_invoice = Math.round(
          month.documents
            .filter((d) => d.official_nature == "invoice")
            .reduce((p, invoice) => p + invoice.gross_amount, 0)
        );
      });
      return months;
    },
    buyings() {
      return Math.round(
        this.invoicesPrepared
          .filter(
            (e) =>
              e.recipient &&
              e.emitter &&
              !e.recipient.is_third_party &&
              !(!e.emitter.is_third_party && !e.recipient.is_third_party) &&
              !(e.emitter.is_third_party && e.recipient.is_third_party)
          )
          .reduce((p, c) => p + c.gross_amount, 0)
      );
    },
    buyingsByMonth() {
      let monthsMap = _.groupBy(
        this.allDocsPrepared.filter(
          (e) =>
            e.recipient &&
            e.emitter &&
            !e.recipient.is_third_party &&
            !(!e.emitter.is_third_party && !e.recipient.is_third_party) &&
            !(e.emitter.is_third_party && e.recipient.is_third_party)
        ),
        (e) => e.month
      );
      let months = [];
      _.forOwn(monthsMap, (v, k) => {
        months.push({
          key: k,
          documents: v,
          amount_order: 0,
          amount_delivery: 0,
          amount_invoice: 0,
        });
      });
      months.forEach((month) => {
        month.amount_order = Math.round(
          month.documents
            .filter((d) => d.official_nature == "order")
            .reduce((p, order) => p + order.gross_amount, 0)
        );
        month.amount_delivery = Math.round(
          month.documents
            .filter((d) => d.official_nature == "delivery")
            .reduce((p, delivery) => p + delivery.gross_amount, 0)
        );
        month.amount_invoice = Math.round(
          month.documents
            .filter((d) => d.official_nature == "invoice")
            .reduce((p, invoice) => p + invoice.gross_amount, 0)
        );
      });
      return months;
    },
    margin() {
      return Math.round(
        this.invoicesPrepared
          .filter(
            (e) =>
              e.emitter &&
              e.recipient &&
              !(!e.emitter.is_third_party && !e.recipient.is_third_party) &&
              !(e.emitter.is_third_party && e.recipient.is_third_party)
          )
          .reduce(
            (p, c) =>
              p + (c.emitter.is_third_party ? -c.gross_amount : c.gross_amount),
            0
          )
      );
    },
    marginsByMonth() {
      let monthsMap = _.groupBy(
        this.invoicesPrepared.filter(
          (e) =>
            e.emitter &&
            e.recipient &&
            !(!e.emitter.is_third_party && !e.recipient.is_third_party) &&
            !(e.emitter.is_third_party && e.recipient.is_third_party)
        ),
        (e) => e.month
      );
      let months = [];
      _.forOwn(monthsMap, (v, k) => {
        months.push({ key: k, invoices: v, amount: 0 });
      });
      months.forEach((month) => {
        month.amount = Math.round(
          month.invoices.reduce(
            (p, invoice) =>
              p +
              (invoice.emitter.is_third_party
                ? -invoice.gross_amount
                : invoice.gross_amount),
            0
          )
        );
      });
      return months;
    },
    // turnoverByEmitter() {
    //   return _.groupBy(this.invoices, e => e.emitter).reduce((p, c) => { }, 0);
    // }
  },
  methods: {
    loadExchanges() {
      this.axios
        .get(
          `/api/official_documents.json?official_nature__in=order,delivery,invoice&ordering=-acted_date`
        )
        .then((result) => {
          this.documents = result.data;
        });
    },
  },
  mounted() {
    this.loadExchanges(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadExchanges(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
