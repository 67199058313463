<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <a @click="getRows()"> Télécharger les écritures comptables </a><br />
        <div v-for="month in months" :key="`md-${month.text}`">
          <a @click="getExtraction(month.value)">
            Télécharger les documents {{ month.text }}
          </a>
        </div>
      </v-col>
    </v-row>
    <iframe id="my_iframe" style="display: none"></iframe>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    official_documents: [],
    months: [],
  }),
  watch: {},
  computed: {},
  methods: {
    download(urlextract, filename) {
      this.axios
        .get(urlextract, { responseType: "arraybuffer" })
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          // alert('your file has downloaded!'); // or you know, something with better UX...
        })
        .catch(() => alert("oh no!"));
    },
    getExtraction(query) {
      this.download(
        `/api/official_documents/docs_extraction/?official_nature=invoice&is_closed=True&${query}`,
        "documents.zip"
      );
    },
    getRows() {
      this.download(
        `/api/exchanges/export/?invoice_acted_date__isnull=False`,
        "export.csv"
      );
    },
  },
  mounted() {
    for (let y = 2022; y <= 2023; y++) {
      for (let i = 1; i <= 12; i++) {
        let month = String(i).padStart(2, "0");
        let nextMonth = String(i + 1).padStart(2, "0");
        let nY = y;
        if (month == 12) {
          nY = y + 1;
          nextMonth = "01";
        }
        this.months.push({
          value: `&acted_date__gte=${y}-${month}-01&acted_date__lt=${nY}-${nextMonth}-01`,
          text: `${month}/${y}`,
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
