<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row class="pa-0 ma-0">
          <v-col cols="6">
            <v-toolbar dense flat class="ma-0 pa-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="mx-1">
                    <v-checkbox
                      v-model="toggleAllValue"
                      dense
                      small
                      :value="toggleAllValue"
                      :indeterminate="toggleAllIndeterminate"
                      hide-details
                      @click.stop="toggleAll"
                    ></v-checkbox>
                    <v-icon class="pt-1" small>expand_more</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    v-for="recipient in recipients"
                    :key="`rec-${recipient.id}`"
                    @click="selectAll(recipient)"
                  >
                    {{ recipient.name }}
                  </v-list-item>
                </v-list>
              </v-menu>
              <OrganizationPicker
                label="Fournisseur"
                v-model="emitter"
                class="pa-2"
                solo
                hide-details
                dense
                :can-create="true"
              ></OrganizationPicker>
              <OrganizationPicker
                label="Destinataire"
                v-model="recipient"
                class="pa-2"
                solo
                hide-details
                dense
                :can-create="true"
              ></OrganizationPicker>
              <v-btn
                :color="rowsSelected.ids.length > 0 ? 'primary' : ''"
                v-bind="attrs"
                class="mr-3"
                small
                @click="createDocumentFromSelection()"
              >
                Commande pour {{ rowsSelected.ids.length }} éléments
              </v-btn>
            </v-toolbar>

            <table class="fo-table">
              <ExchangeLight
                v-for="exchange in exchanges"
                :key="`exchange-${exchange.id}`"
                :exchange="exchange"
                :rowsSelected="rowsSelected"
              >
              </ExchangeLight>
            </table>
            <v-alert v-if="exchanges.length == 0">
              Aucun échange pour le moment
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";
import moment from "moment";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
// import TagsPicker from "../tags/TagsPicker.vue";
import ExchangeLight from "../exchanges/ExchangeLight.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  components: {
    OrganizationPicker,
    ExchangeLight,
  },
  data: () => ({
    rowsSelected: {
      ids: [],
    },
    urlQuery: {},
    official_documents: [],
    newDocNature: "proposal",
    docStates: [
      {
        text: "Brouillon",
        value: false,
      },
      {
        text: "Clotûrée",
        value: true,
      },
    ],
    doctypes: [
      {
        text: "Proposition",
        value: "proposal",
      },
      {
        text: "Commande",
        value: "order",
      },
      {
        text: "Livraison",
        value: "delivery",
      },
      {
        text: "Facture",
        value: "invoice",
      },
    ],
    exchanges: [],
    exchangesFound: [],
    exchangesSelected: {
      ids: [],
    },
    dialogDocuments: false,
    dialogInvoice: false,
    contract: null,
    documentsSelected: [],
    official_document: null,
    emitter: null,
    recipient: null,
  }),
  watch: {
    urlQuery: {
      deep: true,
      handler() {
        if (
          !this.$route.query.q ||
          JSON.stringify(this.urlQuery) != this.$route.query.q
        ) {
          this.$router.push({
            name: "docs-lists",
            query: { q: JSON.stringify(this.urlQuery) },
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      organizations: "organizations/organizations",
    }),
    toggleAllValue() {
      return (
        this.rowsSelected.ids.length == this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    toggleAllIndeterminate() {
      return (
        this.rowsSelected.ids.length != this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    recipients() {
      let recipients = [];
      this.exchanges.forEach((e) => {
        let rIdFound = recipients.find((r) => r.id == e.recipient);
        if (!rIdFound) {
          let org = this.organizations.find((o) => o.id == e.recipient);
          recipients.push(org);
        }
      });
      return recipients;
    },
  },
  methods: {
    selectAll(recipient) {
      this.rowsSelected.ids = this.exchanges
        .filter((e) => e.recipient == recipient.id)
        .map((e) => e.id);
    },
    addToQuery(filter, value) {
      if (!this.urlQuery[filter]) {
        Vue.set(this.urlQuery, filter, []);
      }
      this.urlQuery[filter].push(value);
    },
    toggleAll() {
      if (this.rowsSelected.ids.length == 0) {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      } else if (this.rowsSelected.ids.length == this.exchanges.length) {
        this.rowsSelected.ids = [];
      } else {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      }
    },
    createDocumentFromSelection() {
      // this.rowsSelected.ids.forEach((rowId) => {
      //   let exchange = this.exchanges.find((r) => r.id == rowId);
      // });
      // let apiCalls = [];
      let payload = {
        official_nature: "order",
        emitter_id: this.emitter,
        recipient_id: this.recipient,
        acted_date: moment().format("YYYY-MM-DD"),
      };
      var promCreations = [];
      var i = 1;
      let promDoc = this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.rowsSelected.ids.forEach((rowId) => {
            let exchange = this.exchanges.find((r) => r.id == rowId);
            let payloadRow = {
              document: result.data.id,
              position: i++,
            };
            let payloadExchange = Object.assign({}, exchange);
            delete payloadExchange.id;
            payloadExchange["proposal"] = null;
            payloadExchange["order"] = null;
            payloadExchange["delivery"] = null;
            payloadExchange["invoice"] = null;
            payloadExchange["order"] = result.data.id;
            payloadExchange["is_closed_order"] = false;
            payloadExchange["order_acted_date"] = null;
            payloadExchange.emitter_id = this.emitter;
            payloadExchange.recipient_id = this.recipient;
            payloadExchange.direction = "provider";
            payloadExchange.unit_gross_amount = 0;
            payloadExchange.quantity = exchange.remaining_q;
            let article = this.articles.find((a) => a.id == exchange.article);
            let prices = _.sortBy(
              article.prices.filter(
                (p) => p.direction == payloadExchange.direction
              ),
              ["quantity_min"]
            );
            prices.forEach((price) => {
              if (exchange.remaining_q >= price.quantity_min) {
                payloadExchange.unit_gross_amount = payload[
                  "unit_gross_amount"
                ] = price.unit_gross_amount;
                return;
              }
            });
            payloadExchange.gross_amount =
              payloadExchange.unit_gross_amount * payloadExchange.quantity;
            payloadExchange.tax_ratio = 20;
            payloadExchange.net_amount = payloadExchange.gross_amount * 1.2;

            // apiCalls.push({
            //   url: `/api/exchanges/${row.exchange.id}.json`,
            //   method: "patch",
            //   payload: payloadExchange,
            // });
            let promEx = this.axios
              .post(`/api/exchanges.json`, payloadExchange)
              .then((resultExchange) => {
                let destExchange = resultExchange.data;
                payloadRow["exchange_id"] = destExchange.id;
                let promiseRow = this.axios.post(
                  `/api/doc_rows.json`,
                  payloadRow
                );
                promCreations.push(promiseRow);
                for (let iE = 0; iE < exchange.remaining_q; iE++) {
                  let payloadElement = {
                    article_id: destExchange.article,
                    source_id: destExchange.id,
                    destination_id: exchange.id,
                    description: exchange.description,
                    quantity: 1,
                    buying_amount: destExchange.unit_gross_amount,
                    selling_amount: exchange.unit_gross_amount,
                    orga_dest: exchange.recipient,
                  };
                  let promiseElement = this.axios.post(
                    `/api/elements.json`,
                    payloadElement
                  );
                  promCreations.push(promiseElement);
                }
              });
            promCreations.push(promEx);
          });
          return result;
        });

      promCreations.push(promDoc);
      setTimeout(() => {
        Promise.all(promCreations).then(() => {
          promDoc.then((resultDoc) => {
            this.$router.push({
              name: "document",
              params: { document_id: resultDoc.data.id },
            });
          });
        });
      }, 200);

      // async function callApi(cpt, apiCalls) {
      //   for (const apiCall of apiCalls) {
      //     await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
      //   }
      //   cpt.loadDocument(cpt.$route.params.document_id);
      //   cpt.overlay = false;
      // }
      // callApi(this, apiCalls);
    },
    openDoc(document) {
      this.$router.push({
        name: "document",
        params: { document_id: document.id },
      });
    },
    createDocumentEmpty() {
      let payload = {
        emitter_id: this.emitter,
        recipient_id: this.recipient,
        official_nature: this.newDocNature,
        acted_date: moment().format("YYYY-MM-DD"),
      };
      this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.official_documents.push(result.data);
          let exchangesRequests = [];
          this.exchangesSelected.ids.forEach((exchangeId) => {
            let payloadExchange = {};
            payloadExchange[this.newDocNature] = result.data.id;
            exchangesRequests.push(
              this.axios.patch(
                `/api/exchanges/${exchangeId}.json`,
                payloadExchange
              )
            );
          });
          Promise.all(exchangesRequests).then(() => {
            this.$router.push({
              name: "document",
              params: { document_id: result.data.id },
            });
          });
        });
    },
    loadExchanges() {
      let urlEx = `/api/exchanges.json?`;
      urlEx += `&article__is_stockable=True&remaining_q__gt=0&direction=customer&order_acted_date__isnull=False&delivery__isnull=True&invoice__isnull=True&ordering=order_acted_date`;
      this.axios.get(urlEx).then((result) => {
        this.exchanges = result.data;
      });
    },
  },
  mounted() {
    this.loadExchanges(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadExchanges(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
