<template>
  <div
    v-if="entity"
    @drop="dragAttachmentFinish($event)"
    draggable="true"
    @dragover.prevent
  >
    <v-file-input
      label="Attacher un document"
      dense
      hide-details
      @change="upload($event)"
      :loading="loading"
      class="mt-3"
    >
      <template v-slot:selection="{}">
        <v-chip small label color="primary"> Envoi du document... </v-chip>
      </template>
    </v-file-input>
    <v-row no-gutters>
      <!-- <v-col
        cols="3"
        v-for="(attachment, index) in entity.attachments"
        :key="`attachment-${index}`"
        @click="showAttachment({ attachment: attachment })"
        
      > -->
      <!-- <v-col
        cols="3"
        v-for="(attachment, index) in attachmentsFiltered"
        :key="`attachment-${index}`"
        @click="
          openElement({
            router: $router,
            route: $route,
            element: { nature: 'attachment', id: attachment.uuid },
          })
        "
      >
        <v-card class="pa-2">
          <div>
            <v-img
              :src="attachment.thumbnail_url"
              contain
              max-height="200"
              max-width="200"
            />
          </div>
          <div class="caption">
            {{ attachment.file_name }}
          </div>
          <v-btn
            fab
            small
            icon
            @click="deleteFile(entity.attachments, attachment)"
          >
            <v-icon small>delete</v-icon>
          </v-btn>
          <v-btn fab small icon :href="attachment.url" target="_blank">
            <v-icon small>open_in_new</v-icon>
          </v-btn>
        </v-card>
      </v-col> -->
    </v-row>
    <v-img
      v-if="selectedItem && selectedItem.file_type.includes('image')"
      :src="selectedItem.url"
    />
    <object
      v-else-if="selectedItem"
      width="100%"
      height="700px"
      :data="selectedItem.url"
    ></object>
  </div>
</template>



<script>
// import { mapActions, mapGetters } from "vuex";
// import moment from "moment";
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
// import jsPDF from "jspdf";
/* eslint no-console: "off" */

/* eslint-disable no-debugger */
export default {
  props: {
    entityEndpoint: null,
    entity: null,
    name: { type: String, required: true },
    uuidsFilter: { type: Array, default: null },
  },
  components: {},
  data: () => ({
    selectedItem: null,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      draggingAttachment: "streamapp/draggingAttachment",
    }),
    attachmentsFiltered() {
      if (this.uuidsFilter != null) {
        return this.entity.attachments.filter((attachment) =>
          this.uuidsFilter.includes(attachment.uuid)
        );
      } else {
        return this.entity.attachments;
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      showAttachment: "fileviewer/showAttachment",
      setDraggingAttachment: "streamapp/setDraggingAttachment",
      openElement: "documents/openElement",
    }),
    dragAttachmentFinish() {
      let attachment = this.draggingAttachment;
      this.setDraggingAttachment({ attachment: null });
      fetch(attachment.url)
        .then((res) => res.blob())
        .then((blob) => {
          this.upload(
            new File([blob], attachment.file_name, { type: blob.type })
          );
        }); // Gets the response and returns it as a blob
    },
    deleteFile(array, attachment) {
      if (window.confirm("Êtes-vous sûr(e) ?")) {
        this.axios
          .delete(`/api/attachments/${attachment.uuid}.json`)
          .then(() => {
            this.$emit("file-deleted", attachment.uuid);
            array.splice(array.indexOf(attachment), 1);
          });
      }
    },
    rotateFile(attachment) {
      this.axios
        .patch(`/api/attachments/${attachment.uuid}/rotate.json`)
        .then((result) => {
          console.log(result);
        });
    },
    upload(file) {
      this.loading = true;
      let fileToSend = Object.assign({}, file);
      fileToSend.file_name = file.name;
      fileToSend.file_type = file.type;
      fileToSend.size = file.size;
      fileToSend.object_endpoint = this.entityEndpoint;
      fileToSend.object_id = this.entity.id;
      this.axios
        .post(`/api/attachments/sign_s3.json`, fileToSend)
        .then((resultApi) => {
          let s3Data = resultApi.data.data;
          let postData = new FormData();
          for (var key in s3Data.fields) {
            postData.append(key, s3Data.fields[key]);
          }
          postData.append("file", file);

          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          // fucking hacky way to prevent request to fail with amazon
          // https://github.com/axios/axios/issues/382
          delete this.axios.defaults.headers.common["Authorization"];
          this.axios.post(s3Data.url, postData, config).then(() => {
            this.axios.defaults.headers.common["Authorization"] =
              Cookies.get("auth_token");
            this.axios
              .patch(
                `/api/attachments/${resultApi.data.attachment.uuid}/thumbnail.json`
              )
              .then((result) => {
                this.entity.attachments.push(result.data);
                this.$emit("file-uploaded", result.data.uuid);
                this.loading = false;
              });
          });
        })
        .catch((error) => {
          alert("Could not upload file." + error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
