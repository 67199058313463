<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <div>
          <v-select
            :items="doctypes"
            v-model="urlQuery.official_nature__in"
            multiple
            hide-details
            label="Type de documents"
            clearable
          ></v-select>
          <OrganizationPicker
            label="Emetteur"
            v-model="urlQuery.emitter_id__in"
            hide-details
            :can-create="true"
            clearable
            multiple
          ></OrganizationPicker>
          <OrganizationPicker
            label="Destinataire"
            v-model="urlQuery.recipient_id__in"
            hide-details
            :can-create="true"
            clearable
            multiple
          ></OrganizationPicker>
          <FoDatePicker
            label="Date de début"
            v-model="urlQuery.acted_date__gte"
          ></FoDatePicker>
          <FoDatePicker
            label="Date de fin"
            v-model="urlQuery.acted_date__lte"
          ></FoDatePicker>
          <TagsPicker
            v-model="urlQuery.tags__search"
            hide-details
            clearable
            multiple
            label="Tags"
          ></TagsPicker>
        </div>
      </v-col>
      <v-col>
        <v-toolbar dense flat>
          <v-dialog v-model="dialogInvoice" width="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on"> Créer un document </v-btn>
            </template>

            <v-card class="pa-3">
              <v-row>
                <v-col>
                  <v-select
                    :items="doctypes"
                    v-model="newDocNature"
                    @change="searchExchanges"
                  ></v-select>
                </v-col>
                <v-col>
                  <OrganizationPicker
                    label="Emetteur"
                    v-model="emitter"
                    class="pa-2"
                    solo
                    hide-details
                    @change="searchExchanges"
                    :can-create="true"
                  ></OrganizationPicker>
                </v-col>
                <v-col>
                  <OrganizationPicker
                    label="Bénéficiaire"
                    v-model="recipient"
                    class="pa-2"
                    solo
                    hide-details
                    @change="searchExchanges"
                    :can-create="true"
                  ></OrganizationPicker>
                </v-col>
              </v-row>
              <v-toolbar>
                <v-checkbox
                  v-model="toggleAllValue"
                  dense
                  small
                  :value="toggleAllValue"
                  :indeterminate="toggleAllIndeterminate"
                  @change="toggleAll"
                ></v-checkbox>
                <v-toolbar-title>Échanges appropriés</v-toolbar-title>
              </v-toolbar>
              <ExchangeItem
                v-for="exchange in exchangesFound"
                :key="`exchange-found-${exchange.id}`"
                :exchange="exchange"
                :isEditable="false"
                :exchangesSelected="exchangesSelected"
              >
              </ExchangeItem>
              <span v-if="exchangesFound.length == 0"> Aucun élément </span>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="createDocument()">
                  Créer
                  <span v-if="exchangesSelected.ids.length > 0">
                    avec {{ exchangesSelected.ids.length }} éléments
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
        </v-toolbar>
        <!-- <v-list-item
            link
            :to="{
              name: 'exchanges',
              params: {
                document_nature: 'all',
                document_id: 'all',
              },
            }"
            >Tous les échanges</v-list-item
          > -->
        <v-row class="pa-0 ma-0">
          <v-col cols="6">
            <h3 class="pb-3">Achats</h3>
            <DocumentItem
              v-for="official_document in providerDocuments"
              :key="`official_document-${official_document.id}`"
              :document="official_document"
              @click.native="openDoc(official_document)"
              @query="addToQuery"
            >
            </DocumentItem>
          </v-col>
          <v-col cols="6">
            <h3 class="pb-3">Ventes</h3>
            <DocumentItem
              v-for="official_document in customerDocuments"
              :key="`official_document-${official_document.id}`"
              :document="official_document"
              @click.native="openDoc(official_document)"
              @query="addToQuery"
            >
            </DocumentItem>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";
import moment from "moment";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
import DocumentItem from "./DocumentItem.vue";
import ExchangeItem from "../exchanges/ExchangeItem.vue";
import FoDatePicker from "../FoDatePicker.vue";
import TagsPicker from "../tags/TagsPicker.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    OrganizationPicker,
    DocumentItem,
    ExchangeItem,
    FoDatePicker,
    TagsPicker,
  },
  data: () => ({
    urlQuery: {},
    official_documents: [],
    newDocNature: "proposal",
    doctypes: [
      {
        text: "Proposition",
        value: "proposal",
      },
      {
        text: "Commande",
        value: "order",
      },
      {
        text: "Livraison",
        value: "delivery",
      },
      {
        text: "Facture",
        value: "invoice",
      },
    ],
    exchangesFound: [],
    exchangesSelected: {
      ids: [],
    },
    dialogDocuments: false,
    dialogInvoice: false,
    contract: null,
    documentsSelected: [],
    official_document: null,
    emitter: null,
    recipient: null,
  }),
  watch: {
    urlQuery: {
      deep: true,
      handler() {
        if (
          !this.$route.query.q ||
          JSON.stringify(this.urlQuery) != this.$route.query.q
        ) {
          this.$router.push({
            name: "documents",
            query: { q: JSON.stringify(this.urlQuery) },
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      organizations: "organizations/organizations",
    }),
    toggleAllValue() {
      return (
        this.exchangesSelected.ids.length == this.exchangesFound.length &&
        this.exchangesSelected.ids.length > 0
      );
    },
    toggleAllIndeterminate() {
      return (
        this.exchangesSelected.ids.length != this.exchangesFound.length &&
        this.exchangesSelected.ids.length > 0
      );
    },
    providerDocuments() {
      return this.official_documents.filter((d) => {
        return this.organizations.find((o) => o.id == d.emitter_id)
          .is_third_party;
      });
    },
    customerDocuments() {
      return this.official_documents.filter((d) => {
        return !this.organizations.find((o) => o.id == d.emitter_id)
          .is_third_party;
      });
    },
  },
  methods: {
    addToQuery(filter, value) {
      if (!this.urlQuery[filter]) {
        Vue.set(this.urlQuery, filter, []);
      }
      this.urlQuery[filter].push(value);
    },
    toggleAll() {
      if (this.exchangesSelected.ids.length == 0) {
        this.exchangesSelected.ids = this.exchangesFound.map((e) => e.id);
      } else if (
        this.exchangesSelected.ids.length == this.exchangesFound.length
      ) {
        this.exchangesSelected.ids = [];
      } else {
        this.exchangesSelected.ids = this.exchangesFound.map((e) => e.id);
      }
    },
    searchExchanges() {
      let exchangesSearchRequest = `/api/exchanges.json?`;
      if (this.emitter) {
        exchangesSearchRequest += `&emitter_id=${this.emitter}`;
      }
      if (this.recipient) {
        exchangesSearchRequest += `&recipient_id=${this.recipient}`;
      }
      let previousStep;
      if (this.newDocNature == "invoice") {
        previousStep = "delivery";
      } else if (this.newDocNature == "delivery") {
        previousStep = "order";
      } else if (this.newDocNature == "order") {
        previousStep = "proposal";
      }
      exchangesSearchRequest += `&${this.newDocNature}__isnull=True`;
      exchangesSearchRequest += `&${previousStep}__isnull=False`;
      this.axios.get(exchangesSearchRequest).then((result) => {
        this.exchangesFound = result.data;
      });
    },
    newDocument(document) {
      this.openDoc(document);
      this.official_documents.push(document);
    },
    openDoc(document) {
      this.$router.push({
        name: "document",
        params: { document_id: document.id },
      });
      // this.documentsSelected.push(document.id);
    },
    closeDoc(documentId) {
      this.documentsSelected.splice(
        this.documentsSelected.indexOf(documentId),
        1
      );
    },
    createDocument() {
      let payload = {
        emitter_id: this.emitter,
        recipient_id: this.recipient,
        official_nature: this.newDocNature,
        acted_date: moment().format("YYYY-MM-DD"),
      };
      this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.official_documents.push(result.data);
          let exchangesRequests = [];
          this.exchangesSelected.ids.forEach((exchangeId) => {
            let payloadExchange = {};
            payloadExchange[this.newDocNature] = result.data.id;
            exchangesRequests.push(
              this.axios.patch(
                `/api/exchanges/${exchangeId}.json`,
                payloadExchange
              )
            );
          });
          Promise.all(exchangesRequests).then(() => {
            this.$router.push({
              name: "document",
              params: { document_id: result.data.id },
            });
          });
        });
    },
    loadDocuments() {
      let url = `/api/official_documents.json?`;
      for (const [key, value] of Object.entries(this.urlQuery)) {
        url += `&${key}=${Array.isArray(value) ? value.join(",") : value}`;
      }
      this.axios.get(url).then((result) => {
        this.official_documents = result.data;
      });
    },
  },
  mounted() {
    let urlQueryStr = "";
    if (this.$route.query && this.$route.query.q) {
      urlQueryStr = this.$route.query.q;
    }
    if (urlQueryStr) {
      this.urlQuery = JSON.parse(urlQueryStr);
    } else {
      this.urlQuery = {
        tags__search: ["open"],
      };
    }
    this.loadDocuments(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadDocuments(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
