<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <a href="/api/indicators/generate/"> Générer les indicateurs </a>
        <hr />
        <div v-for="indicator in indicators" :key="`ind-${indicator.id}`">
          {{ indicator.direction }} - {{ indicator.flow_nature }} -
          <router-link
            v-if="indicator.article"
            :to="{ name: 'article', params: { article_id: indicator.article } }"
          >
            Article : {{ articles.find((a) => a.id == indicator.article).name }}
          </router-link>
          <router-link
            v-else-if="indicator.organization"
            :to="{
              name: 'organization',
              params: { organization_id: indicator.organization },
            }"
          >
            Organisation :
            {{ organizations.find((a) => a.id == indicator.organization).name }}
          </router-link>
          <span v-else> Global </span>
          {{ indicator.value }}€
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    indicators: [],
  }),
  watch: {},
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      organizations: "organizations/organizations",
    }),
  },
  methods: {
    loadDocuments() {
      let url = `/api/indicators.json?ordering=-value`;
      this.axios.get(url).then((result) => {
        this.indicators = result.data;
      });
    },
  },
  mounted() {
    this.loadDocuments(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadDocuments(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
