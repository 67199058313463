import axios from "axios";
import _ from "lodash";
/* eslint-disable no-debugger */

const state = {
  tags: [],
  isLoaded: false
};
const getters = {
  tags(state) {
    return _.sortBy(state.tags, ['label']);
  },
  mapTags(state) {
    let mapTags = {};
    state.tags.forEach(t => { mapTags[t.id] = { name: t.name, color: t.color } });
    return mapTags;
  },
};
const actions = {
  load({ state }) {
    if (!state.isLoaded) {
      state.isLoaded = true;
      state.tags = [];
      let url = `/api/tag_articles.json?`;
      return axios.get(url).then((result) => {
        state.tags = result.data;
      });
    }
  },
  create({ state }, payload) {
    state.isLoaded = true;
    let url = `/api/tag_articles.json`;
    axios.post(url, payload).then((result) => {
      state.tags.push(result.data);
    });
  },
  delete({ state }, id) {
    state.isLoaded = true;
    let url = `/api/tag_articles/${id}.json`;
    return axios.delete(url).then(() => {
      let localObj = state.tags.find(e => e.id == id);
      state.tags.splice(state.tags.indexOf(localObj), 1);
    });
  },

};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
