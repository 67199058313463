var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page)?_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"toolbar"},[(_vm.user.direction_access)?_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":_vm.edition ? 'primary' : '',"small":""},on:{"click":function($event){_vm.edition = !_vm.edition}}},[(_vm.edition)?_c('span',[_vm._v("Affichage standard")]):_c('span',[_vm._v("Edition")])]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":_vm.page.is_system ? 'primary' : '',"small":""},on:{"click":function($event){_vm.page.is_system = !_vm.page.is_system;
          _vm.patchPage('is_system', _vm.page.is_system);}}},[(_vm.page.is_system)?_c('span',[_vm._v("Page système")]):_c('span',[_vm._v("Page normale")])]),(_vm.edition)?_c('v-btn',{attrs:{"small":"","color":_vm.oldData != _vm.page.script + _vm.page.body ? 'primary' : ''},on:{"click":function($event){return _vm.patchPageScript()}}},[_vm._v("Sauvegarder")]):_vm._e()],1):_vm._e()],1),(_vm.edition)?_c('div',{staticClass:"wrapper"},[_c('EmojiPicker',{attrs:{"search":_vm.search},on:{"emoji":_vm.insert},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
          var clickEvent = ref.events.click;
return _c('div',{on:{"click":function($event){$event.stopPropagation();return clickEvent.apply(null, arguments)}}},[_c('div',{staticClass:"emoji-invoker ma-1"},[_vm._v(" "+_vm._s(_vm.page.icon ? _vm.page.icon : "-")+" ")])])}},{key:"emoji-picker",fn:function(ref){
          var emojis = ref.emojis;
return _c('div',{staticClass:"emoji-picker"},[_c('div',[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('h5',[_vm._v(_vm._s(category))]),_c('div',_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,staticClass:"emoji-btn pa-1",attrs:{"title":emojiName},on:{"click":function($event){_vm.page.icon = emoji;
                    _vm.patchPage('icon', emoji);}}},[_vm._v(_vm._s(emoji))])}),0)])}),0)])])}}],null,false,1381313224)})],1):_vm._e(),_c('div',[(_vm.page.is_system)?_c('div',[(_vm.edition)?_c('div',[_c('v-row',[_c('v-col',[_c('CodeEditor',{attrs:{"languages":[['html', 'HTML']],"font_size":"13px","width":"100%"},model:{value:(_vm.page.body),callback:function ($$v) {_vm.$set(_vm.page, "body", $$v)},expression:"page.body"}}),_c('CodeEditor',{attrs:{"languages":[['js', 'JS']],"font_size":"13px","width":"100%"},model:{value:(_vm.page.script),callback:function ($$v) {_vm.$set(_vm.page, "script", $$v)},expression:"page.script"}})],1),_c('v-col',[_c('v-text-field',{on:{"click":function($event){return _vm.patchPage('title', _vm.page.title)}},model:{value:(_vm.page.title),callback:function ($$v) {_vm.$set(_vm.page, "title", $$v)},expression:"page.title"}}),_c(("MyExternalComponent" + (_vm.page.id)),{tag:"component"})],1)],1)],1):_c('div',[_c('div',{staticClass:"document"},[_c('v-card',{staticClass:"pa-4 elevation-4"},[_c('h1',[_vm._v(_vm._s(_vm.page.icon)+" "+_vm._s(_vm.page.title))]),_c(("MyExternalComponent" + (_vm.page.id)),{tag:"component"})],1)],1)])]):_c('div',[(!_vm.edition)?_c('div',[_c('div',{staticClass:"document"},[_c('v-card',{staticClass:"pa-4 elevation-4"},[_c('h1',[_vm._v(_vm._s(_vm.page.title))]),_c('div',{staticClass:"ProseMirror",domProps:{"innerHTML":_vm._s(_vm.page.body)}})])],1)]):_c('div',[_c('v-text-field',{on:{"click":function($event){return _vm.patchPage('title', _vm.page.title)}},model:{value:(_vm.page.title),callback:function ($$v) {_vm.$set(_vm.page, "title", $$v)},expression:"page.title"}}),_c('TipTap',{on:{"input":function($event){
                    var i = arguments.length, argsArray = Array(i);
                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.patchPage.apply(void 0, [ 'body' ].concat( argsArray ))}},model:{value:(_vm.page.body),callback:function ($$v) {_vm.$set(_vm.page, "body", $$v)},expression:"page.body"}})],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }