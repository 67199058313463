<template>
  <v-dialog v-model="selectDialog" width="700">
    <template v-slot:activator="{}">
      <div
        :class="`article-select-placeholder ${canEdit ? 'editable' : ''}`"
        @click="openDialog()"
      >
        <div>
          {{ articleSelected ? articleSelected.name : "Aucun article" }}
        </div>
        <div v-if="articleSelected">
          <v-chip
            x-small
            v-for="tag in articleSelected.tags"
            :key="`ta-${tag}`"
            :color="mapTags[tag].color"
          >
            {{ mapTags[tag].name }}
          </v-chip>
        </div>
      </div>
    </template>
    <v-card class="pa-3">
      <v-card-title>Articles</v-card-title>
      <v-toolbar dense flat>
        <v-btn @click="removeArticle()" small>Retirer l'article</v-btn>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="createArticleAndRefresh()"
          >Créer l'article</v-btn
        >
      </v-toolbar>
      <v-text-field
        ref="filterStrInput"
        label="Rechercher"
        v-model="filterStr"
      ></v-text-field>
      <div class="" v-if="canCreate && filterStr"></div>
      <table class="fo-table fixed">
        <thead>
          <tr>
            <!-- <th class="actions"></th> -->
            <th>Nom</th>
          </tr>
        </thead>
        <tbody>
          <ArticleSelItem
            v-for="article in articlesToShow"
            :key="`article-${article.id}`"
            :article="article"
            @input="selectArticle(article.id)"
            :emitterId="emitterId"
            :recipientId="recipientId"
          >
          </ArticleSelItem>
        </tbody>
      </table>
      <v-alert v-if="articlesToShow.length == 0">
        Aucun article pour le moment
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import { mapGetters, mapActions } from "vuex";
import ArticleSelItem from "./ArticleSelItem.vue";
import Vue from "vue";

export default {
  inheritAttrs: true,
  components: {
    ArticleSelItem,
  },
  props: {
    value: {},
    canEdit: {
      default: true,
    },
    canCreate: {},
    recipientId: null,
    emitterId: null,
  },
  data: () => ({
    selectDialog: false,
    filterStr: "",
    articlesToShow: [],
  }),
  watch: {
    filterStr(value) {
      this.filter(value);
    },
    selectDialog() {
      if (this.selectDialog) {
        this.filterStr = null;
        if (this.articleSelected) {
          this.filterStr = this.articleSelected.name;
        }
        setTimeout(() => {
          this.$refs.filterStrInput.focus();
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      mapTags: "tags/mapTags",
      searchArticles: "articles/searchArticles",
    }),
    articleSelected() {
      return this.articles.find((a) => a.id == this.value);
    },
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
  methods: {
    ...mapActions({
      createArticle: "articles/create",
    }),
    filter(value) {
      if (value) {
        this.articlesToShow = this.searchArticles
          .search(value)
          .map((i) => i.item);
      } else {
        this.articlesToShow = this.articles;
      }
    },
    createArticleAndRefresh() {
      this.createArticle({
        name: this.filterStr,
        tags: [],
      }).then(() => {
        this.filter(this.filterStr);
      });
    },
    openDialog() {
      if (this.canEdit) {
        this.selectDialog = true;
      }
    },
    removeArticle() {
      this.selectArticle(null);
    },
    selectArticle(articleId) {
      Vue.set(this, "value", articleId);
      this.$nextTick(() => {
        this.$emit("input", articleId);
        this.$emit("change", articleId);
        this.selectDialog = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.article-select-placeholder {
  padding: 7px;
  width: 100%;
  font-size: 0.85em;
  &.editable:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }
}
</style>
