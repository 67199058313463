<template>
  <v-container v-if="contracts">
    <h1>Contrats</h1>
    <v-list subheader>
      <v-list-item
        v-for="contract in contracts"
        :key="`contract-${contract.id}`"
        link
        :to="{
          name: 'documents-all',
          query: { contract_id: contract.id },
        }"
      >
        <v-list-item-avatar> </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="contract.name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-alert v-if="contracts.length == 0">
      Aucun contrat pour le moment
    </v-alert>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    contracts: null,
  }),
  methods: {},
  mounted() {
    this.axios.get(`/api/contracts.json`).then((result) => {
      this.contracts = result.data;
    });
  },
};
</script>
<style lang="scss" scoped>
</style>
