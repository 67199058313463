<template>
  <v-container v-if="automations">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Automates</v-card-title>
          <v-toolbar flat dense class="mb-3">
            <v-btn @click="create()">Créer</v-btn>
          </v-toolbar>
          <v-list subheader>
            <v-list-item
              v-for="automation in automations"
              :key="`automation-${automation.id}`"
              link
              :to="{
                name: 'automation',
                params: { automation_id: automation.id },
              }"
            >
              <span>{{ automation.title }}</span>
            </v-list-item>
          </v-list>
          <v-alert v-if="automations.length == 0">
            Aucun import pour le moment
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    automations: null,
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    load() {
      this.axios.get(`/api/automations.json`).then((result) => {
        this.automations = result.data;
      });
    },
    create() {
      this.axios
        .post(`/api/automations.json`, { title: "Nouvel automate" })
        .then((result) => {
          this.$router.push({
            name: "automation",
            params: { automation_id: result.data.id },
          });
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style lang="scss" scoped>
</style>
