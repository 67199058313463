<template>
  <v-menu
    v-bind="$attrs"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formattedDate"
        :label="$attrs.label"
        readonly
        v-bind="$attrs"
        v-on="on"
        hide-details
        :clearable="clearable"
        @click:clear="value = null"
      ></v-text-field>
    </template>
    <v-date-picker v-model="value" no-title scrollable @input="emitInput" />
  </v-menu>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    value: {},
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    formattedDate() {
      return this.value ? moment(this.value).format("DD/MM/YYYY") : null;
    },
  },
  methods: {
    emitInput() {
      this.menu = false;
      if (this.value) {
        this.value = moment(this.value).format("YYYY-MM-DD");
      }
      this.$emit("input", this.value);
    },
  },
};
</script>
