<template>
  <v-container fluid v-if="automation">
    <v-row>
      <v-col cols="6">
        <v-toolbar flat dense class="mb-3">
          <v-btn @click="deleteAutomation()" small color="red">Supprimer</v-btn>
        </v-toolbar>
        <v-text-field
          v-model="automation.title"
          @change="patchAutomation('title', automation.title)"
        ></v-text-field>
        <v-toolbar flat dense class="mb-3">
          <v-btn
            @click="saveCode()"
            small
            :color="oldCode != automation.script ? 'primary' : ''"
            >Sauvegarder</v-btn
          >
        </v-toolbar>
        <CodeEditor
          v-model="automation.script"
          :languages="[['python', 'Python']]"
          font_size="13px"
          width="100%"
        ></CodeEditor>
      </v-col>
      <v-col cols="6">
        <v-toolbar flat dense class="mb-3">
          <v-btn small @click="runAutomation(automation)">Lancer</v-btn>
        </v-toolbar>
        <div
          v-for="execution in automation.executions"
          :key="`ex-${automation.id}automation-${execution.id}`"
        >
          <v-alert :color="execution.is_succeed ? 'green' : 'orange'">
            <div class="caption">
              {{ execution.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
              <v-btn x-small text @click="deleteExecution(execution)"
                >Supprimer</v-btn
              >
            </div>
            <code v-if="execution.output">
              {{ execution.output }}
            </code>
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
// import _ from "lodash";
import CodeEditor from "simple-code-editor";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    CodeEditor,
  },
  props: {},
  data: () => ({
    oldCode: null,
    automation: null,
  }),
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadAutomation(this.$route.params.automation_id);
  },
  mounted() {
    this.loadAutomation(this.$route.params.automation_id);
  },
  methods: {
    ...mapActions({}),
    runAutomation(automation) {
      this.axios.post(`/api/automations/${automation.id}/run.json`).then(() => {
        this.loadAutomation(this.automation.id);
      });
    },
    saveCode() {
      let payload = {
        script: this.automation.script,
      };
      this.axios
        .patch(`/api/automations/${this.automation.id}.json`, payload)
        .then(() => {
          this.oldCode = this.automation.script;
        });
    },
    patchAutomation(field, name) {
      let payload = {};
      payload[field] = name;
      this.axios.patch(`/api/automations/${this.automation.id}.json`, payload);
    },
    loadAutomation(automationId) {
      this.axios.get(`/api/automations/${automationId}.json`).then((result) => {
        this.automation = result.data;
        this.oldCode = result.data.script;
      });
    },
    deleteExecution(execution) {
      this.axios.delete(`/api/executions/${execution.id}.json`).then(() => {
        this.loadAutomation(this.automation.id);
      });
    },
    deleteAutomation() {
      if (window.confirm("Etes-vous sur ?")) {
        this.axios
          .delete(`/api/automations/${this.automation.id}.json`)
          .then(() => {
            this.$router.push({ name: "automations" });
          });
      }
    },
  },
};
</script>
