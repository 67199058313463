var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.payments)?_c('v-container',[_c('h1',[_vm._v("Paiements")]),_c('v-toolbar',{staticClass:"mb-4"},[_c('OrganizationPicker',{attrs:{"label":"Emetteur","dense":"","hide-details":""},model:{value:(_vm.newEmitter),callback:function ($$v) {_vm.newEmitter=$$v},expression:"newEmitter"}}),_c('OrganizationPicker',{attrs:{"label":"Destinataire","dense":"","hide-details":""},model:{value:(_vm.newRecipient),callback:function ($$v) {_vm.newRecipient=$$v},expression:"newRecipient"}}),_c('v-btn',{on:{"click":function($event){return _vm.createPayment()}}},[_vm._v(" Créer un paiement ")])],1),_c('table',{staticClass:"fo-table"},[_c('thead',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Emetteur")]),_c('th',[_vm._v("Destinataire")]),_c('th',[_vm._v("Répartition")]),_c('th',[_vm._v("Montant")])]),_vm._l((_vm.payments),function(payment){return _c('tr',{key:("pay-" + (payment.id))},[_c('td',[_c('FoDatePicker',{on:{"input":function($event){return _vm.patchPayment(payment, 'date', payment.date)}},model:{value:(payment.date),callback:function ($$v) {_vm.$set(payment, "date", $$v)},expression:"payment.date"}})],1),_c('td',[_c('OrganizationPicker',{attrs:{"label":"Emetteur","dense":"","disabled":true,"hide-details":""},model:{value:(payment.emitter),callback:function ($$v) {_vm.$set(payment, "emitter", $$v)},expression:"payment.emitter"}})],1),_c('td',[_c('OrganizationPicker',{attrs:{"label":"Destinataire","dense":"","disabled":true,"hide-details":""},model:{value:(payment.recipient),callback:function ($$v) {_vm.$set(payment, "recipient", $$v)},expression:"payment.recipient"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"items":_vm.invoices,"item-text":"invoice_number","item-value":"id","clearable":"","autocomplete":"off","return-object":""},on:{"focus":function($event){return _vm.loadInvoices(payment)},"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.selectInvoice.apply(void 0, argsArray.concat( [payment] ))}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Commencez à entrer le numéro de facture ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.unique_number)+" "),_c('strong',[_vm._v(_vm._s(item.amount_remaining))]),_vm._v("/"+_vm._s(item.net_amount))])],1)]}}],null,true),model:{value:(_vm.invoiceSelected),callback:function ($$v) {_vm.invoiceSelected=$$v},expression:"invoiceSelected"}}),_c('table',{staticClass:"fo-table"},[_c('thead',[_c('th',[_vm._v("Facture")]),_c('th',[_vm._v("Montant")])]),_vm._l((payment.payment_divisions),function(division){return _c('tr',{key:("p-" + (payment.id) + "-d-" + (division.id))},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'document',
                  params: { document_id: division.invoice.id },
                }}},[_vm._v(" "+_vm._s(division.invoice.unique_label || division.invoice.provider_reference || division.invoice.id)+" ")])],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","step":"any","hide-details":"","dense":""},on:{"change":function($event){return _vm.patchAmountDivision(payment, division, division.amount)}},model:{value:(division.amount),callback:function ($$v) {_vm.$set(division, "amount", $$v)},expression:"division.amount"}})],1),_c('td',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteDivision(payment, division)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)],1)])})],2)],1),_c('td',[_c('strong',[_vm._v(_vm._s(payment.amount)+"€")])]),_c('td',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteTransaction(payment)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)],1)])})],2),(_vm.payments.length == 0)?_c('v-alert',[_vm._v(" Aucun paiement pour le moment ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }