<template>
  <v-container v-if="payments">
    <h1>Paiements</h1>
    <v-toolbar class="mb-4">
      <OrganizationPicker
        label="Emetteur"
        v-model="newEmitter"
        dense
        hide-details
      ></OrganizationPicker>
      <OrganizationPicker
        label="Destinataire"
        v-model="newRecipient"
        dense
        hide-details
      ></OrganizationPicker>
      <v-btn @click="createPayment()"> Créer un paiement </v-btn>
    </v-toolbar>
    <table class="fo-table">
      <thead>
        <th>Date</th>
        <th>Emetteur</th>
        <th>Destinataire</th>
        <th>Répartition</th>
        <th>Montant</th>
      </thead>
      <tr v-for="payment in payments" :key="`pay-${payment.id}`">
        <td>
          <FoDatePicker
            v-model="payment.date"
            @input="patchPayment(payment, 'date', payment.date)"
          ></FoDatePicker>
        </td>
        <td>
          <OrganizationPicker
            label="Emetteur"
            v-model="payment.emitter"
            dense
            :disabled="true"
            hide-details
          ></OrganizationPicker>
        </td>
        <td>
          <OrganizationPicker
            label="Destinataire"
            v-model="payment.recipient"
            dense
            :disabled="true"
            hide-details
          ></OrganizationPicker>
        </td>
        <td>
          <v-autocomplete
            v-model="invoiceSelected"
            :items="invoices"
            item-text="invoice_number"
            item-value="id"
            clearable
            autocomplete="off"
            return-object
            @focus="loadInvoices(payment)"
            @input="selectInvoice(...arguments, payment)"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Commencez à entrer le numéro de facture
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.unique_number }}
                  <strong>{{ item.amount_remaining }}</strong
                  >/{{ item.net_amount }}</v-list-item-title
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <table class="fo-table">
            <thead>
              <th>Facture</th>
              <th>Montant</th>
            </thead>
            <tr
              v-for="division in payment.payment_divisions"
              :key="`p-${payment.id}-d-${division.id}`"
            >
              <td>
                <router-link
                  :to="{
                    name: 'document',
                    params: { document_id: division.invoice.id },
                  }"
                >
                  {{
                    division.invoice.unique_label ||
                    division.invoice.provider_reference ||
                    division.invoice.id
                  }}
                </router-link>
              </td>
              <td>
                <v-text-field
                  type="number"
                  step="any"
                  hide-details
                  dense
                  v-model="division.amount"
                  @change="
                    patchAmountDivision(payment, division, division.amount)
                  "
                ></v-text-field>
              </td>
              <td>
                <v-btn @click="deleteDivision(payment, division)" icon small>
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <strong>{{ payment.amount }}€</strong>
        </td>
        <td>
          <v-btn @click="deleteTransaction(payment)" icon small>
            <v-icon small>delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
    <v-alert v-if="payments.length == 0">
      Aucun paiement pour le moment
    </v-alert>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
import FoDatePicker from "../FoDatePicker.vue";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  components: { OrganizationPicker, FoDatePicker },
  data: () => ({
    invoiceSelected: null,
    payments: null,
    newEmitter: null,
    newRecipient: null,
    invoices: [],
  }),
  computed: {
    ...mapGetters({
      organizations: "organizations/organizations",
    }),
  },
  methods: {
    patchPayment(payment, field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/payment_transactions/${payment.id}.json`, payload)
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    patchAmountDivision(payment, division, value) {
      let payload = {
        amount: value,
      };
      this.axios
        .patch(`/api/payment_divisions/${division.id}.json`, payload)
        .then((result) => {
          payment.amount = result.data.payment_transaction.amount;
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    deleteDivision(payment, division) {
      this.axios
        .delete(`/api/payment_divisions/${division.id}.json`)
        .then(() => {
          payment.payment_divisions.splice(
            payment.payment_divisions.indexOf(division),
            1
          );
          payment.amount = payment.amount - division.amount;
        });
    },
    deleteTransaction(payment) {
      this.axios
        .delete(`/api/payment_transactions/${payment.id}.json`)
        .then(() => {
          this.payments.splice(this.payments.indexOf(payment), 1);
        });
    },
    selectInvoice(invoice, payment) {
      this.invoiceSelected = null;
      let payload = {
        invoice_id: invoice.id,
        payment_transaction_id: payment.id,
        amount: invoice.amount_remaining,
      };
      this.axios.post(`/api/payment_divisions.json`, payload).then((result) => {
        payment.payment_divisions.push(result.data);
        payment.amount = result.data.payment_transaction.amount;
        invoice.amount_remaining = result.data.invoice.amount_remaining;
      });
    },
    loadInvoices(payment) {
      let direction = "recipient_id";
      let partyId = payment.emitter_id;
      let emitter = this.organizations.find((e) => e.id == payment.emitter_id);
      if (!emitter.is_third_party) {
        direction = "emitter_id";
        partyId = payment.recipient_id;
      }
      this.axios
        .get(
          `/api/official_documents.json?official_nature=invoice&${direction}=${partyId}&amount_remaining__gt=0`
        )
        .then((result) => {
          this.invoices = result.data;
        });
    },
    createPayment() {
      let payload = {
        emitter_id: this.newEmitter,
        recipient_id: this.newRecipient,
        date: moment().format("YYYY-MM-DD"),
      };
      this.axios
        .post(`/api/payment_transactions.json`, payload)
        .then((result) => {
          this.payments.unshift(result.data);
        });
    },
    loadPayments() {
      this.axios
        .get(`/api/payment_transactions.json?ordering=-date`)
        .then((result) => {
          this.payments = result.data;
        });
    },
  },
  mounted() {
    this.loadPayments(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadPayments(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
