<template>
  <v-container fluid class="page">
    <v-card v-if="document" class="document ma-2 pa-2">
      <v-row>
        <v-col cols="8">
          <v-toolbar flat class="toolbar">
            <v-btn icon @click="$router.go(-1)" class="mr-3">
              <v-icon>navigate_before</v-icon>
            </v-btn>
            <v-chip large :color="colorNature">
              <span>
                <v-icon
                  class="pb-1"
                  v-if="document.official_nature == 'invoice'"
                  >receipt</v-icon
                >
                <v-icon
                  class="pb-1"
                  v-else-if="document.official_nature == 'delivery'"
                  >local_shipping</v-icon
                >
                <v-icon
                  class="pb-1"
                  v-else-if="document.official_nature == 'order'"
                  >settings_suggest</v-icon
                >
                <v-icon
                  class="pb-1"
                  v-else-if="document.official_nature == 'proposal'"
                  >request_quote</v-icon
                >
                {{ labelNature }}
              </span>
              &nbsp;

              <span v-if="document.emitter.is_third_party">
                <v-icon class="pb-1">input</v-icon>
                Achat
              </span>
              <span v-if="!document.emitter.is_third_party">
                <v-icon class="pb-1">output</v-icon>
                Vente
              </span>
              &nbsp;
              <span
                v-if="
                  !document.emitter.is_third_party &&
                  document.official_nature == 'invoice'
                "
              >
                {{
                  document.unique_label
                    ? `${document.unique_label}`
                    : "BROUILLON"
                }}
              </span>
            </v-chip>
            <span class="ml-3">
              <v-chip v-if="document.is_closed" outlined color="gray">
                <v-icon class="mr-1">lock</v-icon>
                Clôturée
              </v-chip>
              <v-chip v-else outlined color="green">
                <v-icon class="mr-1">lock_open</v-icon>
                Brouillon
              </v-chip>
            </span>
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              @click="generatePdf()"
              class="ma-2"
              v-if="
                document.direction == 'customer' &&
                ((document.official_nature == 'invoice' &&
                  document.is_closed) ||
                  document.official_nature == 'proposal')
              "
            >
              Générer PDF
            </v-btn>
            <span dense flat v-if="document.is_closed" class="d-flex">
              <!-- <v-btn
                small
                color="primary"
                @click="sendEmail()"
                v-if="
                  !document.emitter.is_third_party &&
                  document.official_nature == 'invoice'
                "
              >
                Envoyer par email
              </v-btn> -->
              <v-btn
                small
                color="primary"
                @click="reopenDoc()"
                class="ma-2"
                v-if="document.official_nature == 'invoice'"
              >
                Rouvrir
              </v-btn>
              <v-spacer></v-spacer>
            </span>

            <span dense flat v-else class="d-flex">
              <v-btn
                small
                color="primary"
                @click="closeDoc()"
                class="ma-2"
                v-if="
                  (document.direction == 'customer' &&
                    (document.official_nature == 'invoice' ||
                      document.official_nature == 'proposal')) ||
                  (document.direction == 'provider' &&
                    document.official_nature == 'invoice')
                "
              >
                Clôturer
              </v-btn>
              <v-spacer></v-spacer>
            </span>
            <v-btn small @click="deleteDocument()" color="red lighten-3"
              >Supprimer le document</v-btn
            >
          </v-toolbar>
          <v-row>
            <v-col :cols="8">
              <v-row>
                <v-col :cols="12" class="mt-2">
                  <v-text-field
                    v-model="document.title"
                    label="Titre"
                    dense
                    hide-details
                    @change="
                      patchDocument({
                        title: document.title,
                      })
                    "
                  ></v-text-field>
                </v-col>
                <v-col :cols="12">
                  <v-text-field
                    v-model="document.provider_reference"
                    label="Référence fournisseur"
                    dense
                    hide-details
                    @change="
                      patchDocument({
                        provider_reference: document.provider_reference,
                      })
                    "
                  ></v-text-field>
                </v-col>
                <v-col>
                  <!-- readonly : carefull, exchanges have to be changed to -->
                  <OrganizationPicker
                    label="Emetteur"
                    v-model="document.emitter"
                    dense
                    hide-details
                    :disabled="document.is_closed"
                    :can-create="true"
                    @change="updateOrganization('emitter', document.emitter)"
                  ></OrganizationPicker>
                </v-col>
                <v-col>
                  <!-- readonly : carefull, exchanges have to be changed to -->
                  <OrganizationPicker
                    label="Destinataire"
                    v-model="document.recipient"
                    dense
                    hide-details
                    :disabled="document.is_closed"
                    :can-create="true"
                    @change="
                      updateOrganization('recipient', document.recipient)
                    "
                  ></OrganizationPicker>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <FoDatePicker
                    label="Date"
                    dense
                    hide-details
                    v-model="document.acted_date"
                    :disabled="document.is_closed"
                    @input="setActedDate(document.acted_date)"
                  ></FoDatePicker>
                </v-col>
                <v-col>
                  <v-select
                    v-if="document.official_nature == 'invoice'"
                    label="Conditions"
                    v-model="document.payment_condition"
                    :items="paymentConditions"
                    @change="setDeadlineDoc(document.payment_condition)"
                    :disabled="document.is_closed"
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <v-col>
                  <FoDatePicker
                    v-if="document.official_nature == 'invoice'"
                    label="Échéance"
                    v-model="document.deadline_date"
                    :disabled="document.payment_condition != 'custom'"
                    @input="
                      patchDocument({ deadline_date: document.deadline_date })
                    "
                    dense
                    hide-details
                  ></FoDatePicker>
                </v-col>
              </v-row>
              <!-- <div>
            <TagsPicker
              v-model="document.tags"
              multiple
              @input="patchDocument({ tags: document.tags })"
            ></TagsPicker>
          </div> -->
            </v-col>
            <v-col :cols="4">
              <FileBrowser
                :entity="document"
                entityEndpoint="official_documents"
              />
              <v-row class="my-2">
                <v-col :cols="2" v-if="document.pdf" class="pa-1">
                  <v-card
                    class="pa-0 ma-0 text--center"
                    :href="document.pdf.url"
                    target="_blank"
                  >
                    <v-img
                      :src="document.pdf.thumbnail_url"
                      contain
                      max-height="200"
                      max-width="200"
                    />
                  </v-card>
                </v-col>
                <v-col
                  :cols="2"
                  v-for="(attachment, index) in document.attachments"
                  :key="`attachment-${index}`"
                  class="pa-1"
                >
                  <v-card
                    class="pa-0 ma-0 text--center"
                    :href="attachment.url"
                    target="_blank"
                  >
                    <div>
                      <v-img
                        :src="attachment.thumbnail_url"
                        contain
                        max-height="200"
                        max-width="200"
                      />
                    </div>
                    <!-- <div class="caption">
                      {{ attachment.file_name }}
                    </div> -->
                    <v-btn
                      small
                      icon
                      @click.prevent.stop="deleteFile(attachment)"
                    >
                      <v-icon x-small>delete</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row class="toolbar">
            <v-col>
              <div class="text-overline">Montant HT</div>
              <div class="text-h4 font-weight-light">
                {{ document.gross_amount ? document.gross_amount : "-" }}
              </div>
            </v-col>
            <v-col
              ><div class="text-overline">Montant TTC</div>
              <div class="text-h4 font-weight-light">
                {{ document.net_amount ? document.net_amount : "-" }}
              </div></v-col
            >
            <v-col
              ><div class="text-overline">Restant</div>
              <div class="text-h4 font-weight-light">
                {{ document.amount_remaining }}
              </div></v-col
            >
            <v-col v-if="document.official_nature == 'invoice'">
              <div class="text-overline">Etat</div>
              <div
                v-if="document.is_paid"
                class="text-h4 font-weight-light green--text"
              >
                Payée
              </div>
              <div v-else>
                <v-btn color="green" @click="markAsPaid"
                  >Marquer comme payée</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <div class="text-overline">Marge brute</div>
              <div class="text-h4 font-weight-light">
                {{ document.margin_amount }}
              </div>
            </v-col>
            <v-col
              ><div class="text-overline">Ratio marge</div>
              <div class="text-h4 font-weight-light">
                {{ Math.round(document.margin_ratio * 10000, 2) / 100 }}%
              </div>
            </v-col>
            <v-col
              ><div class="text-overline">Quantité</div>
              <div class="text-h4 font-weight-light">
                {{ document.quantity }}
              </div></v-col
            >
          </v-row> -->
        </v-col>
      </v-row>

      <v-toolbar dense flat class="ma-0 pa-0 mt-2 toolbar" elevation="2">
        <v-checkbox
          v-model="toggleAllValue"
          dense
          small
          :value="toggleAllValue"
          :indeterminate="toggleAllIndeterminate"
          @change="toggleAll"
          hide-details
        ></v-checkbox>

        <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              :color="rowsSelected.ids.length > 0 ? 'primary' : ''"
              v-bind="attrs"
              v-on="on"
              class="mr-3"
            >
              {{ rowsSelected.ids.length }} éléments
            </v-btn>
          </template> -->

        <v-chip
          small
          :color="rowsSelected.ids.length > 0 ? 'primary' : ''"
          class="mr-3"
        >
          {{ rowsSelected.ids.length }} éléments
        </v-chip>
        <div v-if="rowsSelected.ids.length > 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="document.official_nature != 'invoice'"
                class="ma-1"
                small
                link
                icon
                @click="markDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>task_alt</v-icon>
              </v-btn>
            </template>
            <span>Marquer les lignes</span>
          </v-tooltip>
          <v-btn small link icon @click="moveToDialog = true">
            <v-icon class="ma-1" small>input</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-1"
                small
                link
                icon
                @click="cloneDoc(document.official_nature)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>content_copy</v-icon>
              </v-btn>
            </template>
            <span>Dupliquer dans un nouveau document</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="ma-1"
                v-if="!document.is_closed"
                link
                icon
                color="red"
                @click="removeSelectedExchanges()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span
              >Retire les opérations du des documents et les supprime si elles
              ne sont dans aucun autre document.</span
            >
          </v-tooltip>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-1" v-bind="attrs" v-on="on" small>
                Nouvelle étape
              </v-btn>
            </template>
            <v-list>
              <v-list-item small link @click="createDocument('proposal')">
                Proposer
              </v-list-item>
              <v-list-item small link @click="createDocument('order')">
                Commander
              </v-list-item>
              <v-list-item small link @click="createDocument('delivery')">
                Livrer
              </v-list-item>
              <v-list-item small link @click="createDocument('invoice')">
                Facturer
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn
          small
          v-if="!(document.official_nature == 'invoice' && document.is_closed)"
          @click="createExchange()"
          color="green lighten-3"
          class="ma-1"
        >
          <v-icon small>add</v-icon>
          Nouvelle ligne</v-btn
        >
        <v-btn
          small
          v-if="!(document.official_nature == 'invoice' && document.is_closed)"
          @click="createSubtotal()"
          color="green lighten-5"
          class="ma-1"
          >Nouveau sous-total</v-btn
        >
        <GroupPicker
          v-if="user && user.beta_access"
          solo
          v-model="groupSelected"
          hide-details
          label="Groupes"
          small
          @input="selectGroup"
          dense
        ></GroupPicker>

        <v-dialog
          v-model="dialogOpsGroup"
          width="500"
          v-if="user && user.beta_access"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on"> Nouveau groupe </v-btn>
          </template>

          <v-card class="pa-3">
            <v-card-title>Nouveau groupe</v-card-title>
            <v-select
              :items="natures"
              v-model="groupNature"
              hide-details
              item-text="name"
              item-value="id"
              label="Nature"
              clearable
            ></v-select>
            <v-text-field label="Nom" v-model="groupName"></v-text-field>
            <v-text-field
              label="Nombre d'occurences"
              v-model="groupOccurences"
            ></v-text-field>
            <v-btn @click="addOpsGroup()"> Ajouter </v-btn>
          </v-card>
        </v-dialog>

        <textarea id="textarea-excel-output" v-model="excelOutput"></textarea>
        <v-btn class="ma-2" small @click="copyToExcel()"
          >Copier pour Excel</v-btn
        >
      </v-toolbar>
      <div class="exchanges-container py-2">
        <table class="fo-table">
          <thead class="table-header">
            <tr>
              <th></th>
              <th></th>
              <th>Etat</th>
              <th
                v-if="
                  user && user.beta_access && !document.emitter.is_third_party
                "
              >
                Source
              </th>
              <!-- <th>Date</th> -->
              <!-- <th>Livraison</th> -->
              <th>Note interne</th>
              <th>Article / Description</th>
              <!-- <th v-if="!document.emitter.is_third_party">Marge</th>
              <th v-if="!document.emitter.is_third_party">Ratio</th> -->
              <th>Qté</th>
              <!-- <th>Parc</th> -->
              <th>Prix unitaire HT</th>
              <th>Montant HT</th>
              <!-- <th>Remise HT</th> -->
              <th>TVA %</th>
              <th>Montant TTC</th>
              <th
                v-if="
                  user && user.beta_access && document.emitter.is_third_party
                "
              >
                Destination
              </th>
            </tr>
          </thead>

          <Draggable
            sortable="true"
            @change="onEnd(rows, ...arguments)"
            v-model="rows"
            tag="tbody"
            handle="td.drag"
            :options="{ disabled: document.is_closed }"
          >
            <template v-for="row in rows">
              <ExchangeItem
                v-if="row.exchange"
                :key="`row-${row.id}`"
                :document="document"
                :row="row"
                :exchange="row.exchange"
                :isEditable="!document.is_closed"
                :rowsSelected="rowsSelected"
                @update-amounts="updateAmounts()"
                @delete-exchange="removeRow(rows, row)"
              >
              </ExchangeItem>
              <tr v-else :key="`row-${row.id}`">
                <td class="">
                  <v-checkbox
                    v-model="rowsSelected.ids"
                    dense
                    x-small
                    class="ma-0 pa-0"
                    hide-details
                    :value="row.id"
                  ></v-checkbox>
                </td>
                <td class="drag">
                  <v-btn small icon v-if="!document.is_closed"
                    ><v-icon small>drag_indicator</v-icon></v-btn
                  >
                </td>
                <td colspan="2"></td>
                <td>
                  <div class="caption text-right pa-3 pr-5">Sous-total :</div>
                </td>
                <!-- <td>
                  <div class="caption text-center">
                    {{ row.margin_amount }}
                  </div>
                </td>
                <td>
                  <div class="caption text-center">
                    {{ Math.round(row.margin_ratio * 10000, 2) / 100 }}%
                  </div>
                </td> -->
                <td>
                  <div class="caption text-center">
                    {{ row.quantity }}
                  </div>
                </td>
                <td colspan="2"></td>
                <td>
                  <div class="caption text-center">
                    <strong>{{ row.gross_amount }}</strong>
                  </div>
                </td>
              </tr>
            </template>
          </Draggable>
        </table>
      </div>
      <v-dialog v-model="markDialog" max-width="500px">
        <v-card class="pa-3">
          <div v-if="document.official_nature == 'invoice'">
            Le document doit être cloturé dans son ensemble
          </div>
          <div v-else>
            Marquer les échanges comme...
            <div class="d-flex align-center mb-4">
              <v-btn @click="markExchangesAs(true)" class="mr-3">
                <strong v-if="document.official_nature == 'proposal'">
                  proposé
                </strong>
                <strong v-else-if="document.official_nature == 'order'">
                  commandé
                </strong>
                <strong v-if="document.official_nature == 'delivery'">
                  livré
                </strong>
                <strong v-if="document.official_nature == 'invoice'">
                  facturé
                </strong>
              </v-btn>
              <span class="mr-3">au</span>
              <FoDatePicker
                label="Date"
                v-model="proposedOn"
                :disabled="document.is_closed"
              ></FoDatePicker>
            </div>
            <div class="mb-4">
              <strong>ou comme</strong>
            </div>
            <div>
              <v-btn @click="markExchangesAs(false)">
                <strong v-if="document.official_nature == 'proposal'">
                  non proposé
                </strong>
                <strong v-else-if="document.official_nature == 'order'">
                  non commandé
                </strong>
                <strong v-if="document.official_nature == 'delivery'">
                  non livré
                </strong>
                <strong v-if="document.official_nature == 'invoice'">
                  non facturé
                </strong>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-card>
    <v-dialog v-model="providerOrderDialog" max-width="1000px">
      <v-card class="pa-3">
        <table class="fo-table">
          <tr v-for="(order, iO) in ordersToCreate" :key="`otc-${iO}`">
            <td>
              <h3>{{ order.emitter.name }}</h3>
            </td>
            <td>
              <table class="fo-table">
                <tr>
                  <td></td>
                  <td><strong> Article </strong></td>
                  <td><strong> Quantité </strong></td>
                  <td><strong> Prix unitaire </strong></td>
                </tr>
                <tr
                  v-for="(exchange, iEx) in order.exchanges"
                  :key="`otc-${iO}-nex-${iEx}`"
                >
                  <td>
                    <v-checkbox
                      v-model="exchange.create"
                      hide-details
                      dense
                    ></v-checkbox>
                  </td>
                  <td>{{ exchange.article.name }}</td>
                  <td>{{ exchange.quantity }}</td>
                  <td>{{ exchange.price.unit_gross_amount }}€</td>
                </tr>
              </table>
              <v-toolbar dense flat>
                <v-spacer></v-spacer>
                <v-btn @click="createProviderDoc(order, 'proposal')"
                  >Créer la proposition</v-btn
                >
                <v-btn @click="createProviderDoc(order, 'order')"
                  >Créer le bon de commande</v-btn
                >
                <v-btn @click="createProviderDoc(order, 'invoice')"
                  >Créer une facture</v-btn
                >
              </v-toolbar>
            </td>
          </tr>
        </table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="moveToDialog" max-width="600px">
      <v-card class="pa-3">
        <DocumentItem
          v-for="document in moveToDocuments"
          :key="`doc-m-t-${document.id}`"
          @click.native="moveTo(document)"
          :document="document"
        ></DocumentItem>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import moment from "moment";
import ExchangeItem from "../exchanges/ExchangeItem.vue";
import Draggable from "vuedraggable";
import FileBrowser from "../file_browser/FileBrowser.vue";
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
import FoDatePicker from "../FoDatePicker.vue";
import { mapActions, mapGetters } from "vuex";
// import _ from "lodash";
// import TagsPicker from "../tags/TagsPicker.vue";
import DocumentItem from "./DocumentItem.vue";
import GroupPicker from "../groups/GroupPicker.vue";

export default {
  components: {
    ExchangeItem,
    Draggable,
    FileBrowser,
    OrganizationPicker,
    FoDatePicker,
    // TagsPicker,
    DocumentItem,
    GroupPicker,
  },
  props: {
    document_id: {},
  },
  data: () => ({
    excelOutput: "Hello",
    overlay: true,
    providerOrderDialog: false,
    ordersToCreate: [],
    document: null,
    rowsSelected: {
      ids: [],
    },
    paymentConditions: [
      { value: "now", text: "Immédiate" },
      { value: "net 15", text: "15 jours net" },
      { value: "net 30", text: "30 jours net" },
      { value: "net 45", text: "45 jours net" },
      { value: "net 60", text: "60 jours net" },
      { value: "end 30", text: "30 jours fin de mois" },
      { value: "end 45", text: "45 jours fin de mois" },
      { value: "end 60", text: "60 jours fin de mois" },
      { value: "end 90", text: "90 jours fin de mois" },
      { value: "custom", text: "Personnalisée" },
    ],
    moveToDialog: false,
    moveToDocuments: [],
    markDialog: false,
    proposedOn: moment().format("YYYY-MM-DD"),
    dialogOpsGroup: false,
    groupNature: null,
    groupName: "Groupe",
    groupOccurences: 2,
    groupSelected: null,
  }),
  computed: {
    ...mapGetters({
      user: "user/user",
      articles: "articles/articles",
      externalPrices: "articles/externalPrices",
      organizations: "organizations/organizations",
      natures: "groups/natures",
    }),
    colorNature() {
      if (this.document.official_nature == "invoice") {
        return !this.document.is_closed ? "green lighten-4" : "green";
      } else if (this.document.official_nature == "delivery") {
        return !this.document.is_closed ? "yellow lighten-4" : "yellow";
      } else if (this.document.official_nature == "order") {
        return !this.document.is_closed ? "red lighten-4" : "red";
      } else if (this.document.official_nature == "proposal") {
        return !this.document.is_closed ? "blue lighten-4" : "blue";
      }
      return "Inconnu";
    },
    rows: {
      get() {
        if (this.document) {
          return this.document.rows;
        } else {
          return [];
        }
      },
      set(value) {
        this.document.rows = value;
      },
    },
    toggleAllValue() {
      return (
        this.rowsSelected.ids.length == this.rows.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    toggleAllIndeterminate() {
      return (
        this.rowsSelected.ids.length != this.rows.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    getNextStep() {
      if (this.document.official_nature == "proposal") {
        return 1;
      } else if (this.document.official_nature == "order") {
        return 2;
      } else if (this.document.official_nature == "delivery") {
        return 3;
      }
      return 0;
    },
    labelNature() {
      if (this.document.official_nature == "proposal") {
        return "Proposition";
      } else if (this.document.official_nature == "order") {
        return "Commande";
      } else if (this.document.official_nature == "delivery") {
        return "Livraison";
      } else if (this.document.official_nature == "invoice") {
        return "Facture";
      }
      return "Inconnu";
    },
  },
  watch: {
    moveToDialog(value) {
      if (value) {
        this.axios
          .get(
            `/api/official_documents.json?tags__search=open&ordering=-updated_at`
          )
          .then((result) => {
            this.moveToDocuments = result.data;
          });
      }
    },
  },
  methods: {
    ...mapActions({
      setPageLoading: "setPageLoading",
      updateDocElements: "stocks/updateDocElements",
    }),
    selectGroup() {
      this.overlay = true;
      let apiCalls = [];
      this.rowsSelected.ids.forEach((rowId) => {
        let row = this.rows.find((r) => r.id == rowId);
        if (row.exchange) {
          let payloadExchange = {};
          payloadExchange.groups = row.exchange.groups.map((g) => g.id);
          payloadExchange.groups.push(this.groupSelected);
          apiCalls.push({
            url: `/api/exchanges/${row.exchange.id}.json`,
            method: "patch",
            payload: payloadExchange,
          });
        }
      });
      async function callApi(cpt, apiCalls) {
        for (const apiCall of apiCalls) {
          await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
        }
        cpt.loadDocument(cpt.$route.params.document_id);
        cpt.overlay = false;
      }
      callApi(this, apiCalls);
    },
    addOpsGroup() {
      async function createExchanges(cpt, count) {
        cpt.overlay = true;
        let result = await cpt.axios.post(`/api/ops_groups.json`, {
          name: cpt.groupName,
          nature: cpt.groupNature,
        });
        let groupId = result.data.id;
        let i = 0;
        if (count < i) {
          count = 1;
        }
        while (i < count) {
          i++;
          await cpt.createExchange([groupId]);
        }
        await cpt.createSubtotal();
        await cpt.loadDocument(cpt.$route.params.document_id);
        cpt.overlay = false;
      }
      createExchanges(this, this.groupOccurences);
    },
    markAsPaid() {
      this.overlay = true;
      let payload = {
        emitter_id: this.document.recipient.id,
        recipient_id: this.document.emitter.id,
        date: this.document.deadline_date,
      };
      this.axios
        .post(`/api/payment_transactions.json`, payload)
        .then((result) => {
          let payload = {
            invoice_id: this.document.id,
            payment_transaction_id: result.data.id,
            amount: this.document.net_amount,
          };
          this.axios.post(`/api/payment_divisions.json`, payload).then(() => {
            this.loadDocument(this.document.id);
          });
        });
    },
    copyToExcel() {
      this.excelOutput = "";
      this.excelOutput += `Article\t`;
      this.excelOutput += `Description\t`;
      this.excelOutput += `Quantité\t`;
      this.excelOutput += `Prix U.\t`;
      this.excelOutput += `Prix HT\t`;
      this.excelOutput += `TVA %\t`;
      this.excelOutput += `TVA €\t`;
      this.excelOutput += `Prix TTC\t\n`;
      this.rows.forEach((row) => {
        if (row.exchange) {
          let article = this.articles.find((a) => a.id == row.exchange.article);
          this.excelOutput += `"${article ? article.name : ""}"\t`;
          let description = "";
          if (row.exchange.description) {
            let doc = new DOMParser().parseFromString(
              row.exchange.description,
              "text/html"
            );
            description = doc.body?.textContent || "";
          }
          this.excelOutput += `"${description}"\t`;
          this.excelOutput += `${String(row.exchange.quantity).replace(
            ".",
            ","
          )}\t`;
          this.excelOutput += `${String(row.exchange.unit_gross_amount).replace(
            ".",
            ","
          )}\t`;
          this.excelOutput += `${String(row.exchange.gross_amount).replace(
            ".",
            ","
          )}\t`;
          this.excelOutput += `${String(row.exchange.tax_ratio).replace(
            ".",
            ","
          )}\t`;
          this.excelOutput += `${String(row.exchange.tax_amount).replace(
            ".",
            ","
          )}\t`;
          this.excelOutput += `${String(row.exchange.net_amount).replace(
            ".",
            ","
          )}\t\n`;
        }
      });
      setTimeout(() => {
        var content = document.getElementById("textarea-excel-output");
        content.select();
        document.execCommand("copy");
      }, 100);
    },
    deleteFile(attachment) {
      if (window.confirm("Êtes-vous sûr(e) ?")) {
        this.axios
          .delete(`/api/attachments/${attachment.uuid}.json`)
          .then(() => {
            this.document.attachments.splice(
              this.document.attachments.indexOf(attachment),
              1
            );
          });
      }
    },

    moveTo(document) {
      this.overlay = true;
      let apiCalls = [];
      this.rowsSelected.ids.forEach((rowId) => {
        let row = this.rows.find((r) => r.id == rowId);
        let payloadRow = {};
        payloadRow[`document`] = document.id;
        apiCalls.push({
          url: `/api/doc_rows/${row.id}.json`,
          method: "patch",
          payload: payloadRow,
        });

        if (row.exchange) {
          let payloadExchange = {};
          payloadExchange[`emitter_id`] = document.emitter.id;
          payloadExchange[`recipient_id`] = document.recipient.id;
          payloadExchange[this.document.official_nature] = null;
          payloadExchange[document.official_nature] = document.id;
          if (this.document.official_nature != document.official_nature) {
            payloadExchange[`${this.document.official_nature}_acted_date`] =
              null;
          }
          apiCalls.push({
            url: `/api/exchanges/${row.exchange.id}.json`,
            method: "patch",
            payload: payloadExchange,
          });
        }
      });
      async function callApi(cpt, apiCalls) {
        for (const apiCall of apiCalls) {
          await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
        }
        cpt.loadDocument(cpt.$route.params.document_id);
        cpt.overlay = false;
      }
      callApi(this, apiCalls);
    },
    toggleAll() {
      if (this.rowsSelected.ids.length == 0) {
        this.rowsSelected.ids = this.rows.map((e) => e.id);
      } else if (this.rowsSelected.ids.length == this.rows.length) {
        this.rowsSelected.ids = [];
      } else {
        this.rowsSelected.ids = this.rows.map((e) => e.id);
      }
    },
    setActedDate(value) {
      let payload = {};
      payload["acted_date"] = value;
      this.axios
        .patch(`/api/official_documents/${this.document.id}.json`, payload)
        .then((result) => {
          this.document.deadline_date = result.data.deadline_date;
        });
    },
    setDeadlineDoc(value) {
      let payload = {};
      payload["payment_condition"] = value;
      this.axios
        .patch(`/api/official_documents/${this.document.id}.json`, payload)
        .then((result) => {
          this.document.deadline_date = result.data.deadline_date;
        });
    },
    // sendEmail() {
    //   this.setPageLoading(true);
    //   this.axios
    //     .patch(`/api/official_documents/${this.document.id}.json`, {
    //       is_sending_email: true,
    //     })
    //     .then(() => {
    //       this.setPageLoading(false);
    //     });
    // },
    generatePdf() {
      setTimeout(() => {
        this.setPageLoading(true);
      }, 100);
      this.axios
        .patch(`/api/official_documents/${this.document.id}.json`, {
          generate_pdf: true,
        })
        .then(() => {
          this.loadDocument(this.$route.params.document_id);
        });
    },
    closeDoc() {
      this.document.is_closed = true;
      setTimeout(() => {
        this.setPageLoading(true);
      }, 100);
      this.axios
        .patch(`/api/official_documents/${this.document.id}.json`, {
          generate_pdf: true,
          is_closed: true,
        })
        .then(() => {
          this.loadDocument(this.$route.params.document_id);
        });
    },
    reopenDoc() {
      this.setPageLoading(true);
      this.axios
        .patch(`/api/official_documents/${this.document.id}.json`, {
          is_closed: false,
        })
        .then(() => {
          this.loadDocument(this.$route.params.document_id);
        });
    },
    cloneDoc(nature) {
      this.overlay = true;
      let apiCalls = [];

      let payload = {
        official_nature: nature,
        contract_id: this.document.contract_id,
        emitter_id: this.document.emitter_id,
        recipient_id: this.document.recipient_id,
        acted_date: moment().format("YYYY-MM-DD"),
        title: this.document.title,
      };
      let promDoc = this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.rowsSelected.ids.forEach((rowId) => {
            let row = this.rows.find((r) => r.id == rowId);
            if (row.exchange) {
              let payloadExchange = Object.assign({}, row.exchange);
              payloadExchange.proposal_id = null;
              payloadExchange.order_id = null;
              payloadExchange.delivery_id = null;
              payloadExchange.invoice_id = null;
              payloadExchange[`${nature}_id`] = result.data.id;
              this.axios
                .post(`/api/exchanges.json`, payloadExchange)
                .then((resultEx) => {
                  let payloadRow = {
                    document: result.data.id,
                    position: row.position,
                    exchange_id: resultEx.data.id,
                  };
                  return this.axios.post(`/api/doc_rows.json`, payloadRow);
                });
            }
          });
          this.$emit("new-document", result.data);
          return result;
        });
      promDoc.then((resultDoc) => {
        async function callApi(cpt, apiCalls) {
          for (const apiCall of apiCalls) {
            await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
          }
          cpt.loadDocument(cpt.$route.params.document_id);

          cpt.$router.push({
            name: "document",
            params: { document_id: resultDoc.data.id },
          });
          cpt.overlay = false;
        }
        callApi(this, apiCalls);
      });
    },
    createDocument(nature) {
      this.overlay = true;
      let apiCalls = [];
      var hasAlreadyDocument = false;
      this.rowsSelected.ids.forEach((rowId) => {
        let row = this.rows.find((r) => r.id == rowId);
        if (row.exchange && row.exchange[`${nature}`]) {
          hasAlreadyDocument = true;
        }
      });
      if (hasAlreadyDocument) {
        alert("Des éléments sont déjà associé à document de cette nature");
        this.overlay = false;
        return;
      }

      let payload = {
        official_nature: nature,
        contract_id: this.document.contract_id,
        emitter_id: this.document.emitter_id,
        recipient_id: this.document.recipient_id,
        acted_date: moment().format("YYYY-MM-DD"),
        title: this.document.title,
      };
      let promDoc = this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.rowsSelected.ids.forEach((rowId) => {
            let row = this.rows.find((r) => r.id == rowId);
            let payloadRow = {
              document: result.data.id,
              position: row.position,
            };
            if (row.exchange) {
              payloadRow["exchange_id"] = row.exchange.id;
            }
            apiCalls.push({
              url: `/api/doc_rows.json`,
              method: "post",
              payload: payloadRow,
            });
            if (row.exchange) {
              let payloadExchange = {};
              payloadExchange[nature] = result.data.id;
              apiCalls.push({
                url: `/api/exchanges/${row.exchange.id}.json`,
                method: "patch",
                payload: payloadExchange,
              });
            }
          });
          this.$emit("new-document", result.data);
          return result;
        });
      promDoc.then((resultDoc) => {
        async function callApi(cpt, apiCalls) {
          for (const apiCall of apiCalls) {
            await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
          }
          cpt.loadDocument(cpt.$route.params.document_id);

          cpt.$router.push({
            name: "document",
            params: { document_id: resultDoc.data.id },
          });
          cpt.overlay = false;
        }
        callApi(this, apiCalls);
      });
    },
    updateOrganization(field, organization) {
      this.overlay = true;
      let apiCalls = [];
      this.rows.forEach((row) => {
        if (row.exchange) {
          let payloadExchange = {};
          payloadExchange[`${field}_id`] = organization;
          apiCalls.push({
            url: `/api/exchanges/${row.exchange.id}.json`,
            method: "patch",
            payload: payloadExchange,
          });
        }
      });
      let payloadDoc = {};
      payloadDoc[`${field}_id`] = organization;
      apiCalls.push({
        url: `/api/official_documents/${this.document.id}.json`,
        method: "patch",
        payload: payloadDoc,
      });
      async function callApi(cpt, apiCalls) {
        for (const apiCall of apiCalls) {
          await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
        }
        cpt.loadDocument(cpt.$route.params.document_id);
        cpt.overlay = false;
      }
      callApi(this, apiCalls);
    },
    markExchangesAs(actValue) {
      this.overlay = true;
      let apiCalls = [];
      this.rowsSelected.ids.forEach((rowId) => {
        let row = this.rows.find((r) => r.id == rowId);
        if (row.exchange) {
          let payloadExchange = {};
          payloadExchange[`is_closed_${this.document.official_nature}`] =
            actValue;
          if (actValue) {
            payloadExchange[`${this.document.official_nature}_acted_date`] =
              this.proposedOn;
          } else {
            payloadExchange[`${this.document.official_nature}_acted_date`] =
              null;
          }
          apiCalls.push({
            url: `/api/exchanges/${row.exchange.id}.json`,
            method: "patch",
            payload: payloadExchange,
          });
        }
      });
      async function callApi(cpt, apiCalls) {
        for (const apiCall of apiCalls) {
          await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
        }
        cpt.loadDocument(cpt.$route.params.document_id);
        cpt.overlay = false;
      }
      callApi(this, apiCalls);
    },
    onEnd(items, { moved }) {
      let newPosition = null;
      let previousPosition = 0;
      let nextPosition = items.length + 1;

      if (moved.newIndex > 0) {
        previousPosition = items[moved.newIndex - 1]["position"];
      }
      if (moved.newIndex < items.length - 1) {
        nextPosition = items[moved.newIndex + 1]["position"];
      }

      if (previousPosition < nextPosition) {
        newPosition = previousPosition + (nextPosition - previousPosition) / 2;
      } else {
        if (nextPosition < previousPosition) {
          nextPosition = previousPosition + 1;
        }
        newPosition = nextPosition + (nextPosition - previousPosition) / 2;
      }
      let payload = {};
      payload["position"] = newPosition;
      let element = this.document.rows.find((e) => e.id == moved.element.id);
      element["position"] = newPosition;
      this.axios
        .patch(`/api/doc_rows/${moved.element.id}.json`, payload)
        .then(() => {
          this.loadDocument(this.$route.params.document_id);
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    removeSelectedExchanges() {
      async function callApi(cpt, apiCalls) {
        for (const apiCall of apiCalls) {
          await cpt.axios[apiCall.method](apiCall.url, apiCall.payload);
        }
        cpt.loadDocument(cpt.$route.params.document_id);
        cpt.overlay = false;
      }
      if (window.confirm("Êtes-vous sûr ?")) {
        this.overlay = true;
        let apiCalls = [];
        this.rowsSelected.ids.forEach((id) => {
          let row = this.rows.find((r) => r.id == id);
          apiCalls.push({ url: `/api/doc_rows/${id}.json`, method: "delete" });
          if (row.exchange) {
            let payload = {};
            payload[`${this.document.official_nature}`] = null;
            row.exchange[`${this.document.official_nature}`] = null;
            apiCalls.push({
              url: `/api/exchanges/${row.exchange.id}.json`,
              method: "patch",
              payload: payload,
            });
            if (
              !row.exchange["proposal"] &&
              !row.exchange["order"] &&
              !row.exchange["delivery"] &&
              !row.exchange["invoice"]
            ) {
              apiCalls.push({
                url: `/api/exchanges/${row.exchange.id}.json`,
                method: "delete",
              });
            }
          }
        });
        callApi(this, apiCalls);
      }
    },
    updateAmounts() {
      this.loadDocument(this.$route.params.document_id);
    },
    createExchange(groupsIds) {
      this.overlay = true;
      let payload = {
        contract: this.document.contract_id,
        emitter_id: this.document.emitter_id,
        recipient_id: this.document.recipient_id,
        description: "",
        quantity: 1,
        tax_ratio: 20,
        unit_gross_amount: 0,
        gross_amount: 0,
        net_amount: 0,
        groups: groupsIds,
      };
      if (this.document.official_nature == "proposal") {
        payload["proposal"] = this.document.id;
      } else if (this.document.official_nature == "order") {
        payload["order"] = this.document.id;
      } else if (this.document.official_nature == "delivery") {
        payload["delivery"] = this.document.id;
      } else if (this.document.official_nature == "invoice") {
        payload["invoice"] = this.document.id;
      }
      return this.axios
        .post(`/api/exchanges.json`, payload)
        .then((resultEx) => {
          let payloadRow = {
            document: this.document.id,
            exchange_id: resultEx.data.id,
            position: 1,
          };
          if (this.document.rows.length > 0) {
            payloadRow.position =
              this.document.rows[this.document.rows.length - 1].position + 1;
          }
          return this.axios.post(`/api/doc_rows.json`, payloadRow).then(() => {
            // let row = resultRow.data;
            // row.exchange = resultEx.data;
            // this.document.rows.push(row);
            // this.overlay = false;
            return this.loadDocument(this.document.id);
          });
        });
    },
    createSubtotal() {
      let payloadRow = {
        document: this.document.id,
        position: 1,
      };
      if (this.document.rows.length > 0) {
        payloadRow.position =
          this.document.rows[this.document.rows.length - 1].position + 1;
      }
      return this.axios.post(`/api/doc_rows.json`, payloadRow).then(() => {
        return this.loadDocument(this.document.id);
      });
    },
    loadDocument(document_id) {
      this.setPageLoading(true);
      this.rowsSelected = {
        ids: [],
      };
      return this.axios
        .get(`/api/official_documents/${document_id}.json`)
        .then((result) => {
          this.document = null;
          this.document = result.data;
          this.updateDocElements(this.document);
          this.overlay = false;
        });
    },
    deleteDocument() {
      if (
        window.confirm(
          "Êtes-vous sûr ? Les lignes ne seront pas supprimées, sélectionnez et supprimer toutes les lignes avant si besoin."
        )
      ) {
        this.axios
          .delete(`/api/official_documents/${this.document.id}.json`)
          .then(() => {
            this.$router.push({ name: "home" });
          })
          .catch(() => {
            window.alert("Echec de la suppresion");
          });
      }
    },
    patchDocument(payload) {
      this.axios
        .patch(`/api/official_documents/${this.document.id}.json`, payload)
        .finally(() => {});
    },
  },
  updated() {
    this.setPageLoading(false);
  },
  mounted() {
    this.loadDocument(this.$route.params.document_id);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadDocument(to.params.document_id);
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding-bottom: 200px !important;
}
.toolbar {
  position: sticky;
  top: 48px;
  background: #fff;
  z-index: 2;
}
.table-header {
  position: sticky;
  top: 96px;
  background: #eee;
  z-index: 2;
}
.exchanges-container {
  background-color: #f6f6f6;
}
#textarea-excel-output {
  display: block;
  width: 1px;
  height: 1px;
  opacity: 0;
}
</style>
