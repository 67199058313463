<template>
  <div>
    <v-toolbar dense>
      <v-btn
        text
        :to="{
          name: 'docs-sells-lists',
          params: { nature: 'proposal' },
          query: { nature: 'proposal', direction: 'customer' },
        }"
      >
        <v-icon small>request_quote</v-icon>
        Propositions
      </v-btn>
      <v-btn
        text
        :to="{
          name: 'docs-sells-lists',
          params: { nature: 'order' },
          query: { nature: 'order', direction: 'customer' },
        }"
      >
        <v-icon small>settings_suggest</v-icon>
        Commandes
      </v-btn>
      <v-btn
        text
        :to="{
          name: 'docs-sells-lists',
          params: { nature: 'delivery' },
          query: { nature: 'delivery', direction: 'customer' },
        }"
      >
        <v-icon small>local_shipping</v-icon>
        Livraisons
      </v-btn>
      <v-btn
        text
        :to="{
          name: 'docs-sells-lists',
          params: { nature: 'invoice' },
          query: { nature: 'invoice', direction: 'customer' },
        }"
      >
        <v-icon small>receipt</v-icon>
        Factures
      </v-btn>

      <v-btn
        text
        :to="{ name: 'late-invoices', params: { direction: 'customer' } }"
      >
        <v-icon small>assignment_late</v-icon>
        Retards
      </v-btn>
    </v-toolbar>
    <router-view></router-view>
  </div>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {};
</script>
<style lang="scss" scoped>
</style>
