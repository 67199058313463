<template>
  <div>
    <div v-if="user">
      <v-menu offset-y class="pa-0 ma-0">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" dense>
            {{ user.email ? `${user.email}` : "Pas d'email" }}
          </v-list-item>
        </template>
        <v-list>
          <v-list-item @click="logout()">Déconnexion</v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else>
      <v-btn :to="{ name: 'login' }">Connexion</v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      logoutStore: "user/logout",
    }),
    logout() {
      this.logoutStore().then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
  mounted() {},
};
</script>
