<template>
  <v-container fluid v-if="organizations">
    <v-row>
      <v-col class="left">
        <v-card class="pa-3">
          <v-card-title>Organisations</v-card-title>
          <v-text-field label="Rechercher" v-model="filterStr"></v-text-field>
          <table class="fo-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Nom</th>
              </tr>
            </thead>
            <tbody>
              <OrganizationItem
                v-for="organization in organizationsToShow"
                :key="`organization-${organization.id}`"
                :organization="organization"
              >
              </OrganizationItem>
            </tbody>
          </table>
          <v-alert v-if="organizationsToShow.length == 0">
            Aucune organisation pour le moment
          </v-alert>
        </v-card>
      </v-col>
      <v-col :cols="8"><router-view></router-view></v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import OrganizationItem from "./OrganizationItem.vue";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {
    OrganizationItem,
  },
  data: () => ({
    organizationsToShow: [],
    filterStr: "",
  }),
  methods: {
    ...mapActions({}),
  },
  watch: {
    filterStr(value) {
      if (value) {
        this.organizationsToShow = this.searchOrganizations
          .search(value)
          .map((i) => i.item);
      } else {
        this.organizationsToShow = this.organizations;
      }
    },
  },
  computed: {
    ...mapGetters({
      organizations: "organizations/organizations",
      searchOrganizations: "organizations/searchOrganizations",
    }),
  },
  mounted() {
    this.organizationsToShow = this.organizations;
  },
};
</script>
<style lang="scss" scoped>
</style>
