import axios from "axios";
import _ from "lodash";
import Fuse from "fuse.js";
/* eslint-disable no-debugger */

const state = {
  elements: [],
  isLoaded: false
};
const getters = {
  elements(state, getters, rootState) {
    state.elements.map(e => {
      e.article = rootState.articles.articles.find(a => a.id == e.article_id)
    })
    return _.sortBy(state.elements, ['unique_reference']);
  },
  searchElements(state) {
    return new Fuse(state.elements, {
      keys: ["unique_reference", "article.name"],
    });
  }
};
const actions = {
  updateDocElements({ state }, document) {
    let elements = state.elements.filter(e => {
      return e.source?.proposal == document.id || e.destination?.proposal == document.id
        || e.source?.order == document.id || e.destination?.order == document.id
        || e.source?.delivery == document.id || e.destination?.delivery == document.id
        || e.source?.invoice == document.id || e.destination?.invoice == document.id
    })
    elements.forEach(e => {
      document.rows.forEach(row => {
        if (e.source?.id == row.exchange?.id) {
          e.source = row.exchange
        }
        if (e.destination?.id == row.exchange?.id) {
          e.destination = row.exchange
        }
      })
    })
  },
  load({ state }) {
    if (!state.isLoaded) {
      state.isLoaded = true;
      state.elements = [];
      let url = `/api/elements.json?`;
      return axios.get(url).then((result) => {
        state.elements = result.data;
      });
    }
  },
  create({ state }, payload) {
    state.isLoaded = true;
    let url = `/api/elements.json`;
    return axios.post(url, payload).then((result) => {
      state.elements.push(result.data);
      return result;
    });
  },
  patch({ state }, { id, payload }) {
    state.isLoaded = true;
    return axios.patch(`/api/elements/${id}.json`, payload).then((result) => {
      let i = state.elements.indexOf(state.elements.find(e => e.id == id))
      state.elements[i] = Object.assign(state.elements[i], result.data);
      return result;
    });
  },
  delete({ state }, id) {
    state.isLoaded = true;
    let url = `/api/elements/${id}.json`;
    return axios.delete(url).then(() => {
      let localObj = state.elements.find(e => e.id == id);
      state.elements.splice(state.elements.indexOf(localObj), 1);
    });
  },
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
