/* eslint-disable no-debugger */
import Image from '@tiptap/extension-image'
// import { mergeAttributes } from '@tiptap/core'

export default Image.extend({
  name: 'custom-image',

  addAttributes() {
    return {
      ...this.parent?.(),
      size: {
        default: 'medium',
        parseHTML: element => element.getAttribute('data-size'),
        renderHTML: attributes => {
          return {
            'data-size': attributes.size,
            'class': `custom-image ${attributes.size}`,
          }
        },
      },

    }
  },

  addCommands() {
    return {
      setImage: (options) => ({ tr, commands }) => {
        if (tr.selection?.node?.type?.name == 'custom-image') {
          return commands.updateAttributes('custom-image', options)
        }
        else {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
      },
    }
  },

  // parseHTML() {
  //     return [
  //         {
  //             tag: 'img[src]',
  //             getAttrs: element => {
  //                 element.getAttribute('class')
  //             },
  //         },
  //     ]
  // },
  // renderHTML({ HTMLAttributes }) {

  //     // HTMLAttributes.class = ' custom-image-' + node.attrs.size
  //     // // debugger;
  //     return [
  //         'img',
  //         mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
  //     ]
  // }
})