<template>
  <v-container v-if="group">
    <v-card class="pa-3">
      <h3>
        <span v-if="mapNatures[group.nature_id]"
          >{{ mapNatures[group.nature_id].name }}&nbsp;</span
        >
        <span v-else> Groupe </span>
      </h3>
      <v-text-field
        v-model="group.name"
        @input="patchGroup('name', group.name)"
      ></v-text-field>

      <div class="description">
        <label class="text-caption">Description</label>
        <TipTap
          v-model="group.description"
          @input="saveTextField('description', ...arguments)"
        />
      </div>
      <!-- <div>
        <CustomDataForm
          nature="ops_groups"
          :entity="group"
          :custom_data="group.custom_data"
        ></CustomDataForm>
      </div> -->
      <table class="fo-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Quantité</th>
            <th>Prix €</th>
            <!-- <th>Tier</th> -->
          </tr>
        </thead>
        <tbody>
          <ExchangeLight
            v-for="exchange in group.exchanges"
            :key="`ex-${group.id}group-${exchange.id}`"
            :exchange="exchange"
          ></ExchangeLight>
        </tbody>
      </table>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
// import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import ExchangeLight from "../exchanges/ExchangeLight.vue";
import TipTap from "../tiptap/TipTap.vue";
// import CustomDataForm from "../data/CustomDataForm.vue";
export default {
  components: {
    ExchangeLight,
    TipTap,
    // CustomDataForm,
  },
  props: {},
  data: () => ({
    group: null,
  }),
  computed: {
    ...mapGetters({
      mapNatures: "groups/mapNatures",
    }),
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$router.go(-1);
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadGroup(this.$route.params.group_id);
  },
  mounted() {
    this.loadGroup(this.$route.params.group_id);
  },
  methods: {
    ...mapActions({}),
    patchGroup(field, name) {
      clearTimeout(this.timeOutSave);
      this.timeOutSave = setTimeout(() => {
        let payload = {};
        payload[field] = name;
        this.axios.patch(`/api/ops_groups/${this.group.id}.json`, payload);
      }, 300);
    },
    saveTextField(field, value) {
      clearTimeout(this.timeOutSave);
      this.timeOutSave = setTimeout(() => {
        let payload = {};
        payload[field] = value;
        this.axios
          .patch(`/api/ops_groups/${this.group.id}.json`, payload)
          .catch(() => {
            window.alert("Changement non enregistré");
          });
      }, 300);
    },
    loadGroup(groupId) {
      this.axios.get(`/api/ops_groups/${groupId}.json`).then((result) => {
        this.group = result.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  border: 2px solid #eee;
}
</style>
