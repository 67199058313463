<template>
  <v-container fluid>
    <div v-if="article" class="pa-3">
      <v-toolbar flat class="toolbar">
        <v-btn icon :to="{ name: 'articles' }" class="mr-3">
          <v-icon>navigate_before</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small @click="deleteArticle()">Supprimer l'article</v-btn>
      </v-toolbar>
      <v-row>
        <v-col :cols="3">
          <v-card class="pa-3">
            <v-text-field
              name="Nom de l'article"
              label="Nom de l'article"
              v-model="article.name"
              @change="patchArticle('name', article.name)"
              hide-details
            ></v-text-field>
            <div>
              <TagsPicker
                label="Catégories"
                v-model="article.tags"
                multiple
                :canCreate="true"
                :nature="'article'"
                @input="patchArticle('tags', article.tags)"
              ></TagsPicker>
            </div>
            <div class="description">
              <label class="text-caption">Description</label>
              <TipTap
                v-model="article.description"
                @input="saveTextField('description', ...arguments)"
              />
            </div>
            <div class="internal_note">
              <label class="text-caption">Note privée</label>
              <TipTap
                v-model="article.internal_note"
                @input="saveTextField('internal_note', ...arguments)"
              />
            </div>
            <div>
              <CustomDataForm
                nature="articles"
                :entity="article"
                :custom_data="article.custom_data"
              ></CustomDataForm>
            </div>
            <div>
              <v-switch
                v-model="article.is_stockable"
                label="Est stockable ?"
                @change="patchArticle('is_stockable', article.is_stockable)"
              ></v-switch>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="pa-3">
            <v-toolbar flat dense>
              <v-btn color="blue" small @click="createPrice()"
                >Ajouter un prix</v-btn
              >
            </v-toolbar>
            <div class="caption">
              Laissez vide le destinataire pour appliquer le prix à tous.
            </div>
            <table class="fo-table">
              <thead>
                <th></th>
                <th>Emetteur</th>
                <th>Destinataire</th>
                <th>Qté min</th>
                <th>Mont. Unit. HT</th>
              </thead>
              <tbody>
                <tr v-for="price in article.prices" :key="`price-${price.id}`">
                  <td class="pa-1">
                    <span class="caption" v-if="price.direction == 'customer'"
                      >Client</span
                    >
                    <span class="caption" v-else>Fournisseur</span>
                  </td>
                  <td class="">
                    <OrganizationPicker
                      label="Emetteur"
                      solo
                      v-model="price.emitter"
                      dense
                      hide-details
                      :can-create="true"
                      clearable
                      @change="patchPrice(price, 'emitter', price.emitter)"
                    ></OrganizationPicker>
                  </td>
                  <td class="">
                    <OrganizationPicker
                      label="Destinataire"
                      solo
                      placeholder="Tous"
                      v-model="price.recipient"
                      dense
                      hide-details
                      :can-create="true"
                      clearable
                      @change="patchPrice(price, 'recipient', price.recipient)"
                    ></OrganizationPicker>
                  </td>
                  <td class="numbers">
                    <input
                      type="number"
                      step="any"
                      v-model="price.quantity_min"
                      clearable
                      @change="
                        patchPrice(price, 'quantity_min', price.quantity_min)
                      "
                    />
                  </td>
                  <td class="numbers">
                    <input
                      type="number"
                      step="any"
                      v-model="price.unit_gross_amount"
                      @change="
                        patchPrice(
                          price,
                          'unit_gross_amount',
                          price.unit_gross_amount
                        )
                      "
                    />
                  </td>
                  <td>
                    <v-btn icon small @click="deletePrice(price)">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="pa-3">
            <h3>Opérations</h3>
            <table class="fo-table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Quantité</th>
                  <th>Prix €</th>
                  <!-- <th>Tier</th> -->
                </tr>
              </thead>
              <tbody>
                <!-- <ExchangeSource
                v-for="exchange in exchanges"
                :key="`ex-${exchange.id}`"
                :exchange="exchange"
              ></ExchangeSource> -->
                <ExchangeLight
                  v-for="exchange in exchanges"
                  :key="`ex-${exchange.id}`"
                  :exchange="exchange"
                  :hideDescription="true"
                ></ExchangeLight>
              </tbody>
            </table>
          </v-card>
          <v-card
            class="pa-3 mt-3"
            v-if="user && user.beta_access && article.is_stockable"
          >
            <h3>Parc</h3>
            <v-row>
              <v-col>
                <div class="text-caption">Unités</div>
                <div class="text-overline">{{ article.stock_units }}</div>
              </v-col>
              <v-col>
                <!-- <div class="text-caption">Coût d'achat moyen pondéré</div>
              <div class="text-overline">
                {{ article.weighted_average_cost }}€
              </div> -->
              </v-col>
            </v-row>
            <hr />

            <table class="fo-table">
              <thead>
                <tr>
                  <!-- <th>Etat</th> -->
                  <th>Source</th>
                  <th>Quantité</th>
                  <th>Référence</th>
                  <th>Destination</th>
                </tr>
              </thead>
              <tbody>
                <ElementItem
                  v-for="element in elements"
                  :key="`element-${element.id}`"
                  :element="element"
                  :hideArticle="true"
                >
                </ElementItem>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
import TagsPicker from "../tags/TagsPicker.vue";
// import ExchangeSource from "../exchanges/ExchangeSource.vue";
import ExchangeLight from "../exchanges/ExchangeLight.vue";
import ElementItem from "../stocks/ElementItem.vue";
import TipTap from "../tiptap/TipTap.vue";
import CustomDataForm from "../data/CustomDataForm.vue";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {
    OrganizationPicker,
    TagsPicker,
    // ExchangeSource,
    ExchangeLight,
    ElementItem,
    TipTap,
    CustomDataForm,
  },
  data: () => ({
    article: null,
    exchanges: [],
    elements: [],
  }),
  computed: {
    ...mapGetters({
      user: "user/user",
      articles: "articles/articles",
    }),
  },
  methods: {
    ...mapActions({
      storeDelete: "articles/delete",
    }),
    saveTextField(field, value) {
      clearTimeout(this.timeOutSave);
      this.timeOutSave = setTimeout(() => {
        let payload = {};
        payload[field] = value;
        this.axios
          .patch(`/api/articles/${this.article.id}.json`, payload)
          .catch(() => {
            window.alert("Changement non enregistré");
          });
      }, 300);
    },
    patchArticle(field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/articles/${this.article.id}.json`, payload)
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    deleteArticle() {
      this.storeDelete(this.article.id)
        .then(() => {
          this.$router.push({ name: "articles" });
        })
        .catch(() => {
          window.alert("Echec de la suppresion");
        });
    },
    createPrice() {
      let payload = {
        article: this.article.id,
        unit_gross_amount: 1,
      };
      this.axios
        .post(`/api/prices.json`, payload)
        .then((result) => {
          this.article.prices.push(result.data);
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    patchPrice(price, field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/prices/${price.id}.json`, payload)
        .then((result) => {
          price[field] = result.data[field];
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        });
    },
    deletePrice(price) {
      this.axios
        .delete(`/api/prices/${price.id}.json`)
        .then(() => {
          this.article.prices.splice(
            this.article.prices.indexOf(
              this.article.prices.find((p) => p.id == price.id)
            )
          );
        })
        .catch(() => {
          window.alert("Echec de la suppresion");
        });
    },
  },
  mounted() {
    this.article = this.articles.find(
      (e) => e.id == this.$route.params.article_id
    );
    this.axios
      .get(
        `/api/exchanges.json?article_id=${this.$route.params.article_id}&ordering=-order_acted_date,-delivery_acted_date`
      )
      .then((result) => {
        this.exchanges = result.data;
      });
    this.axios
      .get(`/api/elements.json?article_id=${this.$route.params.article_id}`)
      .then((result) => {
        this.elements = result.data;
      });
  },
};
</script>
<style lang="scss" scoped>
.description,
.internal_note {
  border: 2px solid #eee;
}

.numbers {
  font-size: 1em;
  width: 80px;
  min-width: 80px;
  text-align: right;
  border-right: 1px solid #eee;
  padding: 4px;
  &.bold input {
    font-weight: bold;
  }
  input {
    width: 100%;
    min-width: 90px;
    display: block;
    text-align: right;
    color: #222;
    padding: 8px 4px;
    border: 2px solid #ccc;
  }
}
</style>
