<template>
  <v-container v-if="imps">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Imports</v-card-title>
          <v-list subheader>
            <v-list-item
              v-for="imp in imps"
              :key="`imp-${imp.id}`"
              link
              :to="{
                name: 'imp',
                params: { imp_id: imp.id },
              }"
            >
              <span>{{ imp.name }}</span>
            </v-list-item>
          </v-list>
          <v-alert v-if="imps.length == 0">
            Aucun import pour le moment
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {},
  data: () => ({
    imps: null,
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    load() {
      this.axios.get(`/api/imports.json`).then((result) => {
        this.imps = result.data;
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style lang="scss" scoped>
</style>
