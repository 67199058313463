import axios from "axios";
import _ from "lodash";
import Fuse from "fuse.js";
/* eslint-disable no-debugger */

const state = {
  articles: [],
  isLoaded: false
};
const getters = {
  articles(state) {
    return _.sortBy(state.articles, ['name']);
  },
  mapArticles(state) {
    let mapArticles = {};
    state.articles.forEach(a => {
      mapArticles[a.id] = a
    })
    return mapArticles;
  },
  externalPrices(state, getters, rootState) {
    var prices = [];
    let extOrgsIds = rootState.organizations.organizations.filter(o => o.is_third_party).map(o => o.id);
    state.articles.forEach((article) => {
      prices = prices.concat(...article.prices.filter(p => extOrgsIds.includes(p.emitter)));
    })
    return prices;
  },
  searchArticles(state, getters, rootState, rootGetters) {
    let mapTags = rootGetters["tags/mapTags"];
    let articles = state.articles;
    articles.forEach((a) => {
      a.tags_str = a.tags.map((t) => mapTags[t].name)
    })
    return new Fuse(state.articles, {
      keys: ["tags_str", "name"],
    })
  },
};
const actions = {
  load({ state }) {
    if (!state.isLoaded) {
      state.isLoaded = true;
      state.articles = [];
      let url = `/api/articles.json?`;
      return axios.get(url).then((result) => {
        state.articles = result.data;
        state.articles.map((a) => {
          a.tags_inline = a.tags.reduce((l, n) => `${l} ${n}`, '')
        })
      });
    }
  },
  create({ state }, payload) {
    state.isLoaded = true;
    let url = `/api/articles.json`;
    return axios.post(url, payload).then((result) => {
      state.articles.push(result.data);
    });
  },
  delete({ state }, id) {
    state.isLoaded = true;
    let url = `/api/articles/${id}.json`;
    return axios.delete(url).then(() => {
      let localObj = state.articles.find(e => e.id == id);
      state.articles.splice(state.articles.indexOf(localObj), 1);
    });
  },

};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
