<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-card class="pa-3">
          <v-card-title>Filtres</v-card-title>
          <v-row>
            <v-col>
              <v-select
                :items="doctypes"
                v-model="urlQuery.official_nature__in"
                multiple
                hide-details
                label="Type de documents"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><OrganizationPicker
                label="Emetteur"
                v-model="urlQuery.emitter_id__in"
                hide-details
                :can-create="true"
                clearable
                multiple
              ></OrganizationPicker
            ></v-col>
            <v-col
              ><OrganizationPicker
                label="Destinataire"
                v-model="urlQuery.recipient_id__in"
                hide-details
                :can-create="true"
                clearable
                multiple
              ></OrganizationPicker
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              <FoDatePicker
                label="Date de début d'émission"
                v-model="urlQuery.acted_date__gte"
              ></FoDatePicker>
            </v-col>
            <v-col>
              <FoDatePicker
                label="Date de fin d'émission"
                v-model="urlQuery.acted_date__lte"
              ></FoDatePicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <FoDatePicker
                label="Date de début d'échéance"
                v-model="urlQuery.deadline_date__gte"
                clearable
              ></FoDatePicker>
            </v-col>
            <v-col>
              <FoDatePicker
                label="Date de fin d'échéance"
                v-model="urlQuery.deadline_date__lte"
                clearable
              ></FoDatePicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                multiple
                :items="docStates"
                v-model="urlQuery.is_closed"
                hide-details
                label="Etat"
              ></v-select>
            </v-col>
            <v-col v-if="$route.params.nature == 'invoice'">
              <v-select
                multiple
                :items="paidStates"
                v-model="urlQuery.is_paid"
                hide-details
                label="Payée"
              ></v-select>
            </v-col>
          </v-row>
          <!-- <TagsPicker
            v-model="urlQuery.tags__search"
            hide-details
            clearable
            multiple
            label="Tags"
          ></TagsPicker> -->
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-3">
          <v-card-title>Documents</v-card-title>

          <v-toolbar dense flat class="mb-4">
            <v-dialog v-model="dialogInvoice" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on">
                  Créer un document
                </v-btn>
              </template>

              <v-card class="pa-3">
                <v-card-title>Nouveau document</v-card-title>
                <v-select
                  class="pa-2"
                  hide-details
                  :items="doctypes"
                  v-model="newDocNature"
                  @change="searchExchanges"
                ></v-select>
                <OrganizationPicker
                  label="Emetteur"
                  v-model="emitter"
                  class="pa-2"
                  solo
                  hide-details
                  @change="searchExchanges"
                  :can-create="true"
                ></OrganizationPicker>
                <OrganizationPicker
                  label="Bénéficiaire"
                  v-model="recipient"
                  class="pa-2"
                  solo
                  hide-details
                  @change="searchExchanges"
                  :can-create="true"
                ></OrganizationPicker>
                <!-- <v-toolbar>
                  <v-checkbox
                    v-model="toggleAllValue"
                    dense
                    small
                    :value="toggleAllValue"
                    :indeterminate="toggleAllIndeterminate"
                    @change="toggleAll"
                  ></v-checkbox>
                  <v-toolbar-title>Échanges appropriés</v-toolbar-title>
                </v-toolbar>
                <ExchangeItem
                  v-for="exchange in exchangesFound"
                  :key="`exchange-found-${exchange.id}`"
                  :exchange="exchange"
                  :isEditable="false"
                  :exchangesSelected="exchangesSelected"
                >
                </ExchangeItem>
                <span v-if="exchangesFound.length == 0"> Aucun élément </span> -->
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="createDocumentEmpty()">
                    Créer
                    <!-- <span v-if="exchangesSelected.ids.length > 0">
                      avec {{ exchangesSelected.ids.length }} éléments
                    </span> -->
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
          </v-toolbar>
          <table class="fo-table">
            <DocumentItem
              v-for="official_document in official_documents"
              :key="`official_document-${official_document.id}`"
              :document="official_document"
              @click.native.ctrl="openDoc(official_document, true)"
              @click.native.meta="openDoc(official_document, true)"
              @click.native.exact="openDoc(official_document, false)"
              @query="addToQuery"
            >
            </DocumentItem>
          </table>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-3">
          <v-card-title>Opérations</v-card-title>
          <v-toolbar dense flat class="ma-0 pa-0">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" small v-on="on" class="mx-1">
                  <v-checkbox
                    v-model="toggleAllValue"
                    dense
                    small
                    :value="toggleAllValue"
                    :indeterminate="toggleAllIndeterminate"
                    hide-details
                    @click.stop="toggleAll"
                  ></v-checkbox>
                  <v-icon class="pt-1" small>expand_more</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-divider></v-divider>
                <v-list-item
                  link
                  v-for="recipient in recipients"
                  :key="`rec-${recipient.id}`"
                  @click="selectAll(recipient)"
                >
                  {{ recipient.name }}
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="rowsSelected.ids.length > 0 ? 'primary' : ''"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-3"
                  small
                >
                  {{ rowsSelected.ids.length }} éléments
                </v-btn>
              </template>
              <v-list>
                <v-divider></v-divider>
                <v-list-item link @click="createDocumentFromSelection('order')">
                  Passer la commande
                </v-list-item>
                <v-list-item
                  link
                  @click="createDocumentFromSelection('delivery')"
                >
                  Effectuer la livraison
                </v-list-item>
                <v-list-item
                  link
                  @click="createDocumentFromSelection('invoice')"
                >
                  Facturer
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <ArticlePicker v-model="articleId" hide-details dense clearable />
          <table class="fo-table" v-if="!this.articleId">
            <ExchangeLight
              v-for="exchange in exchanges"
              :key="`exchange-${exchange.id}`"
              :exchange="exchange"
              :rowsSelected="rowsSelected"
            >
            </ExchangeLight>
          </table>
          <table class="fo-table" v-else>
            <ExchangeLight
              v-for="exchange in exchangesFound"
              :key="`exchange-found-${exchange.id}`"
              :exchange="exchange"
              :rowsSelected="rowsSelected"
            >
            </ExchangeLight>
          </table>
          <v-alert v-if="exchanges.length == 0">
            Aucun échange pour le moment
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";
import moment from "moment";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
import DocumentItem from "./DocumentItem.vue";
// import ExchangeItem from "../exchanges/ExchangeItem.vue";
import FoDatePicker from "../FoDatePicker.vue";
// import TagsPicker from "../tags/TagsPicker.vue";
import ExchangeLight from "../exchanges/ExchangeLight.vue";
import { mapGetters } from "vuex";
import ArticlePicker from "../articles/ArticlePicker.vue";
export default {
  components: {
    OrganizationPicker,
    DocumentItem,
    // ExchangeItem,
    FoDatePicker,
    // TagsPicker,
    ExchangeLight,
    ArticlePicker,
  },
  data: () => ({
    rowsSelected: {
      ids: [],
    },
    urlQuery: {},
    official_documents: [],
    newDocNature: "proposal",
    docStates: [
      {
        text: "Brouillon",
        value: false,
      },
      {
        text: "Clôturé",
        value: true,
      },
    ],
    paidStates: [
      {
        text: "Non",
        value: false,
      },
      {
        text: "Oui",
        value: true,
      },
    ],
    doctypes: [
      {
        text: "Proposition",
        value: "proposal",
      },
      {
        text: "Commande",
        value: "order",
      },
      {
        text: "Livraison",
        value: "delivery",
      },
      {
        text: "Facture",
        value: "invoice",
      },
    ],
    exchanges: [],
    exchangesFound: [],
    exchangesSelected: {
      ids: [],
    },
    dialogDocuments: false,
    dialogInvoice: false,
    contract: null,
    documentsSelected: [],
    official_document: null,
    emitter: null,
    recipient: null,
    articleId: null,
    urlEx: "",
  }),
  watch: {
    dialogInvoice() {
      this.newDocNature = this.$route.params?.nature;
      this.$route.matched.forEach((r) => {
        if (r.name == "purchases") {
          this.recipient = this.user.organization;
        } else if (r.name == "sells") {
          this.emitter = this.user.organization;
        }
      });
    },
    urlQuery: {
      deep: true,
      handler() {
        if (
          !this.$route.query.q ||
          JSON.stringify(this.urlQuery) != this.$route.query.q
        ) {
          this.$router.push({
            name: this.$route.name,
            query: { q: JSON.stringify(this.urlQuery) },
          });
        }
      },
    },
    articleId() {
      this.searchExchanges();
    },
  },
  computed: {
    ...mapGetters({
      organizations: "organizations/organizations",
      user: "user/user",
    }),
    toggleAllValue() {
      return (
        this.rowsSelected.ids.length == this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    toggleAllIndeterminate() {
      return (
        this.rowsSelected.ids.length != this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    recipients() {
      let recipients = [];
      this.exchanges.forEach((e) => {
        let rIdFound = recipients.find((r) => r.id == e.recipient);
        if (!rIdFound) {
          let org = this.organizations.find((o) => o.id == e.recipient);
          recipients.push(org);
        }
      });
      return recipients;
    },
  },
  methods: {
    selectAll(recipient) {
      this.rowsSelected.ids = this.exchanges
        .filter(
          (e) => e.recipient == recipient.id && !e[this.$route.params.nature]
        )
        .map((e) => e.id);
    },
    addToQuery(filter, value) {
      if (!this.urlQuery[filter]) {
        Vue.set(this.urlQuery, filter, []);
      }
      this.urlQuery[filter].push(value);
    },
    toggleAll() {
      if (this.rowsSelected.ids.length == 0) {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      } else if (this.rowsSelected.ids.length == this.exchanges.length) {
        this.rowsSelected.ids = [];
      } else {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      }
    },
    createDocumentFromSelection(nature) {
      let firstId = this.rowsSelected.ids[0];
      let firstExchange = this.exchanges.find((r) => r.id == firstId);
      let isSameRecipient = true;
      let isDocOrphan = true;
      this.rowsSelected.ids.forEach((rowId) => {
        let exchange = this.exchanges.find((r) => r.id == rowId);
        if (exchange.recipient != firstExchange.recipient) {
          isSameRecipient = false;
          return;
        }
        if (exchange[`${nature}`]) {
          isDocOrphan = false;
          return;
        }
      });
      if (!isSameRecipient) {
        window.alert("Les échanges ne sont pas pour le même destinataire");
        return;
      }
      if (!isDocOrphan) {
        window.alert("Un échange est déjà sur un document de même nature");
        return;
      }
      let payload = {
        official_nature: nature,
        emitter_id: firstExchange.emitter,
        recipient_id: firstExchange.recipient,
        acted_date: moment().format("YYYY-MM-DD"),
      };
      var promCreations = [];
      var i = 1;
      let promDoc = this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.rowsSelected.ids.forEach((rowId) => {
            let exchange = this.exchanges.find((r) => r.id == rowId);
            let payloadRow = {
              document: result.data.id,
              position: i++,
            };
            payloadRow["exchange_id"] = exchange.id;
            let payloadExchange = {};
            payloadExchange[nature] = result.data.id;
            let promEx = this.axios
              .patch(`/api/exchanges/${exchange.id}.json`, payloadExchange)
              .then(() => {
                let promiseRow = this.axios.post(
                  `/api/doc_rows.json`,
                  payloadRow
                );
                promCreations.push(promiseRow);
              });
            promCreations.push(promEx);
          });
          this.$emit("new-document", result.data);
          return result;
        });

      promCreations.push(promDoc);
      setTimeout(() => {
        Promise.all(promCreations).then(() => {
          promDoc.then((resultDoc) => {
            this.$router.push({
              name: "document",
              params: { document_id: resultDoc.data.id },
            });
          });
        });
      }, 200);
    },
    searchExchanges() {
      let exchangesSearchRequest = this.urlEx;
      if (this.emitter) {
        exchangesSearchRequest += `&emitter_id=${this.emitter}`;
      }
      if (this.recipient) {
        exchangesSearchRequest += `&recipient_id=${this.recipient}`;
      }
      if (this.articleId) {
        exchangesSearchRequest += `&article_id=${this.articleId}`;
      }
      this.axios.get(exchangesSearchRequest).then((result) => {
        this.exchangesFound = result.data;
      });
    },
    newDocument(document) {
      this.openDoc(document);
      this.official_documents.push(document);
    },
    openDoc(document, isNewTab) {
      if (isNewTab) {
        let routeLink = this.$router.resolve({
          name: "document",
          params: { document_id: document.id },
        });
        window.open(routeLink.href, "_blank");
      } else {
        this.$router.push({
          name: "document",
          params: { document_id: document.id },
        });
      }
      // this.documentsSelected.push(document.id);
    },
    closeDoc(documentId) {
      this.documentsSelected.splice(
        this.documentsSelected.indexOf(documentId),
        1
      );
    },
    createDocumentEmpty() {
      let payload = {
        emitter_id: this.emitter,
        recipient_id: this.recipient,
        official_nature: this.newDocNature,
        acted_date: moment().format("YYYY-MM-DD"),
      };
      this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.official_documents.push(result.data);
          let exchangesRequests = [];
          this.exchangesSelected.ids.forEach((exchangeId) => {
            let payloadExchange = {};
            payloadExchange[this.newDocNature] = result.data.id;
            exchangesRequests.push(
              this.axios.patch(
                `/api/exchanges/${exchangeId}.json`,
                payloadExchange
              )
            );
          });
          Promise.all(exchangesRequests).then(() => {
            this.$router.push({
              name: "document",
              params: { document_id: result.data.id },
            });
          });
        });
    },
    loadDocuments() {
      this.rowsSelected.ids = [];
      let urlQueryStr = "";
      if (this.$route.query && this.$route.query.q) {
        urlQueryStr = this.$route.query.q;
      }
      if (urlQueryStr) {
        this.urlQuery = JSON.parse(urlQueryStr);
      } else {
        this.urlQuery = {
          is_closed: false,
          // tags__search: ["open"],
        };
      }
      if (this.$route.query?.nature) {
        this.urlQuery["official_nature__in"] = this.$route.query.nature;
      }
      if (this.$route.query?.direction) {
        this.urlQuery["direction"] = this.$route.query.direction;
      }
      if (this.$route.query && this.$route.query.q) {
        let url = `/api/official_documents.json?`;
        for (const [key, value] of Object.entries(this.urlQuery)) {
          url += `&${key}=${Array.isArray(value) ? value.join(",") : value}`;
        }
        this.axios.get(url).then((result) => {
          this.official_documents = result.data;
        });
        this.urlEx = `/api/exchanges.json?`;
        if (this.urlQuery.official_nature__in == "proposal") {
          this.urlEx += `&proposal_acted_date__isnull=True&order__isnull=True&delivery__isnull=True&invoice__isnull=True&ordering=proposal_acted_date`;
        } else if (this.urlQuery.official_nature__in == "order") {
          this.urlEx += `&order_acted_date__isnull=True&proposal_acted_date__isnull=False&delivery__isnull=True&invoice__isnull=True&ordering=order_acted_date`;
        } else if (this.urlQuery.official_nature__in == "delivery") {
          this.urlEx += `&order_acted_date__isnull=False&delivery_acted_date__isnull=True&invoice__isnull=True&ordering=delivery_acted_date`;
        } else if (this.urlQuery.official_nature__in == "invoice") {
          this.urlEx += `&delivery_acted_date__isnull=False&invoice_acted_date__isnull=True&ordering=invoice_acted_date`;
        }
        for (const [key, value] of Object.entries(this.urlQuery)) {
          this.urlEx += `&${key}=${
            Array.isArray(value) ? value.join(",") : value
          }`;
        }
        this.axios.get(this.urlEx).then((result) => {
          this.exchanges = result.data;
        });
      }
    },
  },
  mounted() {
    this.loadDocuments(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadDocuments(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
