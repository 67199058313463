<template>
  <tr>
    <!-- <td class="actions">
      <slot></slot>
    </td> -->
    <td class="pa-1">
      <router-link
        class="block-link"
        :to="{
          name: 'article',
          params: { article_id: article.id },
        }"
      >
        <div class="name">{{ article.name }}</div>
        <div>
          <v-chip
            x-small
            v-for="tag in article.tags"
            :key="`ta-${tag}`"
            :color="mapTags[tag].color"
          >
            {{ mapTags[tag].name }}
          </v-chip>
          <span class="no-tag" v-if="article.tags.length == 0"
            >Aucune catégorie</span
          >
        </div>
      </router-link>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
// import _ from "lodash";
export default {
  components: {},
  props: {
    article: {},
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      mapTags: "tags/mapTags",
    }),
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.block-link {
  display: block;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
  font-size: 0.9em;
  border-bottom: 1px solid #f6f6f6;
  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // max-width: 500px;
  }
  .no-tag {
    color: #aaa;
    font-size: 0.7em;
  }
  &:hover {
    background: #f6f6f6;
  }
}
</style>

