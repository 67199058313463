
<template>
  <tr v-if="document" class="document pa-1 text-body-2">
    <td class="ma-0 pa-1 caption">
      <v-chip
        x-small
        :color="colorNature"
        @click.prevent.stop="
          $emit('query', 'official_nature__in', document.official_nature)
        "
        >{{ labelNature }}
        <v-icon x-small class="ml-1">
          {{ document.is_closed ? "lock" : "" }}
        </v-icon>
      </v-chip>
      <v-chip x-small color="#CCC" v-if="document.unique_label">{{
        document.unique_label
      }}</v-chip>
    </td>
    <td class="ma-0 pa-1 caption">
      <div class="black--text">{{ actedDate }}</div>
      <div class="grey--text">{{ deadlineDate }}</div>
    </td>
    <td class="ma-0 pa-1 caption" v-if="document.emitter.is_third_party">
      <v-chip
        dark
        x-small
        color="purple"
        @click.prevent.stop="
          $emit('query', 'emitter_id__in', document.emitter.id)
        "
        >{{ document.emitter.name }}</v-chip
      >
    </td>
    <td class="ma-0 pa-1 caption" v-if="document.recipient.is_third_party">
      <v-chip
        dark
        x-small
        color="pink"
        @click.prevent.stop="
          $emit('query', 'recipient_id__in', document.recipient.id)
        "
        >{{ document.recipient.name }}
      </v-chip>
    </td>
    <td class="ma-0 pa-1 caption amounts">
      <div class="black--text">
        {{ document.gross_amount ? document.gross_amount : "-" }}€ HT
      </div>
      <div class="grey--text">
        {{ document.net_amount ? document.net_amount : "-" }}€ TTC
      </div>
    </td>
    <td class="ma-0 pa-1 caption title">
      <span class="caption">
        {{ document.title }}
        &nbsp;
      </span>
    </td>
    <td class="ma-0 pa-1 caption" v-if="document.official_nature == 'invoice'">
      <v-icon class="pa-2" x-small :color="paymentColor">euro</v-icon>
    </td>
  </tr>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    nature: {},
    document: {},
  },
  computed: {
    ...mapGetters({
      tags: "tags/mapTags",
    }),
    colorNature() {
      if (this.document.official_nature == "proposal") {
        return !this.document.is_closed ? "blue lighten-4" : "blue";
      } else if (this.document.official_nature == "order") {
        return !this.document.is_closed ? "red lighten-4" : "red";
      } else if (this.document.official_nature == "delivery") {
        return !this.document.is_closed ? "yellow lighten-4" : "yellow";
      } else if (this.document.official_nature == "invoice") {
        return !this.document.is_closed ? "green lighten-4" : "green";
      }
      return "Inconnu";
    },
    labelNature() {
      if (this.document.official_nature == "proposal") {
        return "Proposition";
      } else if (this.document.official_nature == "order") {
        return "Commande";
      } else if (this.document.official_nature == "delivery") {
        return "Livraison";
      } else if (this.document.official_nature == "invoice") {
        return "Facture";
      }
      return "Inconnu";
    },
    actedDate() {
      return this.document.acted_date
        ? moment(this.document.acted_date).format("DD/MM/YYYY")
        : "-";
    },
    deadlineDate() {
      return this.document.deadline_date
        ? moment(this.document.deadline_date).format("DD/MM/YYYY")
        : "-";
    },
    paymentColor() {
      if (this.document.is_paid) {
        return "green";
      } else {
        if (moment(this.document.deadline_date).isBefore(moment())) {
          return "red";
        } else {
          return "grey";
        }
      }
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.document {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f6;
  }
  td {
    width: 0%;
    &.amounts {
      div {
        white-space: nowrap;
      }
    }
    &.title {
      width: 100%;
    }
  }
}
</style>

