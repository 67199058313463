/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import router from "./router.js";
import Vuex from "vuex";
Vue.use(Vuex);
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
import App from "./App.vue";
// import gmaps from "./plugins/gmaps";
import vuetify from "./plugins/vuetify";
import Cookies from "js-cookie";

Vue.config.productionTip = false;
import store from "./store.js";

import moment from "moment";
moment.locale("fr");
import VueMoment from "vue-moment";
Vue.use(VueMoment);


import VueExcelEditor from 'vue-excel-editor'
Vue.use(VueExcelEditor)

Vue.config.productionTip = false;

axios.defaults.headers.common["X-CSRFToken"] = Cookies.get("csrftoken");
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] = Cookies.get("auth_token");


// https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = (msg, vm, trace) => {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};
var app;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (
      error.response.status === 403 &&
      error.response.config.url != "/auth/users/me.json"
    ) {
      app.$store.dispatch("user/setUnauthorizedResponse", true);
      Cookies.remove("auth_token");
      axios.defaults.headers.common["Authorization"] = null;
    } else if (error.response.status === 404) {
      app.$store.dispatch("user/setNotFoundResponse", true);
    }
    return Promise.reject(error.response);
  }
);
app = new Vue({
  store: store,
  router: router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
// app.$store.dispatch("user/autologin");

// UNIT TEST DRAG & DROP Selenium
window.app = app