<template>
  <v-autocomplete
    v-bind="$attrs"
    v-bind:value="value"
    v-on="inputListeners"
    :items="organizations"
    :loading="isLoading"
    item-text="name"
    item-value="id"
    autocomplete="off"
    @input="selectItems"
    :search-input.sync="searchInput"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Commencez à entrer le nom d'une
          <strong>organisation</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:append-item>
      <div class="pa-2" v-if="canCreate">
        <v-row>
          <v-col>
            <v-switch
              v-model="isThirdParty"
              :label="isThirdParty ? 'Externe' : 'Interne'"
            ></v-switch>
          </v-col>
          <v-col>
            <v-btn
              small
              color="primary"
              @click="
                createOrganization({
                  name: searchInput,
                  is_third_party: isThirdParty,
                })
              "
              >Créer l'organisation</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        dark
        :color="item.is_third_party ? 'purple' : 'pink'"
        class="font-weight-light white--text"
      >
        &nbsp;
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <div>
          <v-chip
            x-small
            v-for="tag in item.tags"
            :color="tags[tag].color"
            :key="`i-${item.id}-t-${tag}`"
          >
            {{ tags[tag].label }}
          </v-chip>
        </div>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  inheritAttrs: true,
  props: {
    value: {},
    canCreate: {},
  },
  data: () => ({
    searchTime: null,
    isLoading: false,
    items: [],
    selectedItems: [],
    searchInput: "",
    idsSelected: [],
    isThirdParty: true,
  }),
  computed: {
    ...mapGetters({
      organizations: "organizations/organizations",
      tags: "tags/mapTags",
    }),

    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
  // watch: {
  //   search(searchedValue) {
  //     clearTimeout(this.searchTime);
  //     this.searchTime = setTimeout(() => {
  //       if (searchedValue && searchedValue.length > 0) {
  //         this.isLoading = true;
  //         this.axios
  //           .get(
  //             `/api/organizations.json?` +
  //               `name=${searchedValue}` +
  //               `&limit=10&offset=0`
  //           )
  //           .then((result) => {
  //             this.items = [];
  //             this.items = _.concat(
  //               result.data.results.filter(
  //                 (e) => !this.idsSelected.includes(e.id)
  //               ),
  //               this.selectedItems
  //             );
  //           })
  //           .finally(() => (this.isLoading = false));
  //       }
  //     }, 400);
  //   },
  // },
  methods: {
    ...mapActions({
      createOrganization: "organizations/create",
    }),
    selectItems(value) {
      this.idsSelected = value;
      if (!Array.isArray(this.idsSelected)) {
        this.idsSelected = [value];
      }
      this.selectedItems = _.concat(
        [],
        this.items.filter((e) => this.idsSelected.includes(e.id))
      );
    },
  },
};
</script>
