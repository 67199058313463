<template>
  <v-container fluid v-if="exchanges">
    <h1>Échanges</h1>
    <v-tabs v-model="tab">
      <v-tab
        v-for="(tabObject, indTab) in tabs"
        :key="`tab-${indTab}`"
        @click="$router.push({ name: 'exchanges', query: { tab: indTab } })"
      >
        {{ tabObject.label }}
      </v-tab>
    </v-tabs>

    <v-toolbar dense flat class="ma-0 pa-0">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mx-1">
            <v-checkbox
              v-model="toggleAllValue"
              dense
              small
              :value="toggleAllValue"
              :indeterminate="toggleAllIndeterminate"
              hide-details
              @click.stop="toggleAll"
            ></v-checkbox>
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-divider></v-divider>
          <v-list-item
            link
            v-for="recipient in recipients"
            :key="`rec-${recipient.id}`"
            @click="selectAll(recipient)"
          >
            {{ recipient.name }}
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="rowsSelected.ids.length > 0 ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          >
            {{ rowsSelected.ids.length }} éléments
          </v-btn>
        </template>
        <v-list>
          <v-divider></v-divider>
          <v-list-item link @click="createDocument('order')">
            Passer la commande
          </v-list-item>
          <v-list-item link @click="createDocument('delivery')">
            Effectuer la livraison
          </v-list-item>
          <v-list-item link @click="createDocument('invoice')">
            Facturer
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-row>
      <v-col :cols="6">
        <table class="fo-table">
          <ExchangeLight
            v-for="exchange in exchanges"
            :key="`exchange-${exchange.id}`"
            :exchange="exchange"
            :rowsSelected="rowsSelected"
          >
          </ExchangeLight>
        </table>
        <v-alert v-if="exchanges.length == 0">
          Aucun échange pour le moment
        </v-alert>
      </v-col>
      <v-col :cols="6">
        <div class="d-flex">
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-select
            v-model="type"
            :items="types"
            dense
            outlined
            hide-details
            class="ma-2"
            label="type"
          ></v-select>
          <v-select
            v-model="mode"
            :items="modes"
            dense
            outlined
            hide-details
            label="event-overlap-mode"
            class="ma-2"
          ></v-select>
          <v-select
            v-model="weekday"
            :items="weekdays"
            dense
            outlined
            hide-details
            label="weekdays"
            class="ma-2"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @change="getEvents"
            @click:event="openExchange"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import moment from "moment";
import ExchangeLight from "./ExchangeLight.vue";
import { mapGetters } from "vuex";
export default {
  components: { ExchangeLight },
  data: () => ({
    exchanges: [],
    tab: 0,
    filters: "",
    rowsSelected: {
      ids: [],
    },
    // calendar
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    tabs: [
      {
        label: "A relancer",
        counter: null,
        filters: `emitter__is_third_party=False&ordering=-updated_at&order__isnull=True&proposal_acted_date__isnull=False&invoice__isnull=True`,
      },
      {
        label: "A commander",
        counter: null,
        filters: `emitter__is_third_party=True&ordering=-updated_at&order__isnull=False&order_acted_date__isnull=True`,
      },
      {
        label: "Livraisons à réceptionner",
        counter: null,
        filters: `emitter__is_third_party=True&ordering=-updated_at&order__isnull=False&order_acted_date__isnull=False&delivery_acted_date__isnull=True`,
      },
      {
        label: "Factures à associer",
        counter: null,
        filters: `emitter__is_third_party=True&ordering=-updated_at&delivery__isnull=False&delivery_acted_date__isnull=False&invoice_acted_date__isnull=True`,
      },
      {
        label: "Commandes en attente",
        counter: null,
        filters: `emitter__is_third_party=False&ordering=-updated_at&order__isnull=False&delivery__isnull=True&is_provided=False`,
      },
      {
        label: "Commandes à préparer",
        counter: null,
        filters: `emitter__is_third_party=False&ordering=-updated_at&order__isnull=False&delivery__isnull=True&is_provided=True`,
      },
      {
        label: "Livraisons à effectuer",
        counter: null,
        filters: `emitter__is_third_party=False&ordering=-updated_at&delivery__isnull=False&delivery_acted_date__isnull=True`,
      },
      {
        label: "A facturer",
        counter: null,
        filters: `emitter__is_third_party=False&ordering=-updated_at&delivery_acted_date__isnull=False&invoice_acted_date__isnull=True`,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      organizations: "organizations/organizations",
    }),
    toggleAllValue() {
      return (
        this.rowsSelected.ids.length == this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    toggleAllIndeterminate() {
      return (
        this.rowsSelected.ids.length != this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    recipients() {
      let recipients = [];
      this.exchanges.forEach((e) => {
        let rIdFound = recipients.find((r) => r.id == e.recipient);
        if (!rIdFound) {
          let org = this.organizations.find((o) => o.id == e.recipient);
          recipients.push(org);
        }
      });
      return recipients;
    },
  },
  watch: {
    tab() {
      this.loadExchanges();
    },
  },
  methods: {
    selectAll(recipient) {
      this.rowsSelected.ids = this.exchanges
        .filter((e) => e.recipient == recipient.id)
        .map((e) => e.id);
    },
    openExchange(event) {
      this.$router.push({
        name: "document",
        params: { document_id: event.event.document_id },
      });
    },
    toggleAll() {
      if (this.rowsSelected.ids.length == 0) {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      } else if (this.rowsSelected.ids.length == this.exchanges.length) {
        this.rowsSelected.ids = [];
      } else {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      }
    },
    createDocument(nature) {
      let firstId = this.rowsSelected.ids[0];
      let firstExchange = this.exchanges.find((r) => r.id == firstId);
      let isSameRecipient = true;
      let isDocOrphan = true;
      this.rowsSelected.ids.forEach((rowId) => {
        let exchange = this.exchanges.find((r) => r.id == rowId);
        if (exchange.recipient != firstExchange.recipient) {
          isSameRecipient = false;
          return;
        }
        if (exchange[`${nature}`]) {
          isDocOrphan = false;
          return;
        }
      });
      if (!isSameRecipient) {
        window.alert("Les échanges ne sont pas pour le même destinataire");
        return;
      }
      if (!isDocOrphan) {
        window.alert("Un échange est déjà sur un document de même nature");
        return;
      }
      let payload = {
        official_nature: nature,
        emitter_id: firstExchange.emitter,
        recipient_id: firstExchange.recipient,
        acted_date: moment().format("YYYY-MM-DD"),
      };
      var promCreations = [];
      var i = 1;
      let promDoc = this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.rowsSelected.ids.forEach((rowId) => {
            let exchange = this.exchanges.find((r) => r.id == rowId);
            let payloadRow = {
              document: result.data.id,
              position: i++,
            };
            payloadRow["exchange_id"] = exchange.id;
            let payloadExchange = {};
            payloadExchange[nature] = result.data.id;
            let promEx = this.axios
              .patch(`/api/exchanges/${exchange.id}.json`, payloadExchange)
              .then(() => {
                let promiseRow = this.axios.post(
                  `/api/doc_rows.json`,
                  payloadRow
                );
                promCreations.push(promiseRow);
              });
            promCreations.push(promEx);
          });
          this.$emit("new-document", result.data);
          return result;
        });

      promCreations.push(promDoc);
      setTimeout(() => {
        Promise.all(promCreations).then(() => {
          promDoc.then((resultDoc) => {
            this.$router.push({
              name: "document",
              params: { document_id: resultDoc.data.id },
            });
          });
        });
      }, 200);
    },
    loadExchanges(route) {
      this.tab = parseInt(route.query.tab) || 0;
      this.rowsSelected = {
        ids: [],
      };
      this.filters = this.tabs[this.tab].filters;
      this.axios.get(`/api/exchanges.json?${this.filters}`).then((result) => {
        this.exchanges = result.data;
        this.events = [];
        this.exchanges.forEach((exchange) => {
          if (this.tab == 2 || this.tab == 5) {
            if (exchange.delivery_supposed_date) {
              let articleName = "";
              let article = this.articles.find((a) => a.id == exchange.article);
              if (article) {
                articleName = article.name;
              }
              let event = {
                name: articleName,
                start: exchange.delivery_supposed_date,
                end: exchange.delivery_supposed_date,
                exchange: exchange,
                timed: false,
              };
              this.events.push(event);
            }
          }
        });
      });
    },
    loadCounters() {},
  },
  mounted() {
    this.loadExchanges(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadExchanges(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
