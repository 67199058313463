<template>
  <v-dialog v-model="selectDialog" width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small primary text v-bind="attrs" v-on="on">
        Sélectionner un élément
      </v-btn>
    </template>
    <v-card class="pa-3">
      <v-toolbar dense flat>
        <ArticlePicker v-model="articleId" hide-details dense />
      </v-toolbar>
      <div class="d-flex">
        <span class="overline"> Fournisseur </span>
        <v-spacer></v-spacer>
        <span class="overline"> Client </span>
      </div>
      <v-toolbar dense flat class="ma-0">
        <v-btn-toggle dense v-model="sourceState">
          <v-btn x-small value="void"> - </v-btn>
          <v-btn x-small value="proposal_acted_date" color="blue">
            Proposé
          </v-btn>
          <v-btn x-small value="order_acted_date" color="red"> Commandé </v-btn>
          <v-btn x-small value="delivery_acted_date" color="yellow">
            Livré
          </v-btn>
          <v-btn x-small value="invoice_acted_date" color="green">
            Facturé
          </v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <v-btn-toggle dense v-model="destinationState">
          <v-btn x-small value="void"> - </v-btn>
          <v-btn x-small value="proposal_acted_date" color="blue">
            Proposé
          </v-btn>
          <v-btn x-small value="order_acted_date" color="red"> Commandé </v-btn>
          <v-btn x-small value="delivery_acted_date" color="yellow">
            Livré
          </v-btn>
          <v-btn x-small value="invoice_acted_date" color="green">
            Facturé
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <table class="fo-table">
        <thead>
          <tr>
            <!-- <th>Etat</th> -->
            <th>Source</th>
            <th>Quantité</th>
            <th>Article</th>
            <th>Référence</th>
            <th>Destination</th>
            <th>Org.</th>
          </tr>
        </thead>
        <tbody>
          <ElementItem
            v-for="element in filteredElements"
            :key="`element-${element.id}`"
            :element="element"
            :showDestOrg="true"
          >
            <v-btn small @click="selectElement(element)"> Sélectionner </v-btn>
          </ElementItem>
        </tbody>
      </table>
      <v-alert v-if="filteredElements.length == 0">
        Aucun élément pour le moment
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
// import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import ElementItem from "./ElementItem.vue";
import ArticlePicker from "../articles/ArticlePicker.vue";
export default {
  components: {
    ElementItem,
    ArticlePicker,
  },
  inheritAttrs: true,
  props: {
    value: {},
    canCreate: {},
    articleId: {},
    exchangeId: {},
  },
  data: () => ({
    sourceState: "void",
    destinationState: "void",
    selectDialog: false,
    searchTime: null,
    isLoading: false,
    items: [],
    selectedItems: [],
    searchInput: "",
    idsSelected: [],
    isThirdParty: true,
    filteredElements: [],
  }),
  computed: {
    ...mapGetters({
      elements: "stocks/elements",
      mapArticles: "articles/mapArticles",
      tags: "tags/mapTags",
    }),
  },
  watch: {
    articleId() {
      this.filterElements();
    },
    sourceState() {
      this.filterElements();
    },
    destinationState() {
      this.filterElements();
    },
    selectDialog(value) {
      if (value) {
        if (this.$route.query?.select_element != "true") {
          this.$router.push({
            query: { exchange_id: this.exchangeId, select_element: true },
          });
        }
        this.filterElements();
      } else {
        this.$router.push({
          query: { exchange_id: this.exchangeId },
        });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (this.$route.query?.select_element == "true") {
      this.selectDialog = true;
    } else {
      this.selectDialog = false;
    }
  },
  mounted() {
    if (this.$route.query?.select_element == "true") {
      this.selectDialog = true;
    } else {
      this.selectDialog = false;
    }
  },
  methods: {
    ...mapActions({
      createOrganization: "stocks/create",
    }),
    selectElement(element) {
      this.$emit("input", element.id);
      this.selectDialog = false;
    },
    filterElements() {
      this.filteredElements = [].concat(this.elements);
      if (this.articleId) {
        this.filteredElements = this.filteredElements.filter(
          (e) => e.article_id == this.articleId
        );
      }
      if (this.sourceState) {
        if (this.sourceState == "void") {
          this.filteredElements = this.filteredElements.filter(
            (e) =>
              !e.source ||
              (!e.source["proposal_acted_date"] &&
                !e.source["order_acted_date"])
          );
        } else {
          this.filteredElements = this.filteredElements.filter(
            (e) => e.source && e.source[this.sourceState]
          );
        }
      }
      if (this.destinationState) {
        if (this.destinationState == "void") {
          this.filteredElements = this.filteredElements.filter(
            (e) =>
              !e.destination ||
              (!e.destination["proposal_acted_date"] &&
                !e.destination["order_acted_date"])
          );
        } else {
          this.filteredElements = this.filteredElements.filter(
            (e) => e.destination && e.destination[this.destinationState]
          );
        }
      }
    },
  },
};
</script>
