var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-btn',{attrs:{"text":"","to":{
        name: 'docs-purchases-lists',
        params: { nature: 'proposal' },
        query: { nature: 'proposal', direction: 'provider' },
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("request_quote")]),_vm._v(" Propositions ")],1),_c('v-btn',{attrs:{"text":"","to":{
        name: 'docs-purchases-lists',
        params: { nature: 'order' },
        query: { nature: 'order', direction: 'provider' },
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("settings_suggest")]),_vm._v(" Commandes ")],1),_c('v-btn',{attrs:{"text":"","to":{
        name: 'docs-purchases-lists',
        params: { nature: 'delivery' },
        query: { nature: 'delivery', direction: 'provider' },
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("local_shipping")]),_vm._v(" Livraisons ")],1),_c('v-btn',{attrs:{"text":"","to":{
        name: 'docs-purchases-lists',
        params: { nature: 'invoice' },
        query: { nature: 'invoice', direction: 'provider' },
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("receipt")]),_vm._v(" Factures ")],1),_c('v-btn',{attrs:{"text":"","to":{ name: 'late-invoices', params: { direction: 'provider' } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("assignment_late")]),_vm._v(" Retards ")],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }