<template>
  <v-container fluid v-if="messages">
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <v-card-title>Messages</v-card-title>
        <div>
          <v-card
            v-for="message in messages"
            :key="`message-${message.id}`"
            class="ma-2 pa-2"
            :color="message.read_at ? '' : 'blue lighten-4'"
          >
            <div class="caption">
              {{ message.updated_at | moment("HH:mm DD/MM/YYYY") }}
              <v-btn
                x-small
                text
                v-if="!message.read_at"
                color="primary"
                @click="markAsRead(message)"
                >Marquer comme lu</v-btn
              >
              <v-btn
                v-else
                x-small
                text
                color="primary"
                @click="markAsUnread(message)"
                >Marquer comme non lu</v-btn
              >
              <v-btn
                x-small
                text
                @click="deleteMessage({ messages, message })"
                color="red"
                >Supprimer</v-btn
              >
            </div>
            <MessageItem :message="message"></MessageItem>
          </v-card>
        </div>
        <v-alert v-if="messages.length == 0">
          Aucun message pour le moment
        </v-alert>
        <v-btn @click="loadMore()" v-if="hasNext">Charger plus</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MessageItem from "./MessageItem.vue";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {
    MessageItem,
  },
  data: () => ({
    messages: null,
    offset: 0,
    hasNext: null,
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      markAsRead: "messages/markAsRead",
      markAsUnread: "messages/markAsUnread",
      deleteMessage: "messages/deleteMessage",
      loadCount: "messages/loadCount",
    }),
    loadMore() {
      this.offset += 20;
      this.axios
        .get(`/api/messages.json?offset=${this.offset}&limit=20`)
        .then((result) => {
          this.hasNext = result.data.next ? true : false;
          this.messages = this.messages.concat(result.data.results);
        });
    },
    filter() {
      this.axios
        .get(`/api/messages.json?offset=${this.offset}&limit=20`)
        .then((result) => {
          this.hasNext = result.data.next ? true : false;
          this.messages = result.data.results;
        });
    },
  },
  mounted() {
    this.offset = 0;
    this.loadCount();
    this.filter();
  },
};
</script>
<style lang="scss" scoped>
</style>
