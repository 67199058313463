
<template>
  <tr
    v-if="exchange"
    :class="`exchange ${
      exchange[document.official_nature] != document.id ? 'out' : ''
    }
      ${$route.query.exchange_id == exchange.id ? 'show' : ''}`"
  >
    <td class="">
      <v-checkbox
        v-model="rowsSelected.ids"
        dense
        x-small
        class="ma-0 pa-0"
        hide-details
        :value="row.id"
      ></v-checkbox>
    </td>
    <td class="drag">
      <v-btn small icon v-if="isEditable"
        ><v-icon small>drag_indicator</v-icon></v-btn
      >
      <v-icon v-if="!isEditable">lock</v-icon>
    </td>
    <td :class="`states ${colorNature} pa-2`">
      <span v-if="exchange[document.official_nature] != document.id">
        Dans un autre document pour le même état
      </span>
      <v-chip
        x-small
        v-if="exchange.invoice"
        class="exchange-stage"
        :color="`${exchange.invoice_acted_date ? 'green' : 'green lighten-3'}`"
        :to="{
          name: 'document',
          params: { document_id: exchange.invoice },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">receipt</v-icon>
        <span v-if="exchange.invoice_acted_date" class="text--green">
          Facturé {{ exchange.invoice_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Facturation</div>
      </v-chip>
      <v-chip
        v-if="exchange.delivery"
        x-small
        class="exchange-stage"
        :color="`${
          exchange.delivery_acted_date ? 'yellow' : 'yellow lighten-3'
        }`"
        :to="{
          name: 'document',
          params: { document_id: exchange.delivery },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">local_shipping</v-icon>
        <span v-if="exchange.delivery_acted_date" class="text--green">
          Livré {{ exchange.delivery_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Livraison</div>
      </v-chip>
      <v-chip
        v-if="exchange.order"
        x-small
        class="exchange-stage"
        :color="`${exchange.order_acted_date ? 'red' : 'red lighten-3'}`"
        :to="{
          name: 'document',
          params: { document_id: exchange.order },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">settings_suggest</v-icon>
        <span v-if="exchange.order_acted_date" class="text--green">
          Commandé {{ exchange.order_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Commande</div>
      </v-chip>
      <v-chip
        v-if="exchange.proposal"
        x-small
        class="exchange-stage"
        :color="`${exchange.proposal_acted_date ? 'blue' : 'blue lighten-3'}`"
        :to="{
          name: 'document',
          params: { document_id: exchange.proposal },
          query: { exchange_id: exchange.id },
        }"
      >
        <v-icon x-small class="mr-2">request_quote</v-icon>
        <span v-if="exchange.proposal_acted_date" class="text--green">
          Proposé {{ exchange.proposal_acted_date | moment("DD/MM/YYYY") }}
        </span>
        <div v-else>Proposition</div>
      </v-chip>
    </td>
    <td
      class="article"
      v-if="
        user && user.beta_access && document && !document.emitter.is_third_party
      "
    >
      <div v-if="articleObj && articleObj.is_stockable">
        <table class="fo-table" style="width: 100%">
          <thead>
            <tr>
              <!-- <th>Etat</th> -->
              <th>Source</th>
              <th>P.A.</th>
              <th>Quantité</th>
              <th>Référence</th>
              <th>P.V.</th>
              <th style="width: 30px"></th>
            </tr>
          </thead>
          <tbody>
            <ElementItem
              v-for="element in exchange.destination_elements"
              :key="`element-${element.id}`"
              :element="element"
              :showDestOrg="true"
              :hideArticle="true"
              :showPrices="true"
              :hideDestination="true"
            >
              <v-btn
                icon
                small
                @click="
                  patchElement(element, {
                    destination_id: null,
                    is_available: element.source ? true : false,
                  })
                "
                ><v-icon>clear</v-icon></v-btn
              >
            </ElementItem>
          </tbody>
        </table>

        <v-dialog v-model="dialogSource" width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small primary v-bind="attrs" v-on="on">
              <v-icon small>add</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-3">
            <v-row>
              <v-col>
                <v-text-field v-model="uniqueReference" label="Référence">
                </v-text-field>
                <v-text-field v-model="elementAmount" label="Prix d'achat">
                </v-text-field>
                <v-text-field v-model="elementQuantity" label="Quantité">
                </v-text-field>
                <OrganizationPicker
                  v-model="elementOrgaDest"
                  label="Revendu à"
                  clearable
                ></OrganizationPicker>
                <v-btn @click="createElement()">Créer</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <!-- <ElementPicker
        v-model="elementSelected"
        @input="selectElement"
      ></ElementPicker> -->
        <FullElPicker
          :exchangeId="exchange.id"
          v-model="elementSelected"
          :articleId="exchange.article"
          @input="selectElement"
        ></FullElPicker>
        <div class="imputations">
          <div
            v-for="providing_exchange in exchange.provider_imputations"
            :key="`pe-${providing_exchange.id}`"
          >
            {{
              organizations.find((o) => o.id == providing_exchange.emitter).name
            }}
            fournit {{ providing_exchange.quantity }} sur
            <router-link
              v-if="providing_exchange.invoice"
              :to="{
                name: 'document',
                params: { document_id: providing_exchange.invoice },
                query: { exchange_id: providing_exchange.id },
              }"
            >
              la facture
            </router-link>
            <router-link
              v-else-if="providing_exchange.delivery"
              :to="{
                name: 'document',
                params: { document_id: providing_exchange.delivery },
                query: { exchange_id: providing_exchange.id },
              }"
            >
              la livraison
            </router-link>
            <router-link
              v-else-if="providing_exchange.order"
              :to="{
                name: 'document',
                params: { document_id: providing_exchange.order },
                query: { exchange_id: providing_exchange.id },
              }"
            >
              la commande
            </router-link>
            <router-link
              v-else-if="providing_exchange.proposal"
              :to="{
                name: 'document',
                params: { document_id: providing_exchange.proposal },
                query: { exchange_id: providing_exchange.id },
              }"
            >
              la proposition
            </router-link>
          </div>
        </div>
      </div>
    </td>
    <!-- <td class="date">
      <FoDatePicker
        v-model="exchange[`${document.official_nature}_acted_date`]"
        dense
        hide-details
        clearable
        :disabled="!isEditable"
        @input="
          patchExchange(
            `${document.official_nature}_acted_date`,
            exchange[`${document.official_nature}_acted_date`]
          )
        "
      ></FoDatePicker>
    </td> -->
    <!-- <td class="date">
      <FoDatePicker
        v-model="exchange[`delivery_supposed_date`]"
        dense
        hide-details
        clearable
        @input="
          patchExchange(
            `delivery_supposed_date`,
            exchange[`delivery_supposed_date`]
          )
        "
      ></FoDatePicker>
    </td> -->
    <td class="article">
      <v-chip
        x-small
        v-for="group in exchange.groups"
        :key="`gr-${group.id}`"
        :to="{ name: 'group', params: { group_id: group.id } }"
      >
        <span class="grey--text" v-if="mapNatures[group.nature_id]"
          >{{ mapNatures[group.nature_id].name }}&nbsp;</span
        >
        {{ group.name }}
      </v-chip>
      <div class="internal">
        <TipTap
          v-if="isEditable"
          v-model="exchange.internal_note"
          @input="saveTextField('internal_note', ...arguments)"
          placeholder="Note privée..."
        />
        <div
          v-else
          v-html="exchange.internal_note ? exchange.internal_note : '-'"
        ></div>
      </div>
    </td>
    <td class="article">
      <div class="article-select d-flex">
        <!-- <ArticlePicker
          v-model="exchange.article"
          :disabled="!isEditable"
          :can-create="true"
          @change="selectArticle()"
          hide-details
          dense
        /> -->
        <ArticleSelect
          v-model="exchange.article"
          :can-edit="isEditable"
          :can-create="true"
          @change="selectArticle()"
          hide-details
          dense
          :recipientId="document.recipient.id"
          :emitterId="document.emitter.id"
        />
        <v-btn
          icon
          v-if="exchange.article"
          @click="
            $router.push({
              name: 'article',
              params: { article_id: exchange.article },
            })
          "
          ><v-icon>open_in_new</v-icon></v-btn
        >
      </div>
      <div class="description">
        <TipTap
          v-if="isEditable"
          v-model="exchange.description"
          @input="saveTextField('description', ...arguments)"
          placeholder="Description visible..."
        />
        <div
          v-else
          v-html="exchange.description ? exchange.description : '-'"
        ></div>
      </div>
    </td>
    <!-- <td class="numbers" v-if="document && !document.emitter.is_third_party">
      {{ exchange.margin_amount }}
    </td>
    <td class="numbers" v-if="document && !document.emitter.is_third_party">
      {{ Math.round(exchange.margin_ratio * 10000, 2) / 100 }}%
    </td> -->
    <td class="numbers">
      <input
        v-if="isEditable"
        v-model="exchange.quantity"
        @blur="changeQuantity()"
        @keypress.enter="changeQuantity()"
      />
      <div v-else class="caption text-center">
        {{ exchange.quantity ? exchange.quantity : "-" }}
      </div>
    </td>
    <td class="numbers">
      <div v-if="isEditable" class="d-flex">
        <PricePicker
          class="pa-3"
          v-if="exchange.article"
          v-model="exchange.unit_gross_amount"
          :can-create="true"
          @input="changeUnitGrossAmount()"
          :articleId="exchange.article"
          :recipientId="document.recipient.id"
          :emitterId="document.emitter.id"
        />
        <input
          v-model="exchange.unit_gross_amount"
          @blur="changeUnitGrossAmount()"
          @keypress.enter="changeUnitGrossAmount()"
        />
      </div>
      <div v-else class="caption text-center">
        {{ exchange.unit_gross_amount ? exchange.unit_gross_amount : "-" }}
      </div>
    </td>
    <td class="numbers bold">
      <input
        v-if="isEditable"
        v-model="exchange.gross_amount"
        @blur="changeGrossAmount()"
        @keypress.enter="changeGrossAmount()"
      />
      <div v-else class="caption text-center">
        <strong>{{
          exchange.gross_amount ? exchange.gross_amount : "-"
        }}</strong>
      </div>
    </td>
    <!-- <td class="numbers"></td> -->
    <td class="numbers">
      <input
        v-if="isEditable"
        v-model="exchange.tax_ratio"
        @blur="changeTaxRatio()"
        @keypress.enter="changeTaxRatio()"
      />
      <div v-else class="caption text-center">
        {{ exchange.tax_ratio ? exchange.tax_ratio : "-" }}
      </div>
    </td>
    <td class="numbers">
      <input
        v-if="isEditable"
        v-model="exchange.net_amount"
        @blur="changeNetAmount()"
        @keypress.enter="changeNetAmount()"
      />
      <div v-else class="caption text-center">
        {{ exchange.net_amount ? exchange.net_amount : "-" }}
      </div>
    </td>
    <td
      class="article"
      v-if="
        user && user.beta_access && document && document.emitter.is_third_party
      "
    >
      <div v-if="articleObj && articleObj.is_stockable">
        <table class="fo-table">
          <thead>
            <tr>
              <!-- <th>Etat</th> -->
              <th>P.A.</th>
              <th>Quantité</th>
              <th>Référence</th>
              <th>P.V.</th>
              <th>Destination</th>
              <th>Org.</th>
            </tr>
          </thead>
          <tbody>
            <ElementItem
              v-for="element in exchange.source_elements"
              :key="`element-${element.id}`"
              :element="element"
              :showDestOrg="true"
              :hideArticle="true"
              :showPrices="true"
              :hideSource="true"
            >
              <v-btn
                icon
                small
                @click="
                  patchElement(element, {
                    source_id: null,
                    is_available: false,
                  })
                "
                ><v-icon>clear</v-icon></v-btn
              >
            </ElementItem>
          </tbody>
        </table>
        <v-dialog v-model="dialogSource" width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small primary text v-bind="attrs" v-on="on">
              <v-icon small>add</v-icon> Créer un élément
            </v-btn>
          </template>
          <v-card class="pa-3">
            <v-row>
              <v-col>
                <v-text-field v-model="uniqueReference" label="Référence">
                </v-text-field>
                <v-text-field v-model="elementAmount" label="Prix de vente">
                </v-text-field>
                <v-text-field v-model="elementQuantity" label="Quantité">
                </v-text-field>
                <OrganizationPicker
                  v-model="elementOrgaDest"
                  label="Revendu à"
                  clearable
                ></OrganizationPicker>
                <v-btn @click="createElement()">Créer</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <!-- <ElementPicker
        v-model="elementSelected"
        @input="selectElement"
      ></ElementPicker> -->

        <FullElPicker
          :exchangeId="exchange.id"
          v-model="elementSelected"
          :articleId="exchange.article"
          @input="selectElement"
        ></FullElPicker>
        <div v-if="destinationOrganization">
          Refacturé à {{ destinationOrganization.name }} sur
          <router-link
            v-if="exchange.consuming_exchange.invoice"
            :to="{
              name: 'document',
              params: { document_id: exchange.consuming_exchange.invoice },
              query: { exchange_id: exchange.consuming_exchange.id },
            }"
          >
            la facture
          </router-link>
          <router-link
            v-else-if="exchange.consuming_exchange.delivery"
            :to="{
              name: 'document',
              params: { document_id: exchange.consuming_exchange.delivery },
              query: { exchange_id: exchange.consuming_exchange.id },
            }"
          >
            la livraison
          </router-link>
          <router-link
            v-else-if="exchange.consuming_exchange.order"
            :to="{
              name: 'document',
              params: { document_id: exchange.consuming_exchange.order },
              query: { exchange_id: exchange.consuming_exchange.id },
            }"
          >
            la commande
          </router-link>
          <router-link
            v-else-if="exchange.consuming_exchange.proposal"
            :to="{
              name: 'document',
              params: { document_id: exchange.consuming_exchange.proposal },
              query: { exchange_id: exchange.consuming_exchange.id },
            }"
          >
            la proposition
          </router-link>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
// import ArticlePicker from "../articles/ArticlePicker.vue";
import ArticleSelect from "../articles/ArticleSelect.vue";
import PricePicker from "../articles/PricePicker.vue";
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
import _ from "lodash";
// import FoDatePicker from "../FoDatePicker.vue";
// import ElementPicker from "../stocks/ElementPicker.vue";
import FullElPicker from "../stocks/FullElPicker.vue";
import ElementItem from "../stocks/ElementItem.vue";
import TipTap from "../tiptap/TipTap.vue";
export default {
  components: {
    // ArticlePicker,
    // FoDatePicker,
    // ElementPicker,
    PricePicker,
    FullElPicker,
    OrganizationPicker,
    ElementItem,
    TipTap,
    ArticleSelect,
  },
  props: {
    row: {},
    exchange: {},
    rowsSelected: {},
    document: {},
  },
  data: () => ({
    dialogSource: false,
    articleRessources: null,
    uniqueReference: "",
    elementAmount: 0,
    elementQuantity: 1,
    elementOrgaDest: null,
    timeOutSave: null,
  }),
  watch: {
    exchange() {
      this.elementAmount = this.exchange.unit_gross_amount;
    },
  },
  mounted() {
    this.elementAmount = this.exchange.unit_gross_amount;
    this.elementOrgaDest = this.document.recipient_id;
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      articles: "articles/articles",
      organizations: "organizations/organizations",
      mapNatures: "groups/mapNatures",
    }),
    articleObj() {
      if (this.exchange.article) {
        return this.articles.find((a) => a.id == this.exchange.article);
      } else {
        return null;
      }
    },
    colorNature() {
      if (this.exchange.invoice) {
        return !this.exchange["invoice_acted°date"]
          ? "green lighten-4"
          : "green";
      } else if (this.exchange.delivery) {
        return !this.exchange["delivery_acted_date"]
          ? "yellow lighten-4"
          : "yellow";
      } else if (this.exchange.order) {
        return !this.exchange["order_acted_date"] ? "red lighten-4" : "red";
      } else if (this.exchange.proposal) {
        return !this.exchange["proposal_acted_date"]
          ? "blue lighten-4"
          : "blue";
      }
      return "Inconnu";
    },
    destinationOrganization() {
      return this.exchange.destination
        ? this.organizations.find((e) => e.id == this.exchange.destination)
        : null;
    },
    isEditable() {
      if (!this.document || this.document.is_closed) {
        return false;
      } else if (
        this.exchange.stage == "delivery" &&
        ["invoice"].includes(this.document.official_nature)
      ) {
        return true;
      } else if (
        this.exchange.stage == "order" &&
        ["invoice", "delivery"].includes(this.document.official_nature)
      ) {
        return true;
      } else if (
        this.exchange.stage == "proposal" &&
        ["invoice", "delivery", "order"].includes(this.document.official_nature)
      ) {
        return true;
      } else if (this.exchange.stage == "draft") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      patchElementStore: "stocks/patch",
      createElementStore: "stocks/create",
    }),
    createElement() {
      let payload = {
        article_id: this.exchange.article,
        unique_reference: this.uniqueReference,
        is_available: false,
        quantity: this.elementQuantity,
        orga_dest_id: this.elementOrgaDest,
      };
      if (this.document.emitter.is_third_party) {
        payload["source_id"] = this.exchange.id;
        payload["buying_amount"] = this.elementAmount;
        if (
          this.exchange.delivery_acted_date &&
          !this.exchange.destination_id
        ) {
          payload["is_available"] = true;
        }
      } else {
        payload["destination_id"] = this.exchange.id;
        payload["selling_amount"] = this.elementAmount;
        if (this.exchange.delivery_acted_date) {
          payload["is_available"] = false;
        }
      }
      this.createElementStore(payload).finally(() => {
        this.updateAfterChange();
      });
    },
    selectElement(element) {
      let payload = {};
      if (this.document.emitter.is_third_party) {
        payload = {
          source_id: this.exchange.id,
          is_available: true,
          buying_amount: this.elementAmount,
        };
      } else {
        payload = {
          destination_id: this.exchange.id,
          is_available: false,
          selling_amount: this.elementAmount,
        };
      }
      this.patchElementStore({ id: element, payload: payload }).finally(() => {
        this.updateAfterChange();
      });
    },
    patchElement(element, payload) {
      this.patchElementStore({ id: element.id, payload: payload }).finally(
        () => {
          this.updateAfterChange();
        }
      );
    },
    selectArticle() {
      let payload = {
        article: this.exchange.article,
      };
      if (this.exchange.article) {
        let article = this.articles.find((a) => a.id == this.exchange.article);
        if (!this.exchange.description) {
          this.exchange.description = payload["description"] =
            article.description;
        }
        if (!this.exchange.internal_note) {
          this.exchange.internal_note = payload["internal_note"] =
            article.internal_note;
        }
        if (!this.exchange.unit_gross_amount) {
          let prices = _.sortBy(
            article.prices.filter(
              (p) => p.direction == this.exchange.direction
            ),
            ["quantity_min", "unit_gross_amount"]
          );
          prices.forEach((price) => {
            if (this.exchange.quantity >= price.quantity_min) {
              this.exchange.unit_gross_amount = payload["unit_gross_amount"] =
                price.unit_gross_amount;
              return;
            }
          });
        }
      }
      this.patchPayloadExchange(payload).then(() => {
        this.changeUnitGrossAmount();
      });
    },
    updateAfterChange() {
      this.$emit("update-amounts");
    },
    removeExchange() {
      this.$emit("delete-exchange");
      this.updateAfterChange();
    },
    deleteImputation(imputations, imputation) {
      if (window.confirm("Supprimer ?")) {
        this.axios
          .delete(`/api/imputations/${imputation.id}.json`)
          .then(() => {
            imputations.splice(imputations.indexOf(imputation), 1);
            this.axios
              .get(`/api/exchanges/${this.exchange.id}.json`)
              .then((result) => {
                this.exchange.margin_amount = result.data.margin_amount;
                this.exchange.margin_ratio = result.data.margin_ratio;
                this.exchange.quantity_provided = result.data.quantity_provided;
              });
          })
          .catch(() => {
            window.alert("Changement non enregistré");
          });
      }
    },
    parseInputData(field) {
      if (!this.exchange[field]) {
        this.exchange[field] = "0";
      }
      if (typeof this.exchange[field] === "string") {
        this.exchange[field] = this.exchange[field].replace(",", ".");
      }
      this.exchange[field] = eval(this.exchange[field]);
    },
    changeUnitGrossAmount() {
      let payload = {};
      this.parseInputData("unit_gross_amount");
      payload["unit_gross_amount"] = this.exchange.unit_gross_amount;
      this.exchange.gross_amount = payload["gross_amount"] =
        Math.round(
          this.exchange.quantity * this.exchange.unit_gross_amount * 100
        ) / 100;
      this.exchange.net_amount = payload["net_amount"] =
        Math.round(
          this.exchange.gross_amount * (1 + this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.exchange.tax_amount = payload["tax_amount"] =
        Math.round(
          this.exchange.gross_amount * (this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.axios
        .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
        .then((result) => {
          this.exchange.margin_amount = result.data.margin_amount;
          this.exchange.margin_ratio = result.data.margin_ratio;
          this.exchange.quantity_provided = result.data.quantity_provided;
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
    changeQuantity() {
      let payload = {};
      this.parseInputData("quantity");
      payload["quantity"] = this.exchange.quantity;
      this.exchange.gross_amount = payload["gross_amount"] =
        this.exchange.quantity * this.exchange.unit_gross_amount;
      this.exchange.net_amount = payload["net_amount"] =
        Math.round(
          this.exchange.gross_amount * (1 + this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.exchange.tax_amount = payload["tax_amount"] =
        Math.round(
          this.exchange.gross_amount * (this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.axios
        .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
        .then((result) => {
          this.exchange.margin_amount = result.data.margin_amount;
          this.exchange.margin_ratio = result.data.margin_ratio;
          this.exchange.quantity_provided = result.data.quantity_provided;
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
    changeGrossAmount() {
      let payload = {};
      this.parseInputData("gross_amount");
      payload["gross_amount"] = this.exchange.gross_amount;
      this.exchange.unit_gross_amount = payload["unit_gross_amount"] =
        Math.round(
          (this.exchange.gross_amount / this.exchange.quantity) * 100
        ) / 100;
      this.exchange.net_amount = payload["net_amount"] =
        Math.round(
          this.exchange.gross_amount * (1 + this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.exchange.tax_amount = payload["tax_amount"] =
        Math.round(
          this.exchange.gross_amount * (this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.axios
        .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
        .then((result) => {
          this.exchange.margin_amount = result.data.margin_amount;
          this.exchange.margin_ratio = result.data.margin_ratio;
          this.exchange.quantity_provided = result.data.quantity_provided;
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
    changeTaxRatio() {
      let payload = {};
      this.parseInputData("tax_ratio");
      payload["tax_ratio"] = this.exchange.tax_ratio;
      this.exchange.net_amount = payload["net_amount"] =
        Math.round(
          this.exchange.gross_amount * (1 + this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.exchange.tax_amount = payload["tax_amount"] =
        Math.round(
          this.exchange.gross_amount * (this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.axios
        .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
    changeNetAmount() {
      let payload = {};
      this.parseInputData("net_amount");
      payload["net_amount"] = this.exchange.net_amount;
      this.exchange.gross_amount = payload["gross_amount"] =
        Math.round(
          (this.exchange.net_amount / (1 + this.exchange.tax_ratio / 100)) * 100
        ) / 100;
      this.exchange.unit_gross_amount = payload["unit_gross_amount"] =
        Math.round(
          (this.exchange.gross_amount / this.exchange.quantity) * 100
        ) / 100;
      this.exchange.tax_amount = payload["tax_amount"] =
        Math.round(
          this.exchange.gross_amount * (this.exchange.tax_ratio / 100) * 100
        ) / 100;
      this.axios
        .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
    patchPayloadExchange(payload) {
      return this.axios
        .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
    saveTextField(field, value) {
      clearTimeout(this.timeOutSave);
      this.timeOutSave = setTimeout(() => {
        let payload = {};
        payload[field] = value;
        this.axios
          .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
          .catch(() => {
            window.alert("Changement non enregistré");
          });
      }, 300);
    },
    patchExchange(field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/exchanges/${this.exchange.id}.json`, payload)
        .then((result) => {
          this.exchange[field] = result.data[field];
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
    patchImputation(imputation, field, value) {
      let payload = {};
      payload[field] = value;
      this.axios
        .patch(`/api/imputations/${imputation.id}.json`, payload)
        .then((result) => {
          imputation[field] = result.data[field];
          this.axios
            .get(`/api/exchanges/${this.exchange.id}.json`)
            .then((result) => {
              this.exchange.margin_amount = result.data.margin_amount;
              this.exchange.margin_ratio = result.data.margin_ratio;
              this.exchange.quantity_provided = result.data.quantity_provided;
            });
        })
        .catch(() => {
          window.alert("Changement non enregistré");
        })
        .finally(() => {
          this.updateAfterChange();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.element {
  display: block;
}
.exchange {
  font-size: 0.9em;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  &.out {
    background-color: rgb(255, 193, 193);
  }
  &.show {
    background-color: rgb(234, 251, 255);
  }
  .states {
    min-width: 140px;
    .exchange-stage {
      display: block;
      font-size: 0.7em;
      &.router-link-active {
        font-weight: bold;
      }
    }
  }
  .article-select {
    border: 2px solid #ccc;
    margin: 4px;
  }
  .description {
    border: 2px solid #ccc;
    margin: 4px;
  }
  .internal {
    border: 2px solid #ccc;
    margin: 4px;
    background-color: rgb(255, 255, 200);
  }

  .date {
    width: 100px !important;
    min-width: 100px !important;
  }
  .numbers {
    font-size: 1em;
    width: 80px;
    min-width: 80px;
    text-align: right;
    border-right: 1px solid #eee;
    padding: 4px;
    &.bold input {
      font-weight: bold;
    }
    .caption {
      font-size: 1em !important;
    }
    input {
      width: 100%;
      min-width: 90px;
      display: block;
      text-align: right;
      color: #222;
      padding: 8px 4px;
      border: 2px solid #ccc;
    }
  }
}
.imputations {
  background-color: #f9f9f9;
}
</style>

