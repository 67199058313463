/* eslint no-console: "off" */
/* eslint-disable no-debugger */
import axios from "axios";
import router from "../../router.js";
import Cookies from "js-cookie";

const state = {
  user: null,
  previousRoute: null,
  gotUnauthorizedResponse: false,
  gotNotFoundResponse: false,
};
const getters = {
  user(state) {
    return state.user;
  },
  gotUnauthorizedResponse(state) {
    return state.gotUnauthorizedResponse;
  },
  gotNotFoundResponse(state) {
    return state.gotNotFoundResponse;
  },
};
const actions = {
  setUnauthorizedResponse({ state }, value) {
    state.gotUnauthorizedResponse = value;
  },
  setNotFoundResponse({ state }, value) {
    state.gotNotFoundResponse = value;
  },
  logout({ commit }) {
    return axios.post(`/auth/token/logout/`).then(() => {
      Cookies.remove("auth_token");
      axios.defaults.headers.common["Authorization"] = null;
      commit("setUser", null);
    });
  },
  login({ commit }, { username, password }) {
    return axios
      .post(`/auth/token/login/`, {
        username: username,
        password: password,
      })
      .then((result) => {
        Cookies.set("auth_token", `Token ${result.data.auth_token}`);
        axios.defaults.headers.common["Authorization"] = Cookies.get(
          "auth_token"
        );
        return axios.get(`/auth/users/me.json`).then((result) => {
          commit("setUser", result.data);
          return axios.get(`/api/persons.json?user=${result.data.id}`).then((resultUser) => {
            commit("setPerson", resultUser.data.length > 0 ? resultUser.data[0] : null);
          });
        });
      });
  },
  gotoPrevious({ state }) {
    if (state.previousRoute) {
      router.push(state.previousRoute);
    } else {
      router.go("/");
    }
  },
  setPreviousRoute({ state }, previousRoute) {
    state.previousRoute = previousRoute;
  },
  autologin({ commit }) {
    return axios.get(`/auth/users/me.json`).then((result) => {
      commit("setUser", result.data);
      return axios.get(`/api/persons.json?user=${result.data.id}`).then((resultUser) => {
        commit("setUser", resultUser.data.length > 0 ? resultUser.data[0] : null);
      });
    });
  },
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
