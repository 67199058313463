/* eslint-disable no-debugger */
/* eslint-disable no-console */
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import store from "./store";

import LayoutApp from "./components/LayoutApp";
import LoginPage from "./components/authentication/LoginPage.vue";
import StartPage from "./components/start/StartPage.vue";
import OrganizationPage from "./components/organizations/OrganizationPage.vue";
import OrganizationsList from "./components/organizations/OrganizationsList.vue";
import ArticlePage from "./components/articles/ArticlePage.vue";
import ArticlesList from "./components/articles/ArticlesList.vue";
import DocumentPage from "./components/documents/DocumentPage.vue";
import DocumentsList from "./components/documents/DocumentsList.vue";
import ContractsList from "./components/contracts/ContractsList.vue";
import ExchangesFlow from "./components/exchanges/ExchangesFlow.vue";
import ExchangesList from "./components/exchanges/ExchangesList.vue";
import PaymentsList from "./components/payments/PaymentsList.vue";
import TagsList from "./components/tags/TagsList.vue";
import StocksList from "./components/stocks/StocksList.vue";
import ElementPage from "./components/stocks/ElementPage.vue";
import Purchases from "./components/documents/Purchases.vue";
import Sells from "./components/documents/Sells.vue";
import DocsList2 from "./components/documents/DocsList2.vue";
import OrderPrepPage from "./components/order-preparation/OrderPrepPage.vue";
import LateInvoices from "./components/documents/LateInvoices.vue";
import DocsExtraction from "./components/documents/DocsExtraction.vue";
import Indicators from "./components/documents/Indicators.vue";
import GroupPage from "./components/groups/GroupPage.vue";
import GroupsList from "./components/groups/GroupsList.vue";
import ExportsList from "./components/data/ExportsList.vue";
import ImportsList from "./components/data/ImportsList.vue";
import AutomationsList from "./components/data/AutomationsList.vue";
import AutomationPage from "./components/data/AutomationPage.vue";
import MessagesList from "./components/messages/MessagesList.vue";

import PagesList from "./components/pages/PagesList.vue";
import PagePage from "./components/pages/PagePage.vue";

const routes = [
  { path: "/login", component: LoginPage, name: "login" },
  {
    path: `/`,
    component: LayoutApp,
    children: [
      {
        path: "",
        name: 'home',
        component: StartPage
        // component: () => import('@/components/start/StartPage.vue')
      }, {
        path: "organizations",
        name: "organizations",
        component: OrganizationsList,
        children: [
          {
            path: "organizations/:organization_id",
            name: "organization",
            component: OrganizationPage
          }
        ]
      },

      {
        path: "pages",
        name: "pages",
        component: PagesList,
        children: [
          {
            path: "page/:page_id",
            name: 'page',
            component: PagePage
          }
        ]
      },

      {
        path: "indicators",
        name: "indicators",
        component: Indicators
      }, {
        path: "messages",
        name: "messages",
        component: MessagesList
      }, {
        path: "exports",
        name: "exports",
        component: ExportsList
      }, {
        path: "imports",
        name: "imports",
        component: ImportsList
      }, {
        path: "automations",
        name: "automations",
        component: AutomationsList
      }, {
        path: "automations/:automation_id",
        name: "automation",
        component: AutomationPage
      }, {
        path: "docs-extraction",
        name: "docs-extraction",
        component: DocsExtraction
      }, {
        path: "order-preparation",
        name: "order-preparation",
        component: OrderPrepPage
      }, {
        path: "late-invoices/:direction",
        name: "late-invoices",
        component: LateInvoices,
      },
      {
        path: "purchases",
        name: "purchases",
        component: Purchases,
        children: [
          {
            path: "lists/:nature",
            name: "docs-purchases-lists",
            component: DocsList2
          }
        ]
      },
      {
        path: "sells",
        name: "sells",
        component: Sells,
        children: [
          {
            path: "lists/:nature",
            name: "docs-sells-lists",
            component: DocsList2
          }
        ]
      }, {
        path: "all-documents",
        name: "documents-all",
        component: DocumentsList
      }, {
        path: "articles",
        name: "articles",
        component: ArticlesList,
      }, {
        path: "articles/:article_id",
        name: "article",
        component: ArticlePage
      }, {
        path: "stocks",
        name: "stocks",
        component: StocksList,
        children: [
          {
            path: 'elements/:element_id',
            name: 'element',
            component: ElementPage
          }
        ]
      }, {
        path: "contracts",
        name: "contracts",
        component: ContractsList
      }, {
        path: "documents/:document_id",
        name: "document",
        component: DocumentPage,
      }, {
        path: "groups",
        name: "groups",
        component: GroupsList
      },
      {
        path: 'groups/:group_id',
        name: 'group',
        component: GroupPage
      }, {
        path: "exchanges",
        name: "exchanges",
        component: ExchangesFlow
      }, {
        path: "transactions",
        name: "transactions",
        component: ExchangesList
      }, {
        path: "payments",
        name: "payments",
        component: PaymentsList
      }, {
        path: "tags",
        name: "tags",
        component: TagsList
      },
    ]
  },
];

let router = new VueRouter({ mode: "history", routes });

router.beforeEach((to, from, next) => {
  if (from.name != "login") {
    store.dispatch("user/setPreviousRoute", from);
  }
  next();
});

export default router;
