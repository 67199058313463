var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.exchange)?_c('tr',{staticClass:"exchange",class:{ exchange: true, orphan: _vm.isOrphan }},[(_vm.rowsSelected)?_c('td',{},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"dense":"","x-small":"","hide-details":"","value":_vm.exchange.id},model:{value:(_vm.rowsSelected.ids),callback:function ($$v) {_vm.$set(_vm.rowsSelected, "ids", $$v)},expression:"rowsSelected.ids"}})],1):_vm._e(),(_vm.emitterObj.is_third_party)?_c('td',{staticClass:"organization"},[_vm._v(" "+_vm._s(_vm.emitter)+" ")]):_vm._e(),_c('td',{class:("states " + _vm.colorNature)},[(_vm.exchange.invoice)?_c('v-chip',{staticClass:"exchange-stage",attrs:{"x-small":"","color":("" + (_vm.exchange.invoice_acted_date ? 'green' : 'green lighten-3')),"to":{
        name: 'document',
        params: { document_id: _vm.exchange.invoice },
        query: { exchange_id: _vm.exchange.id },
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("receipt")]),(_vm.exchange.invoice_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Facturé "+_vm._s(_vm._f("moment")(_vm.exchange.invoice_acted_date,"DD/MM/YYYY"))+" ")]):_c('div',[_vm._v("Facturation")])],1):_vm._e(),(_vm.exchange.delivery)?_c('v-chip',{staticClass:"exchange-stage",attrs:{"x-small":"","color":("" + (_vm.exchange.delivery_acted_date ? 'yellow' : 'yellow lighten-3')),"to":{
        name: 'document',
        params: { document_id: _vm.exchange.delivery },
        query: { exchange_id: _vm.exchange.id },
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("local_shipping")]),(_vm.exchange.delivery_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Livré "+_vm._s(_vm._f("moment")(_vm.exchange.delivery_acted_date,"DD/MM/YYYY"))+" ")]):_c('div',[_vm._v("Livraison")])],1):_vm._e(),(_vm.exchange.order)?_c('v-chip',{staticClass:"exchange-stage",attrs:{"x-small":"","color":("" + (_vm.exchange.order_acted_date ? 'red' : 'red lighten-3')),"to":{
        name: 'document',
        params: { document_id: _vm.exchange.order },
        query: { exchange_id: _vm.exchange.id },
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("settings_suggest")]),(_vm.exchange.order_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Commandé "+_vm._s(_vm._f("moment")(_vm.exchange.order_acted_date,"DD/MM/YYYY"))+" ")]):_c('div',[_vm._v("Commande")])],1):_vm._e(),(_vm.exchange.proposal)?_c('v-chip',{staticClass:"exchange-stage",attrs:{"x-small":"","color":("" + (_vm.exchange.proposal_acted_date ? 'blue' : 'blue lighten-3')),"to":{
        name: 'document',
        params: { document_id: _vm.exchange.proposal },
        query: { exchange_id: _vm.exchange.id },
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("request_quote")]),(_vm.exchange.proposal_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Proposé "+_vm._s(_vm._f("moment")(_vm.exchange.proposal_acted_date,"DD/MM/YYYY"))+" ")]):_c('div',[_vm._v("Proposition")])],1):_vm._e()],1),(!_vm.emitterObj.is_third_party)?_c('td',{staticClass:"organization"},[_vm._v(" "+_vm._s(_vm.recipient)+" ")]):_vm._e(),(!_vm.hideDescription)?_c('td',{staticClass:"article"},[_c('div',[_vm._v(_vm._s(_vm.article))]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.exchange.description)}})]):_vm._e(),_c('td',{staticClass:"numbers"},[_vm._v(" "+_vm._s(_vm.exchange.source_q ? _vm.exchange.source_q + "/" : "")+" "+_vm._s(_vm.exchange.quantity ? _vm.exchange.quantity : "-")+" ")]),_c('td',{staticClass:"numbers"},[_vm._v(" "+_vm._s(_vm.exchange.gross_amount ? _vm.exchange.gross_amount : "-")+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }