var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"element-row"},[(!_vm.hideSource)?_c('td',{class:("states " + _vm.sourceColorNature)},[(_vm.element.source)?_c('span',[(_vm.element.source.invoice)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.source.invoice },
          query: { exchange_id: _vm.element.source.id },
        }}},[(_vm.element.source.invoice_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Facturé "+_vm._s(_vm._f("moment")(_vm.element.source.invoice_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Facturation ")])]):_vm._e(),(_vm.element.source.delivery)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.source.delivery },
          query: { exchange_id: _vm.element.source.id },
        }}},[(_vm.element.source.delivery_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Livré "+_vm._s(_vm._f("moment")(_vm.element.source.delivery_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Livraison ")])]):_vm._e(),(_vm.element.source.order)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.source.order },
          query: { exchange_id: _vm.element.source.id },
        }}},[(_vm.element.source.order_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Commandé "+_vm._s(_vm._f("moment")(_vm.element.source.order_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Commande ")])]):_vm._e(),(_vm.element.source.proposal)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.source.proposal },
          query: { exchange_id: _vm.element.source.id },
        }}},[(_vm.element.source.proposal_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Proposé "+_vm._s(_vm._f("moment")(_vm.element.source.proposal_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Proposition ")])]):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.showPrices)?_c('td',[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.element.buying_amount ? _vm.element.buying_amount : "-")+"€ ")])]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(_vm.element.quantity)+" ")]),(!_vm.hideArticle)?_c('td',[_c('router-link',{staticClass:"caption",attrs:{"to":{
        name: 'article',
        params: { article_id: _vm.element.article.id },
      }}},[_vm._v(" "+_vm._s(_vm.element.article.name)+" ")])],1):_vm._e(),_c('td',[_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'element', params: { element_id: _vm.element.id } }}},[_vm._v(" "+_vm._s(_vm.element.unique_reference ? _vm.element.unique_reference : _vm.element.id)+" ")])],1),(_vm.showPrices)?_c('td',[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.element.selling_amount ? _vm.element.selling_amount : "-")+"€ ")])]):_vm._e(),(!_vm.hideDestination)?_c('td',{class:("states " + _vm.destinationColorNature)},[(_vm.element.destination)?_c('span',[(_vm.element.destination.invoice)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.destination.invoice },
          query: { exchange_id: _vm.element.destination.id },
        }}},[(_vm.element.destination.invoice_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Facturé "+_vm._s(_vm._f("moment")(_vm.element.destination.invoice_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Facturation ")])]):_vm._e(),(_vm.element.destination.delivery)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.destination.delivery },
          query: { exchange_id: _vm.element.destination.id },
        }}},[(_vm.element.destination.delivery_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Livré "+_vm._s(_vm._f("moment")(_vm.element.destination.delivery_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Livraison ")])]):_vm._e(),(_vm.element.destination.order)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.destination.order },
          query: { exchange_id: _vm.element.destination.id },
        }}},[(_vm.element.destination.order_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Commandé "+_vm._s(_vm._f("moment")(_vm.element.destination.order_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Commande ")])]):_vm._e(),(_vm.element.destination.proposal)?_c('router-link',{staticClass:"exchange-stage",attrs:{"to":{
          name: 'document',
          params: { document_id: _vm.element.destination.proposal },
          query: { exchange_id: _vm.element.destination.id },
        }}},[(_vm.element.destination.proposal_acted_date)?_c('span',{staticClass:"text--green"},[_vm._v(" Proposé "+_vm._s(_vm._f("moment")(_vm.element.destination.proposal_acted_date,"DD/MM/YYYY"))+" ")]):_c('span',[_vm._v(" Proposition ")])]):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.showDestOrg && !_vm.hideDestination)?_c('td',[_c('OrganizationPicker',{attrs:{"label":"Revendu à","dense":"","disabled":"","clearable":"","hide-details":""},model:{value:(_vm.element.orga_dest),callback:function ($$v) {_vm.$set(_vm.element, "orga_dest", $$v)},expression:"element.orga_dest"}})],1):_vm._e(),_c('td',[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }