<template>
  <v-container fluid v-if="exchanges">
    <h1>Transactions</h1>
    <v-text-field label="Rechercher" v-model="filterStr"></v-text-field>
    <v-toolbar dense flat class="ma-0 pa-0">
      <v-checkbox
        v-model="toggleAllValue"
        dense
        small
        :value="toggleAllValue"
        :indeterminate="toggleAllIndeterminate"
        @change="toggleAll"
        hide-details
      ></v-checkbox>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            :color="rowsSelected.ids.length > 0 ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          >
            {{ rowsSelected.ids.length }} éléments
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="removeSelectedExchanges()">
            Supprimer
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item link @click="createDocument('order')">
            Passer la commande
          </v-list-item>
          <v-list-item link @click="createDocument('delivery')">
            Effectuer la livraison
          </v-list-item>
          <v-list-item link @click="createDocument('invoice')">
            Facturer
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-row>
      <v-col :cols="6">
        <table class="fo-table">
          <ExchangeLight
            v-for="exchange in exchanges"
            :key="`exchange-${exchange.id}`"
            :exchange="exchange"
            :rowsSelected="rowsSelected"
          >
          </ExchangeLight>
        </table>
        <v-alert v-if="exchanges.length == 0">
          Aucun échange pour le moment
        </v-alert>
      </v-col>
      <v-col :cols="6"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import moment from "moment";
import ExchangeLight from "./ExchangeLight.vue";
import { mapGetters } from "vuex";
import Fuse from "fuse.js";

export default {
  components: { ExchangeLight },
  data: () => ({
    exchanges: [],
    rowsSelected: {
      ids: [],
    },
    filterStr: "",
    searchEngine: null,
  }),
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      organizations: "organizations/organizations",
    }),
    toggleAllValue() {
      return (
        this.rowsSelected.ids.length == this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
    toggleAllIndeterminate() {
      return (
        this.rowsSelected.ids.length != this.exchanges.length &&
        this.rowsSelected.ids.length > 0
      );
    },
  },
  watch: {
    filterStr(value) {
      if (value) {
        this.exchanges = this.searchEngine.search(value).map((i) => i.item);
      } else {
        this.exchanges = this.exchangesBackup;
      }
    },
  },
  methods: {
    openExchange(event) {
      this.$router.push({
        name: "document",
        params: { document_id: event.event.document_id },
      });
    },
    removeSelectedExchanges() {
      if (window.confirm("Êtes-vous sûr ?")) {
        this.rowsSelected.ids.forEach((id) => {
          this.axios.delete(`/api/exchanges/${id}.json`).then(() => {
            this.exchanges.splice(
              this.exchanges.indexOf(this.exchanges.find((e) => e.id == id)),
              1
            );
            this.rowsSelected.ids.splice(
              this.rowsSelected.ids.find((v) => v == id),
              1
            );
          });
        });
      }
    },
    toggleAll() {
      if (this.rowsSelected.ids.length == 0) {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      } else if (this.rowsSelected.ids.length == this.exchanges.length) {
        this.rowsSelected.ids = [];
      } else {
        this.rowsSelected.ids = this.exchanges.map((e) => e.id);
      }
    },
    createDocument(nature) {
      let firstId = this.rowsSelected.ids[0];
      let firstExchange = this.exchanges.find((r) => r.id == firstId);
      let isSameRecipient = true;
      let isDocOrphan = true;
      this.rowsSelected.ids.forEach((rowId) => {
        let exchange = this.exchanges.find((r) => r.id == rowId);
        if (exchange.recipient != firstExchange.recipient) {
          isSameRecipient = false;
          return;
        }
        if (exchange[`${nature}`]) {
          isDocOrphan = false;
          return;
        }
      });
      if (!isSameRecipient) {
        window.alert("Les échanges ne sont pas pour le même destinataire");
        return;
      }
      if (!isDocOrphan) {
        window.alert("Un échange est déjà sur un document de même nature");
        return;
      }
      let payload = {
        official_nature: nature,
        emitter_id: firstExchange.emitter,
        recipient_id: firstExchange.recipient,
        acted_date: moment().format("YYYY-MM-DD"),
      };
      var promCreations = [];
      var i = 1;
      let promDoc = this.axios
        .post(`/api/official_documents.json`, payload)
        .then((result) => {
          this.rowsSelected.ids.forEach((rowId) => {
            let exchange = this.exchanges.find((r) => r.id == rowId);
            let payloadRow = {
              document: result.data.id,
              position: i++,
            };
            payloadRow["exchange_id"] = exchange.id;
            let payloadExchange = {};
            payloadExchange[nature] = result.data.id;
            let promEx = this.axios
              .patch(`/api/exchanges/${exchange.id}.json`, payloadExchange)
              .then(() => {
                let promiseRow = this.axios.post(
                  `/api/doc_rows.json`,
                  payloadRow
                );
                promCreations.push(promiseRow);
              });
            promCreations.push(promEx);
          });
          this.$emit("new-document", result.data);
          return result;
        });

      promCreations.push(promDoc);
      setTimeout(() => {
        Promise.all(promCreations).then(() => {
          promDoc.then((resultDoc) => {
            this.$router.push({
              name: "document",
              params: { document_id: resultDoc.data.id },
            });
          });
        });
      }, 200);
    },
    loadExchanges() {
      this.rowsSelected = {
        ids: [],
      };
      this.axios
        .get(
          `/api/exchanges.json?sorting=-delivery_acted_date,-order_acted_date,-proposal_acted_date`
        )
        .then((result) => {
          let exchangesRaw = result.data;
          exchangesRaw.map((e) => {
            e.article = e.article
              ? this.articles.find((a) => a.id == e.article)
              : null;
            e.emitter = e.emitter
              ? this.organizations.find((a) => a.id == e.emitter)
              : null;
            e.recipient = e.recipient
              ? this.organizations.find((a) => a.id == e.recipient)
              : null;
          });
          this.exchanges = exchangesRaw;
          this.exchangesBackup = exchangesRaw;
          this.searchEngine = new Fuse(exchangesRaw, {
            keys: [
              "description",
              "id",
              "article.name",
              "recipient.name",
              "emitter.name",
            ],
          });
        });
    },
    loadCounters() {},
  },
  mounted() {
    this.loadExchanges(this.$route);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadExchanges(to);
  },
};
</script>
<style lang="scss" scoped>
</style>
