<template>
  <v-autocomplete
    v-bind="$attrs"
    v-bind:value="value"
    v-on="inputListeners"
    :items="articles"
    :loading="isLoading"
    item-text="name"
    item-value="id"
    clearable
    autocomplete="off"
    @input="selectItems"
    :search-input.sync="searchInput"
  >
    <!-- :append-icon="value ? 'open_in_new' : null"
    @click:append="
      $router.push({ name: 'article', params: { article_id: value } })
    " -->
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Commencez à entrer le nom d'un
          <strong>article</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:append-item>
      <div class="pa-2" v-if="canCreate">
        <v-btn
          small
          color="primary"
          @click="
            createArticle({
              name: searchInput,
              tags: [],
            })
          "
          >Créer l'article</v-btn
        >
      </div>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>

        <div>
          <v-chip
            x-small
            v-for="tag in item.tags"
            :color="mapTags[tag].color"
            :key="`i-${item.id}-t-${tag}`"
          >
            {{ mapTags[tag].name }}
          </v-chip>
        </div>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  inheritAttrs: true,
  props: {
    value: {},
    canCreate: {},
  },
  data: () => ({
    searchTime: null,
    isLoading: false,
    items: [],
    selectedItems: [],
    search: "",
    canCreate: false,
    idsSelected: [],
    searchInput: "",
  }),
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      mapTags: "tags/mapTags",
    }),

    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
  methods: {
    ...mapActions({
      createArticle: "articles/create",
    }),
    selectItems(value) {
      this.idsSelected = value;
      if (!Array.isArray(this.idsSelected)) {
        this.idsSelected = [value];
      }
      this.selectedItems = _.concat(
        [],
        this.items.filter((e) => this.idsSelected.includes(e.id))
      );
    },
  },
};
</script>
