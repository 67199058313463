<template>
  <v-container fluid v-if="articles">
    <v-row>
      <v-col class="left" :cols="4">
        <v-card class="pa-3">
          <v-card-title>Articles</v-card-title>
          <v-text-field label="Rechercher" v-model="filterStr"></v-text-field>
          <table class="fo-table fixed">
            <thead>
              <tr>
                <!-- <th class="actions"></th> -->
                <th>Nom</th>
              </tr>
            </thead>
            <tbody>
              <ArticleItem
                v-for="article in articlesToShow"
                :key="`article-${article.id}`"
                :article="article"
              >
                <v-checkbox
                  v-model="selectedItems"
                  :value="article"
                  class="ma-0 pa-0"
                ></v-checkbox>
              </ArticleItem>
            </tbody>
          </table>
          <v-alert v-if="articlesToShow.length == 0">
            Aucun article pour le moment
          </v-alert>
        </v-card>
      </v-col>
      <v-col :cols="8"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ArticleItem from "./ArticleItem.vue";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
export default {
  components: {
    ArticleItem,
  },
  data: () => ({
    articlesToShow: [],
    filterStr: "",
    selectedItems: [],
  }),
  methods: {
    ...mapActions({}),
  },
  watch: {
    filterStr(value) {
      if (value) {
        this.articlesToShow = this.searchArticles
          .search(value)
          .map((i) => i.item);
      } else {
        this.articlesToShow = this.articles;
      }
    },
  },
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      searchArticles: "articles/searchArticles",
    }),
  },
  mounted() {
    this.articlesToShow = this.articles;
  },
};
</script>
<style lang="scss" scoped>
.actions {
  width: 50px;
}
</style>
