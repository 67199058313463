<template>
  <v-main>
    <v-app-bar app dense class="remove-print">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="ml-4">
        <v-icon>menu</v-icon>
      </v-app-bar-nav-icon>
      <v-btn text to="/">
        <v-icon small class="pa-1">home</v-icon>
        Accueil</v-btn
      >
      <v-btn text :to="{ name: 'pages' }" v-if="user && user.admin_access">
        <v-icon small class="pa-1">description</v-icon>
        Pages
      </v-btn>
      <v-btn text :to="{ name: 'groups' }" v-if="user && user.admin_access">
        <v-icon small class="pa-1">group_work</v-icon>
        Groupes
      </v-btn>
      <v-btn text :to="{ name: 'organizations' }">
        <v-icon small class="pa-1">business</v-icon>
        Organisations
      </v-btn>
      <v-btn text :to="{ name: 'articles' }">
        <v-icon small class="pa-1">document_scanner</v-icon>
        Articles
      </v-btn>
      <!-- <v-btn
        v-if="user && user.direction_access"
        text
        :to="{ name: 'order-preparation' }"
      >
        Préparation de commandes
      </v-btn> -->
      <v-btn text :to="{ name: 'purchases' }">
        <v-icon small class="pa-1">input</v-icon>
        Achats
      </v-btn>
      <!-- <v-btn text :to="{ name: 'documents-all' }"> Tous </v-btn> -->
      <v-btn text :to="{ name: 'stocks' }" v-if="user && user.admin_access">
        <v-icon small class="pa-1">warehouse</v-icon>
        Ressources
      </v-btn>
      <v-btn text :to="{ name: 'sells' }">
        <v-icon small class="pa-1">output</v-icon>
        Ventes
      </v-btn>
      <v-btn text :to="{ name: 'transactions' }">
        <v-icon small class="pa-1">inventory</v-icon>
        Opérations
      </v-btn>
      <!-- <v-btn text :to="{ name: 'exchanges' }"> Flux </v-btn> -->
      <v-btn
        v-if="user && user.direction_access"
        text
        :to="{ name: 'payments' }"
      >
        <v-icon small class="pa-1">money</v-icon>
        Paiements
      </v-btn>
      <v-btn
        v-if="user && user.admin_access"
        text
        :to="{ name: 'automations' }"
      >
        <v-icon small class="pa-1">file_download</v-icon>
        Automates
      </v-btn>
      <v-btn v-if="user && user.beta_access" text :to="{ name: 'imports' }">
        <v-icon small class="pa-1">file_download</v-icon>
        Imports
      </v-btn>
      <v-btn v-if="user && user.beta_access" text :to="{ name: 'exports' }">
        <v-icon small class="pa-1">file_download</v-icon>
        Exports
      </v-btn>
      <v-btn
        v-if="user && user.beta_access"
        text
        :to="{ name: 'docs-extraction' }"
      >
        <v-icon small class="pa-1">file_download</v-icon>
        Extractions
      </v-btn>
      <v-btn v-if="user && user.beta_access" text :to="{ name: 'indicators' }">
        <v-icon small class="pa-1">insights</v-icon>
        Indicateurs
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="user && user.admin_access"
        :to="{ name: 'messages' }"
        :color="unreadCount > 0 ? 'primary' : ''"
      >
        <v-icon small class="pa-1">message</v-icon>
        {{ unreadCount }}
        Messages
      </v-btn>

      <v-progress-linear
        :active="pageLoading || !dataReady"
        :indeterminate="pageLoading || !dataReady"
        absolute
        bottom
        color="deep-purple accent-4"
      ></v-progress-linear>
    </v-app-bar>
    <v-navigation-drawer
      app
      width="260px"
      v-model="drawer"
      left
      class="remove-print"
    >
      <UserStatus></UserStatus>
      <hr />
      <v-list-item
        link
        :to="{ name: 'tags' }"
        v-if="user && user.direction_access"
      >
        <v-list-item-title>Catégories</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item
        link
        :to="{ name: 'people' }"
        v-if="user && user.direction_access"
      >
        <v-list-item-title>Personnes</v-list-item-title>
      </v-list-item> -->
    </v-navigation-drawer>
    <div v-if="dataReady">
      <router-view></router-view>
    </div>
    <div v-else class="text-center ma-5">
      <v-alert color="success"> Chargement... </v-alert>
    </div>
  </v-main>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import UserStatus from "./authentication/UserStatus.vue";
export default {
  components: {
    UserStatus,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      pageLoading: "pageLoading",
      unreadCount: "messages/unreadCount",
    }),
  },
  data: () => ({
    dataReady: false,
    drawer: false,
  }),
  watch: {},
  methods: {
    ...mapActions({
      autologin: "user/autologin",
      loadOrganizations: "organizations/load",
      loadArticles: "articles/load",
      loadTags: "tags/load",
      loadStocks: "stocks/load",
      loadGroupNatures: "groups/load",
      loadMessages: "messages/loadCount",
      // loadPages: "pages/load",
    }),
  },
  async mounted() {
    await this.autologin().catch((result) => {
      this.$router.push({ name: "login" });
      return result;
    });
    await this.loadTags();
    await this.loadOrganizations();
    await this.loadArticles();
    await this.loadStocks();
    await this.loadGroupNatures();
    await this.loadMessages();
    // await this.loadPages();

    this.dataReady = true;
  },
};
</script>
<style lang="scss">
.fo-table {
  display: table;
  table-layout: auto;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  &.fixed {
    table-layout: fixed;
  }

  &.clickable {
    tr:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }

  th {
    font-size: 0.7em;
    text-align: left;
  }

  td {
    &.article {
      width: 50%;
    }
  }
}
</style>
<style lang="scss" scoped>
@media print {
  .remove-print {
    display: none;
  }
  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
  }
}
</style>
