

<template>
  <tr class="element-row">
    <!-- <td>
      <v-chip :color="element.is_available ? 'green' : 'red'"></v-chip>
    </td> -->
    <td :class="`states ${sourceColorNature}`" v-if="!hideSource">
      <span v-if="element.source">
        <router-link
          v-if="element.source.invoice"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.source.invoice },
            query: { exchange_id: element.source.id },
          }"
        >
          <span v-if="element.source.invoice_acted_date" class="text--green">
            Facturé
            {{ element.source.invoice_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Facturation </span>
        </router-link>
        <router-link
          v-if="element.source.delivery"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.source.delivery },
            query: { exchange_id: element.source.id },
          }"
        >
          <span v-if="element.source.delivery_acted_date" class="text--green">
            Livré
            {{ element.source.delivery_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Livraison </span>
        </router-link>
        <router-link
          v-if="element.source.order"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.source.order },
            query: { exchange_id: element.source.id },
          }"
        >
          <span v-if="element.source.order_acted_date" class="text--green">
            Commandé
            {{ element.source.order_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Commande </span>
        </router-link>
        <router-link
          v-if="element.source.proposal"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.source.proposal },
            query: { exchange_id: element.source.id },
          }"
        >
          <span v-if="element.source.proposal_acted_date" class="text--green">
            Proposé
            {{ element.source.proposal_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Proposition </span>
        </router-link>
      </span>
    </td>
    <td v-if="showPrices">
      <span class="caption">
        {{ element.buying_amount ? element.buying_amount : "-" }}€
      </span>
    </td>
    <td>
      {{ element.quantity }}
    </td>
    <td v-if="!hideArticle">
      <router-link
        class="caption"
        :to="{
          name: 'article',
          params: { article_id: element.article.id },
        }"
      >
        {{ element.article.name }}
      </router-link>
    </td>
    <td>
      <router-link
        class="link"
        :to="{ name: 'element', params: { element_id: element.id } }"
      >
        {{ element.unique_reference ? element.unique_reference : element.id }}
      </router-link>
    </td>
    <td v-if="showPrices">
      <span class="caption">
        {{ element.selling_amount ? element.selling_amount : "-" }}€
      </span>
    </td>
    <td :class="`states ${destinationColorNature}`" v-if="!hideDestination">
      <span v-if="element.destination">
        <router-link
          v-if="element.destination.invoice"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.destination.invoice },
            query: { exchange_id: element.destination.id },
          }"
        >
          <span
            v-if="element.destination.invoice_acted_date"
            class="text--green"
          >
            Facturé
            {{ element.destination.invoice_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Facturation </span>
        </router-link>
        <router-link
          v-if="element.destination.delivery"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.destination.delivery },
            query: { exchange_id: element.destination.id },
          }"
        >
          <span
            v-if="element.destination.delivery_acted_date"
            class="text--green"
          >
            Livré
            {{ element.destination.delivery_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Livraison </span>
        </router-link>
        <router-link
          v-if="element.destination.order"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.destination.order },
            query: { exchange_id: element.destination.id },
          }"
        >
          <span v-if="element.destination.order_acted_date" class="text--green">
            Commandé
            {{ element.destination.order_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Commande </span>
        </router-link>
        <router-link
          v-if="element.destination.proposal"
          class="exchange-stage"
          :to="{
            name: 'document',
            params: { document_id: element.destination.proposal },
            query: { exchange_id: element.destination.id },
          }"
        >
          <span
            v-if="element.destination.proposal_acted_date"
            class="text--green"
          >
            Proposé
            {{ element.destination.proposal_acted_date | moment("DD/MM/YYYY") }}
          </span>
          <span v-else> Proposition </span>
        </router-link>
      </span>
    </td>
    <td v-if="showDestOrg && !hideDestination">
      <OrganizationPicker
        v-model="element.orga_dest"
        label="Revendu à"
        dense
        disabled
        clearable
        hide-details
      ></OrganizationPicker>
    </td>
    <td>
      <slot></slot>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable no-console */
/* eslint-disable no-debugger */
// import _ from "lodash";
import OrganizationPicker from "../organizations/OrganizationPicker.vue";
export default {
  components: {
    OrganizationPicker,
  },
  props: {
    element: {},
    hideArticle: {
      default: false,
    },
    showDestOrg: {
      default: false,
    },
    showPrices: {
      default: false,
    },
    hideSource: {
      default: false,
    },
    hideDestination: {
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters({}),
    sourceColorNature() {
      if (this.element.source?.invoice) {
        return !this.element.source["invoice_acted°date"]
          ? "green lighten-4"
          : "green";
      } else if (this.element.source?.delivery) {
        return !this.element.source["delivery_acted_date"]
          ? "yellow lighten-4"
          : "yellow";
      } else if (this.element.source?.order) {
        return !this.element.source["order_acted_date"]
          ? "red lighten-4"
          : "red";
      } else if (this.element.source?.proposal) {
        return !this.element.source["proposal_acted_date"]
          ? "blue lighten-4"
          : "blue";
      }
      return "";
    },
    destinationColorNature() {
      if (this.element.destination?.invoice) {
        return !this.element.destination["invoice_acted°date"]
          ? "green lighten-4"
          : "green";
      } else if (this.element.destination?.delivery) {
        return !this.element.destination["delivery_acted_date"]
          ? "yellow lighten-4"
          : "yellow";
      } else if (this.element.destination?.order) {
        return !this.element.destination["order_acted_date"]
          ? "red lighten-4"
          : "red";
      } else if (this.element.destination?.proposal) {
        return !this.element.destination["proposal_acted_date"]
          ? "blue lighten-4"
          : "blue";
      }
      return "";
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.element-row {
  padding: 4px;
  &:hover {
    background: #fff;
  }
  .link {
    display: block;
  }
  .states {
    padding: 4px;
    .exchange-stage {
      display: block;
      font-size: 0.6em;
      &.router-link-active {
        font-weight: bold;
      }
    }
  }
}
</style>

